import api from "./api";

export const getBookingReserved = async (queryParam) => {
  return api
    .get({
      endpoint: "/booking/own/reserved",
      port: process.env.REACT_APP_PORT_BOOK,
      queryParam,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
