import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../components/Button";
import InputText from "../../../components/Input/Text";
import Modal from "../../../components/Modal";
import { useMyBooking } from "../../../hooks/useMyBooking";
import "./StyleForm.css"

const AddNewCustomer = ({ setShowAddCustomer, showAddCustomer }) => {
  /* Hooks */
  const { createCustomer, loading } = useMyBooking();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name required"),
    email: Yup.string().email("Invalid email").required("Email required"),
    phoneNumber: Yup.string().required("Phone number required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!showAddCustomer) reset();
  }, [showAddCustomer]);

  useEffect(() => {
    if (!loading) setShowAddCustomer(false);
  }, [loading]);

  const onCloseForm = () => {
    setShowAddCustomer(false);
    reset();
  };
  return (
    <React.Fragment>
      <Modal.Common
        closeModal={() => setShowAddCustomer(false)}
        showModal={showAddCustomer}
        title="Add New Customer"
        description="Add new court customer  by complete form information below"
      >
        <form
          className="px-8 pb-8 w-[675px]"
          onSubmit={handleSubmit(createCustomer)}
        >
          <InputText
            labelClassName="mt-3"
            name="name"
            required
            type="text"
            label="Name"
            placeholder="Insert name"
            register={register}
            errorMessage={errors?.name?.message?.toString()}
          />
          <InputText
            labelClassName="mt-3"
            name="email"
            required
            type="email"
            label="Email"
            placeholder="Insert email"
            register={register}
            errorMessage={errors?.email?.message?.toString()}
          />
          <InputText
            labelClassName="mt-3"
            name="phoneNumber"
            required
            type="number"
            label="Phone Number"
            placeholder="Insert phone number"
            register={register}
            errorMessage={errors?.phoneNumber?.message?.toString()}
          />
          <div className="flex justify-end gap-4">
            <div className="w-[180%]" />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Save"
              type="primary"
              btnType="submit"
              isDisable={!isValid || isSubmitting}
              isSubmit={isSubmitting && isValid}
            />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={onCloseForm}
            />
          </div>
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default AddNewCustomer;
