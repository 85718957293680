import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import InputText from "../../components/Input/Text";
import { retrieveLocalStorageItem } from "../../utils/localStorage";
import "./Login.style.css";
import { IconEye, IconEyeOff } from "../../assets/images";
import Button from "../../components/Button";
import Checkbox from "../../components/Input/Checkbox";
import { useSignIn } from "../../hooks/useSignin";
import ForgotPassword from "./ForgotPassword";

const Login = () => {
  /* Hooks */
  const { signIn, loading } = useSignIn();
  const navigate = useNavigate();

  /* Local State */
  const [showPassword, setShowpassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [forgotPassowrd, setForgotPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Wrong format email").required("Email required"),
    password: Yup.string().required("Password required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid, defaultValues },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset({ ...defaultValues });
    if (
      retrieveLocalStorageItem("accessToken") &&
      retrieveLocalStorageItem("info")
    ) {
      navigate("/dashboard");
    }
  }, [retrieveLocalStorageItem]); // eslint-disable-line

  useEffect(() => {
    if (!loading) setForgotPassword(false);
  }, [loading]);
  return (
    <React.Fragment>
      <ForgotPassword
        setShowModal={setForgotPassword}
        showModal={forgotPassowrd}
      />
      <div className="bg-login">
        <div className="flex flex-col items-center justify-center p-6 mx-auto h-screen">
          <div className="md:w-[533px] w-full h-full md:h-[575px] bg-white rounded-[20px] mt-0 max-w-md p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <form onSubmit={handleSubmit(signIn)}>
                <p className="text-center mt-7 text-[24px] font-bold text-dark-default">
                  Login
                </p>
                <p className="text-center text-small text-gray-500 mt-3 tracking-[0.3px]">
                  Enter your email and password below
                </p>
                <InputText
                  labelClassName="mt-10"
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="Insert email address"
                  register={register}
                  errorMessage={errors?.email?.message?.toString()}
                />
                <InputText
                  labelClassName="mt-4"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  register={register}
                  icon={showPassword ? IconEyeOff : IconEye}
                  iconOnClick={() => setShowpassword(!showPassword)}
                  iconPosition="right"
                  placeholder="Insert password"
                  iconClassName="hover:cursor-pointer"
                  errorMessage={errors?.password?.message?.toString()}
                />
                <div className="flex justify-between mt-3">
                  <Checkbox
                    label="Remember me"
                    checked={rememberMe}
                    onClick={() => setRememberMe(!rememberMe)}
                  />
                  <p
                    className="text-[14px] text-blue-400 font-light hover:cursor-pointer hover:text-blue-500"
                    onClick={() => setForgotPassword(true)}
                  >
                    Forgot Password
                  </p>
                </div>
                <Button
                  className="mt-10 tracking-wider font-[14px]"
                  label="Log In"
                  type="primary"
                  btnType="submit"
                  isDisable={!isValid || isSubmitting}
                  isSubmit={isSubmitting && isValid}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
