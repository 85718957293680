import api from "./api";

export const postPricing = async (body) => {
  return api
    .post({
      endpoint: '/pricing',
      port: process.env.REACT_APP_PORT_PRICE,
      body,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
