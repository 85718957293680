import api from "./api";

export const getCountTotalBooking = async (queryParam) => {
    return api
      .get({
        endpoint: "/booking/own/total-booking",
        port: process.env.REACT_APP_PORT_BOOK,
        queryParam,
      })
      .then((res) => res.data)
      .catch((err) => err.response.data);
  };