import React from "react";
import InputText from "../../../components/Input/Text";

const Sosmed = ({ register, errors }) => {
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Facebook Link</p>
          <p className="font-normal text-medium">
            This is optional. This facebook link will let user know abour your
            venue more
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="linkFb"
              required
              type="text"
              placeholder="Insert facebook link"
              register={register}
              errorMessage={errors?.linkFb?.message?.toString()}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Instagram Link</p>
          <p className="font-normal text-medium">
            This is optional. This instagram link will let user know abour your
            venue more
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="linkIg"
              required
              type="text"
              placeholder="Insert instagram link"
              register={register}
              errorMessage={errors?.linkIg?.message?.toString()}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Twitter Link</p>
          <p className="font-normal text-medium">
            This is optional. This twitter link will let user know abour your
            venue more
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="linkTwitter"
              required
              type="text"
              placeholder="Insert twitter link"
              register={register}
              errorMessage={errors?.linkTwitter?.message?.toString()}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sosmed;
