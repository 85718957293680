import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { IconEye, IconEyeOff } from "../../../assets/images";
import Button from "../../../components/Button";
import InputText from "../../../components/Input/Text";
import { useAuth } from "../../../hooks/useAuth";

const Security = ({ isForgotPassword }) => {
  /* Local State */
  const [showOldPassowrd, setShowOldPassword] = useState(false);
  const [showNewPassowrd, setShowNewPassword] = useState(false);
  const [showConfirmPassowrd, setShowConfirmPassword] = useState(false);

  /* Hooks */
  const { changePasswordForgot, changePassword, loading } = useAuth();

  const validationSchema = Yup.object().shape({
    oldPassword: !isForgotPassword
      ? Yup.string().required("Old password required")
      : Yup.string().notRequired(),
    newPassword: Yup.string().required("New password required"),
    confirmPassword: Yup.string()
      .required("Confirm password required")
      .oneOf([Yup.ref("newPassword")], "Invalid password"),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!loading) reset();
  }, [loading]);

  return (
    <React.Fragment>
      <form
        onSubmit={handleSubmit(
          isForgotPassword ? changePasswordForgot : changePassword
        )}
      >
        {!isForgotPassword && (
          <div className="flex justify-between gap-8 mt-12 pb-8 border-b-[1px] border-[#F0F0F0]">
            <div className="w-[40%] my-auto">
              <p className="font-bold text-lg">Old Password</p>
              <p className="font-normal text-medium">
                Please input your old password
              </p>
            </div>
            <div className="w-[60%] my-auto flex gap-4 justify-start">
              <div className="w-full">
                <InputText
                  name="oldPassword"
                  required
                  placeholder="Insert old password"
                  register={register}
                  errorMessage={errors?.oldPassword?.message?.toString()}
                  type={showOldPassowrd ? "text" : "password"}
                  icon={showOldPassowrd ? IconEyeOff : IconEye}
                  iconOnClick={() => setShowOldPassword(!showOldPassowrd)}
                  iconPosition="right"
                />
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-between gap-8 mt-12 pb-8 border-b-[1px] border-[#F0F0F0]">
          <div className="w-[40%] my-auto">
            <p className="font-bold text-lg">New Password</p>
            <p className="font-normal text-medium">
              Please input your new password
            </p>
          </div>
          <div className="w-[60%] my-auto flex gap-4 justify-start">
            <div className="w-full">
              <InputText
                name="newPassword"
                required
                placeholder="Insert new password"
                register={register}
                errorMessage={errors?.newPassword?.message?.toString()}
                type={showNewPassowrd ? "text" : "password"}
                icon={showNewPassowrd ? IconEyeOff : IconEye}
                iconOnClick={() => setShowNewPassword(!showNewPassowrd)}
                iconPosition="right"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-8 mt-12 pb-8">
          <div className="w-[40%] my-auto">
            <p className="font-bold text-lg">Confirm Password</p>
            <p className="font-normal text-medium">
              Please input your new password to be confirmed
            </p>
          </div>
          <div className="w-[60%] my-auto flex gap-4 justify-start">
            <div className="w-full">
              <InputText
                name="confirmPassword"
                required
                placeholder="Confirm new password"
                register={register}
                errorMessage={errors?.confirmPassword?.message?.toString()}
                type={showConfirmPassowrd ? "text" : "password"}
                icon={showConfirmPassowrd ? IconEyeOff : IconEye}
                iconOnClick={() => setShowConfirmPassword(!showConfirmPassowrd)}
                iconPosition="right"
              />
            </div>
          </div>
        </div>
        {isDirty && (
          <Button
            className="mt-7 tracking-wider font-[14px] float-right !w-60"
            label="Save"
            type="primary"
            btnType="submit"
            isDisable={!isValid || isSubmitting}
            isSubmit={isSubmitting && isValid}
          />
        )}
      </form>
    </React.Fragment>
  );
};

export default Security;
