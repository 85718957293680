import {
  SET_LIST_TIME_BOOKING,
  SET_ROWS_TIME_BOOKING,
  SET_QUERY_TIME_BOOKING,
  SET_MASTER_DAYS_TIME,
  SET_DETAIL_EXCEPTION,
  SET_MASTER_TIME_INACTIVE,
  SET_LIST_EXCEPTION,
} from "../../assets/constants/reducer.type";

const initialState = {
  list: null,
  query: {
    page: 0,
    pageSize: 10,
    day: "",
    idRelation: "",
  },
  rows: 0,
  masterDaysTime: null,
  detailException: null,
  masterTimeInactive: null,
  exceptionList: null,
};

const staffReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_LIST_TIME_BOOKING:
      return { ...state, list: payload };
    case SET_ROWS_TIME_BOOKING:
      return { ...state, rows: payload };
    case SET_QUERY_TIME_BOOKING:
      return { ...state, query: payload };
    case SET_MASTER_DAYS_TIME:
      return { ...state, masterDaysTime: payload };
    case SET_DETAIL_EXCEPTION:
      return { ...state, detailException: payload };
    case SET_MASTER_TIME_INACTIVE:
      return { ...state, masterTimeInactive: payload };
    case SET_LIST_EXCEPTION:
      return { ...state, exceptionList: payload };
    default:
      return state;
  }
};

export default staffReducer;
