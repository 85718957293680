import React, { useState } from "react";
import { IconPencil, IconTrash } from "../../assets/images";
import Image from "../../components/Image";
import Table from "../../components/Table";
import FormRole from "./FormRole";

const Role = () => {
  const [showForm, setShowForm] = useState(false);
  return (
    <React.Fragment>
      <FormRole setShowModal={setShowForm} showModal={showForm} />
      <div className="py-7 px-11">
        <Table
          // loading={loadingProduct}
          usePagination
          useButtonAdd
          buttonAddTitle="+ Add New Role"
          onClickBtnAdd={() => setShowForm(true)}
          title="Role Management"
          description="Manage all your existing role or edit or add new role"
          subHeader
          data={[
            {
              id: 1,
              role: "Gardener",
              description: "Gardener is ...",
            },
            {
              id: 2,
              role: "Owner",
              description: "Owner is ...",
            },
          ]}
          textTransform="none"
          columns={[
            {
              name: <p className="text-center w-full">No</p>,
              width: "8%",
              cell: (_row, i) => {
                return <p className="text-center w-full">{i + 1}</p>;
              },
            },
            {
              name: "Role",
              width: "25%",
              sortable: true,
              selector: (row) => row.role || "-",
            },
            {
              name: "Description",
              sortable: true,
              selector: (row) => row.description || "-",
            },
            {
              name: "Action",
              width: "13%",
              cell: (row, i) => {
                return (
                  <div className="flex justify-between gap-3" key={i}>
                    <div className="p-[8px] bg-primary-50 rounded-lg hover:opacity-[0.7]">
                      <Image
                        src={IconPencil}
                        alt="Icon Pencil"
                        height={25}
                        width={25}
                      />
                    </div>
                    <div className="p-[8px] bg-red-50 rounded-lg hover:opacity-[0.7]">
                      <Image
                        src={IconTrash}
                        alt="Icon Pencil"
                        height={25}
                        width={25}
                      />
                    </div>
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default Role;
