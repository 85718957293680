import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../core/GET_Profile";
import { postLogin } from "../core/POST_Login";
import { setCollapsed } from "../stores/actions/common.action";
import {
  retrieveLocalStorageItem,
  storeLocalStorageItem,
} from "../utils/localStorage";
import { toastNotif } from "../utils/toastNotif";

export const useSignIn = () => {
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [error, setError] = useState(false);
  const [messageData, setMessageData] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetMessage = () => {
    setError(false);
    setMessageData("");
  };

  useEffect(() => {
    const timer = setTimeout(() => resetMessage(), 2500);
    return () => {
      clearTimeout(timer);
    };
  }, [messageData]);

  useEffect(() => {
    const accessToken = retrieveLocalStorageItem("accessToken");
    if (accessToken) {
      navigate("/dashboard");
    } else {
      setLoggedIn(false);
    }
  }, [navigate]);

  const signIn = async ({ email, password }) => {
    try {
      const { success, result, message } = await postLogin({ email, password });
      if (success && result) {
        storeLocalStorageItem({
          storageKey: "accessToken",
          storageValue: result.token,
        });
        const user = await getProfile();
        if (user.success && user.result) {
          storeLocalStorageItem({
            storageKey: "info",
            storageValue: JSON.stringify(user.result),
          });
          toastNotif({
            message: message || "Login successfully",
            type: "success",
          });
          setError(false);
          navigate("/dashboard");
          dispatch(setCollapsed(false));
          setLoading(false);
          return setMessageData(message);
        }
      }
      setMessageData(message);
      toastNotif({
        message: "Invalid email or password",
        type: "error",
      });
      dispatch(setCollapsed(false));
      return setError(true);
    } catch (err) {
      dispatch(setCollapsed(false));
      return err;
    }
  };

  return {
    error,
    loggedIn,
    messageData,
    signIn,
    loading,
  };
};
