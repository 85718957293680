import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getExceptionDetail } from "../core/GET_ExceptionDetail";
import { getMasterDaysTime } from "../core/GET_MasterDaysTime";
import { getTimeBooking } from "../core/GET_TimeBooking";
import { patchBulkEditTimeBooking } from "../core/PATCH_BulkEditTimeBooking";
import { postException } from "../core/POST_Exception";
import { postPricing } from "../core/POST_Pricing";
import { putTimeBookingStatus } from "../core/PUT_TimeBookingStatus";
import {
  setDetailException,
  setListException,
  setListTimeBooking,
  setMasterDaysTime,
  setMasterTimeInactive,
  setRowsTimeBooking,
} from "../stores/actions/timebooking.action";
import { toastNotif } from "../utils/toastNotif";
import { getMasterTimeInactive } from "../core/GET_MasterTimeInactive";
import { getExceptionList } from "../core/GET_ExceptionList";
import { deleteException } from "../core/DELETE_Exception";

export const useTimeBooking = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const { query } = useSelector((state) => state.timebooking);

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  /* get my venue detail */
  const fetchTimeBooking = async () => {
    setLoading(true);
    try {
      const { success, message, result, rows } = await getTimeBooking(query);

      if (success && result) {
        dispatch(setRowsTimeBooking(rows));
        dispatch(setListTimeBooking(result));
      } else {
        dispatch(setListTimeBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const updateTimeBookingStatus = async (id, data) => {
    setLoading(true);
    try {
      const { success, message } = await putTimeBookingStatus(id, data);
      if (success) {
        fetchTimeBooking();
        toastNotif({
          message: message || "Updated successfully",
          type: "success",
        });
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const createOrUpdatePricing = async (data) => {
    setLoading(true);
    try {
      const { success, message } = await postPricing(data);
      if (success) {
        Swal.fire({
          title: "Loading.....",
          timer: 2000,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            fetchTimeBooking();
            toastNotif({
              message: message || "Updated successfully",
              type: "success",
            });
          }
        });
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      fetchTimeBooking();
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchMasterTimeDays = async () => {
    setLoading(true);
    try {
      const { message, result } = await getMasterDaysTime();
      if (result) {
        dispatch(setMasterDaysTime(result));
      } else {
        dispatch(setMasterDaysTime());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const bulkUpdateTimeBooking = async (data) => {
    setLoading(true);
    try {
      const { success, message } = await patchBulkEditTimeBooking(data);
      dispatch(setRowsTimeBooking(undefined));
      dispatch(setListTimeBooking(undefined));
      if (success) {
        fetchTimeBooking();
        toastNotif({
          message: message || "Updated successfully",
          type: "success",
        });
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const createException = async (data) => {
    setLoading(true);
    try {
      const { success, message } = await postException(data);
      if (success) {
        dispatch(setRowsTimeBooking(undefined));
        dispatch(setListTimeBooking(undefined));
        if (window.location.pathname === "/time-booking/exception") {
          window.location.reload();
        } else {
          fetchTimeBooking();
        }
        toastNotif({
          message: message || "Updated successfully",
          type: "success",
        });
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchDetailException = async (payload) => {
    setLoading(true);
    try {
      const { message, result } = await getExceptionDetail(payload);
      if (result) {
        dispatch(setDetailException(result));
      } else {
        dispatch(setDetailException());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchMasterTimeInactive = async (params) => {
    setLoading(true);
    try {
      const { message, result } = await getMasterTimeInactive(params);
      if (result) {
        dispatch(setMasterTimeInactive(result));
      } else {
        dispatch(setMasterTimeInactive());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchExceptionList = async (params) => {
    setLoading(true);
    try {
      const { message, rows, count } = await getExceptionList(params);
      if (rows) {
        dispatch(setListException(rows));
        dispatch(setRowsTimeBooking(count));
      } else {
        dispatch(setListException());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const removeException = async (id) => {
    setLoading(true);
    try {
      const { success, message } = await deleteException(id);
      dispatch(setListException(undefined));
      if (success) {
        toastNotif({
          message: message || "Delete successfully",
          type: "success",
        });
        return true;
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      setLoading(false);
      return false;
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      setLoading(false);
      return false;
    }
  };
  return {
    error,
    message,
    loading,
    fetchTimeBooking,
    updateTimeBookingStatus,
    createOrUpdatePricing,
    fetchMasterTimeDays,
    bulkUpdateTimeBooking,
    createException,
    fetchDetailException,
    fetchMasterTimeInactive,
    fetchExceptionList,
    removeException,
  };
};
