import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { IconWarning } from "../../../assets/images";

export const animatedComponents = makeAnimated();

const SelectTypeSearch = ({
  placeholder = "Pilih ...",
  setValue,
  value,
  options,
}) => {
  return (
    <Select
      closeMenuOnSelect
      placeholder={placeholder}
      components={{
        ...animatedComponents,
        IndicatorSeparator: () => null,
      }}
      options={options}
      styles={{
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: "#C5C7CD",
            fontSize: "18px",
            fontWeight: "400",
            marginTop: "auto",
            marginBottom: "auto",
          };
        },
        dropdownIndicator: (base) => ({
          ...base,
          color: "#ccccc",
        }),
        control: (base, state) => ({
          ...base,
          background: state.isDisabled ? "#F9F9F9" : "#FCFDFE",
          fontSize: "18px",
          height: "64px",
          fontWeight: "400",
          borderColor: !state.isFocused ? "#F0F1F7" : "#737374",
          borderRadius: "0.5rem",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
          width: "250px",
          padding: "0 11px",
          borderWidth: "1px",
          cursor: state.isDisabled ? "not-allowed" : "default",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            borderColor: state.isFocused ? "#737374" : "#C5C7CD",
            cursor: "pointer",
          },
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontSize: "18px",
            lineHeight: "25px",
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? "#F5F7FA"
              : isFocused
              ? "#F5F7FA"
              : undefined,
            color: isDisabled ? "#ccc" : isSelected ? "#979797" : "black",
            cursor: isDisabled ? "not-allowed" : "default",
            ":active": {
              ...styles[":active"],
              backgroundColor: isSelected ? "#F5F7FA" : undefined,
              cursor: "pointer",
              fontSize: "18px",
            },
          };
        },
      }}
      value={value}
      onChange={setValue}
      isSearchable
    />
  );
};

export default SelectTypeSearch;
