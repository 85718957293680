import api from "../api";

export const postCreateOrUpdatePin = async (body) => {
  return api
    .post({
      body,
      endpoint: "/secure-code/create-or-update",
      port: process.env.REACT_APP_PORT_VENUE,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
