import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button";
import InputSearch from "../../../components/Input/Search";
import { useMyBooking } from "../../../hooks/useMyBooking";
import { setCutomers } from "../../../stores/actions/mybooking.action";

const SearchCustomer = ({ search, setSearch, setShowAddCustomer }) => {
  const { customers } = useSelector((state) => state.mybook);

  const { searchCustomer } = useMyBooking();
  const dispatch = useDispatch();

  useEffect(() => {
    if (search?.name?.length >= 3) {
      searchCustomer(search?.name);
    }
    if (search?.name?.length < 3) {
      dispatch(setCutomers(null));
    }
  }, [search]);
  return (
    <div className="md:flex justify-start gap-2 w-full md:px-0 px-11">
      <div className="md:w-[220%] w-full">
        <InputSearch
          value={search}
          setValue={setSearch}
          placeholder="Search registered customer contact"
          results={customers}
          className="!h-[51px]"
          iconLeftClassName="!top-[50%] !transform !translate-y-[-50%]"
          iconRightClassName="!top-[50%] !transform !translate-y-[-50%]"
        />
      </div>
      <Button
        isDisable={false}
        label="+ Add New Customer"
        type="primary"
        className="px-9 md:mt-0 mt-4"
        size="md"
        onClick={() => setShowAddCustomer(true)}
      />
    </div>
  );
};

export default SearchCustomer;
