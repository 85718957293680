import {
  SET_NEED_PAYMENT_LIST,
  SET_NEW_BOOKING_LIST,
  SET_NEXT_RESERVATION,
  SET_PAYMENT,
  SET_PAYMENT_BALANCE,
  SET_PAYMENT_PAGINATION,
  SET_TODAY_RESERVATION,
  SET_TOTAL_BOOKING,
  SET_AVAILABLE_DISBURSE_BANK
} from "../../assets/constants/reducer.type";

const initialState = {
  totalBooking: 0,
  needPaymentList: null,
  newBookingList: null,
  todayReservationList: null,
  nextReservationList: null,
  payment: null,
  paymentBalance: null,
  paymentPagination: null,
  availableDisbursebank: null,
};

const dashboardReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  type === SET_PAYMENT_BALANCE && console.log(payload);

  switch (type) {
    case SET_NEED_PAYMENT_LIST:
      return { ...state, needPaymentList: payload };
    case SET_NEW_BOOKING_LIST:
      return { ...state, newBookingList: payload };
    case SET_TODAY_RESERVATION:
      return { ...state, todayReservationList: payload };
    case SET_NEXT_RESERVATION:
      return { ...state, nextReservationList: payload };
    case SET_TOTAL_BOOKING:
      return { ...state, totalBooking: payload };
    case SET_PAYMENT:
      return { ...state, payment: payload };
    case SET_PAYMENT_BALANCE:
      return { ...state, paymentBalance: payload };
    case SET_PAYMENT_PAGINATION:
      return { ...state, paymentPagination: payload };
    case SET_AVAILABLE_DISBURSE_BANK:
      return { ...state, availableDisbursebank: payload };
    default:
      return state;
  }
};

export default dashboardReducer;
