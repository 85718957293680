import React from "react";
import Modal from "../../../components/Modal";
import DetailCancelled from "./DetailCancelled";
import DetailClose from "./DetailClose";
import DetailReserved from "./DetailReserved";
import DetailWaitingPayment from "./DetailWaitingPayment";

const ModalDetail = ({
  showForm,
  modalType,
  onToggle,
  item,
  expiryTime,
  image,
}) => {
  return (
    <React.Fragment>
      <Modal.Common
        showModal={showForm}
        title="Detail Booking"
        description=""
        closeModal={onToggle}
      >
        {modalType === "WAITING_FOR_PAYMENT" ? (
          <DetailWaitingPayment item={item} image={image} />
        ) : modalType === "CANCELLED" ? (
          <DetailCancelled item={item} expiryTime={expiryTime} image={image} />
        ) : modalType === "RESERVED" ? (
          <DetailReserved item={item} image={image} />
        ) : (
          <DetailClose item={item} image={image} />
        )}
      </Modal.Common>
    </React.Fragment>
  );
};

export default ModalDetail;
