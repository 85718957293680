import { Popover } from "@headlessui/react";
import React from "react";
import { BASE_URL_PREVIEW_IMG } from "../../../assets/constants/constants";
import { BgNoData, IconThreeDots } from "../../../assets/images";
import Image from "../../../components/Image";
import InfiniteScroll from "../../../components/InfiniteScroll";

const CardGrid = ({ data, setAction, moreCourts, rows }) => {
  return (
    <React.Fragment>
      <InfiniteScroll
        hasMore={rows > data?.length}
        next={moreCourts}
        dataLength={data?.length}
      >
        <div className="grid grid-cols-12 gap-4 w-full mb-5">
          {data?.length
            ? data?.map((e, i) => (
                <div
                  key={i}
                  className="border-[1px] lg:col-span-3 md:col-span-6 col-span-12 border-gray-300 rounded-[10px] bg-white shadow-lg p-1 transition-all duration-200 hover:shadow hover:border-gray-200 cursor-pointer pb-5 relative"
                >
                  <Image
                    src={`${BASE_URL_PREVIEW_IMG}/${e.pathName}/${e.imageName}`}
                    alt={`image court-${e?.courtName}`}
                    height={230}
                    width={363}
                    className="rounded-[10px] min-w-full h-[230px] object-cover"
                  />
                  <Popover className="my-auto">
                    <Popover.Button className="absolute top-0 right-0">
                      <Image src={IconThreeDots} alt="icon three dots" />
                    </Popover.Button>
                    <Popover.Panel className="absolute right-4 top-10 z-10 mt-2">
                      <div className="min-w-[180px] rounded-lg bg-white border-[1px] border-gray-300 shadow">
                        <p
                          onClick={() => setAction(e, false)}
                          className="cursor-pointer px-4 py-3 hover:bg-primary-50 transition-all duration-100 rounded-t-lg"
                        >
                          Edit
                        </p>
                        <p
                          onClick={() => setAction(e, true)}
                          className="cursor-pointer px-4 py-3 hover:bg-primary-50 transition-all duration-100 rounded-b-lg"
                        >
                          Delete
                        </p>
                      </div>
                    </Popover.Panel>
                  </Popover>
                  <div className="px-2">
                    <p className="text-primary-default font-bold text-xl mt-2">
                      {e?.courtName}
                    </p>
                    <div className="flex justify-start gap-1 my-1">
                      <Image
                        src="/assets/icon-court-active.svg"
                        height={22}
                        width={22}
                        alt="icon court"
                      />
                      <p className="text-primary-default text-sm font-normal">
                        {e?.facility}
                      </p>
                    </div>
                    <p className="text-sm font-normal text-gray-500 line-clamp-3">
                      {e?.description}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
        {data && data.length == 0 && (
          <div className="w-full flex flex-col justify-center items-center text-center mt-28">
            <Image src={BgNoData} height={175} width={175} alt="No Data" />
            <p className="italic text-xl text-gray-600">Courts not found</p>
          </div>
        )}
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default CardGrid;
