import api from "./api";

export const getBank = async (params) => {
  return api
    .get({
      endpoint: "/bank-account",
      port: process.env.REACT_APP_PORT_VENUE,
      queryParam: params,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
