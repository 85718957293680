import api from "./api";

export const putStaff = async (id, body) => {
  return api
    .put({
      endpoint: `/staff/${id}`,
      port: process.env.REACT_APP_PORT_OWNER,
      body,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
