import {
  SET_NEED_PAYMENT_LIST,
  SET_NEW_BOOKING_LIST,
  SET_NEXT_RESERVATION,
  SET_PAYMENT,
  SET_PAYMENT_BALANCE,
  SET_PAYMENT_PAGINATION,
  SET_TODAY_RESERVATION,
  SET_TOTAL_BOOKING,
  SET_AVAILABLE_DISBURSE_BANK
} from "../../assets/constants/reducer.type";

export const setNeedPayment = (payload) => {
  return { type: SET_NEED_PAYMENT_LIST, payload };
};

export const setNewBooking = (payload) => {
  return { type: SET_NEW_BOOKING_LIST, payload };
};

export const setTodayReservation = (payload) => {
  return { type: SET_TODAY_RESERVATION, payload };
};

export const setNextReservation = (payload) => {
  return { type: SET_NEXT_RESERVATION, payload };
};

export const setTotalBooking = (payload) => {
  return { type: SET_TOTAL_BOOKING, payload };
};

export const setPayment = (payload) => {
  return { type: SET_PAYMENT, payload };
};

export const setPaymentBalance = (payload) => {
  return { type: SET_PAYMENT_BALANCE, payload };
};

export const setPaymentPagination = (payload) => {
  return { type: SET_PAYMENT_PAGINATION, payload };
};

export const setAvailableDisburseBank = (payload) => {
  return { type: SET_AVAILABLE_DISBURSE_BANK, payload };
};
