import React from "react";

const Tab = ({
  setTab,
  tab = "general",
  tabs = ["General"],
  className = "",
}) => {
  return (
    <React.Fragment>
      <div
        className={`${className} w-full text-center border-b border-gray-200 shadow-lg`}
      >
        <ul className="flex justify-between">
          {tabs.map((e) => (
            <li
              className="w-full cursor-pointer"
              onClick={() => setTab(e.toLowerCase())}
            >
              <p
                className={`p-4 pb-3 text-[14px] inline-block rounded-t-lg form-tabs${
                  tab.toLowerCase() === e.toLowerCase() && "-active"
                } w-full`}
              >
                {e}
              </p>
              {tab.toLowerCase() === e.toLowerCase() && (
                <div className="w-full h-[4.5px] shadow rounded bg-primary-default transition-all duration-200" />
              )}
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default Tab;
