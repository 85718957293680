import api from "./api";

export const postLogin = async (
  body = {
    email: null,
    password: null,
  }
) => {
  return api
    .post({
      body,
      endpoint: "/auth/login",
      port: process.env.REACT_APP_PORT_OWNER,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
