import React from "react";
import { IconCards, IconList, IconSearch } from "../../../assets/images";
import Image from "../../../components/Image";
import SelectOption from "../../../components/Input/SelectOptions";
import InputText from "../../../components/Input/Text";
import { useSelector } from "react-redux";

const HeaderCourt = ({
  setGrid,
  grid = "list",
  setFacility,
  facility,
  setName,
  name,
}) => {
  /* Redux */
  const { facilities } = useSelector((state) => state.common);
  return (
    <React.Fragment>
      <div className="grid grid-cols-3 mt-7 border-b-[1px] border-gray-300 pb-5">
        <div className="col-span-1 flex justify-start gap-2 my-auto">
          <div
            className={`rounded-lg h-10 w-10 p-2 justify-center flex ${
              grid === "list"
                ? "bg-primary-default"
                : "bg-[#7B7C80] cursor-pointer hover:bg-green-800 transition-all duration-200"
            }`}
            onClick={() => grid !== "list" && setGrid("list")}
          >
            <Image src={IconList} alt="sportirena list" />
          </div>
          <div
            className={`rounded-lg h-10 w-10 p-2 justify-center flex ${
              grid === "grid"
                ? "bg-primary-default"
                : "bg-[#7B7C80] cursor-pointer hover:bg-green-800 transition-all duration-200"
            }`}
            onClick={() => grid !== "grid" && setGrid("grid")}
          >
            <Image src={IconCards} alt="sportirena list" />
          </div>
        </div>
        <div className="col-span-2 flex gap-3 justify-between">
          <div className="w-full my-auto">
            <SelectOption
              placeholder="Choose Facility"
              options={facilities}
              setValue={setFacility}
              value={facility}
              isClearable
            />
          </div>
          <div className="w-full my-auto">
            <InputText
              placeholder="Search by name"
              icon={IconSearch}
              iconPosition="left"
              iconClassName="h-4"
              setValue={setName}
              value={name}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderCourt;
