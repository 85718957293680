export const dataSidebars = [
  {
    id: 1,
    label: "Home",
    menus: [
      {
        id: "1.1",
        title: "Dashboard",
        path: "/dashboard",
        iconPath: "/assets/icon-home.svg",
        iconPathActive: "/assets/icon-home-active.svg",
      },
      {
        id: "1.2",
        title: "Calendar",
        path: "/calendar",
        iconPath: "/assets/icon-calendar.svg",
        iconPathActive: "/assets/icon-calendar-active.svg",
      },
      {
        id: "1.3",
        title: "Payment Report",
        path: "/payment-report",
        iconPath: "/assets/icon-payment-report.svg",
        iconPathActive: "/assets/icon-payment-report-active.svg",
      },
      {
        id: "1.4",
        title: "Payment",
        path: "/payment",
        iconPath: "/assets/icon-payment.svg",
        iconPathActive: "/assets/icon-payment-active.svg",
      },
    ],
  },
  {
    id: 2,
    label: "Booking",
    menus: [
      {
        id: "2.1",
        title: "Booking Management",
        path: "/booking-management",
        iconPath: "/assets/icon-papper-paragraph.svg",
        iconPathActive: "/assets/icon-papper-paragraph-active.svg",
      },
    ],
  },
  {
    id: 3,
    label: "Master & Configuration",
    menus: [
      {
        id: "3.1",
        title: "My Venue",
        path: "/venue",
        iconPath: "/assets/icon-my-venue.svg",
        iconPathActive: "/assets/icon-my-venue-active.svg",
      },
      {
        id: "3.2",
        title: "Time Booking",
        path: "/time-booking",
        iconPath: "/assets/icon-clock.svg",
        iconPathActive: "/assets/icon-clock-active.svg",
      },
      {
        id: "3.3",
        title: "Court Management",
        path: "/court-management",
        iconPath: "/assets/icon-court.svg",
        iconPathActive: "/assets/icon-court-active.svg",
      },
      {
        id: "3.4",
        title: "Bank Account",
        path: "/bank-account",
        iconPath: "/assets/icon-papper-paragraph.svg",
        iconPathActive: "/assets/icon-papper-paragraph-active.svg",
      },
      {
        id: "3.5",
        title: "Gallery",
        path: "/gallery",
        iconPath: "/assets/icon-image.svg",
        iconPathActive: "/assets/icon-image-active.svg",
      },
    ],
  },
  {
    id: 4,
    label: "User Management",
    menus: [
      {
        id: "4.1",
        title: "Staff Management",
        path: "/staff-management",
        iconPath: "/assets/icon-people.svg",
        iconPathActive: "/assets/icon-people-active.svg",
      },
      {
        id: "4.2",
        title: "Membership",
        path: "/membership",
        iconPath: "/assets/icon-membership.svg",
        iconPathActive: "/assets/icon-membership-active.svg",
      },
      // {
      //   id: "4.3",
      //   title: "Roles",
      //   path: "/roles",
      // },
    ],
  },
  {
    id: 4,
    label: "Settings",
    menus: [
      {
        id: "5.1",
        title: "Configuration",
        path: "/configuration",
        iconPath: "/assets/icon-people.svg",
        iconPathActive: "/assets/icon-people-active.svg",
      },
      {
        id: "5.2",
        title: "General",
        path: "/setting-general",
        iconPath: "/assets/icon-people.svg",
        iconPathActive: "/assets/icon-people-active.svg",
      },
    ],
  },
];
