import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Popover } from "@headlessui/react";
import Modal from "../../components/Modal";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Image from "../../components/Image";
import { IconPencil, IconTrash } from "../../assets/images";
import FormModal from './FormModal';
import { useSelector } from "react-redux";
import { useMyBankAccount } from '../../hooks/useMyBankAccount'

const BankAccount = () => {
  const [showForm, setShowForm] = useState(false);
  const [id, setId] = useState(null);
  const [modalAction, setModalAction] = useState(null);
  const validationSchema = Yup.object().shape({
    bankName: Yup.object().nullable().required("Bank Name required"),
    bankAccountHolder: Yup.string().required("Bank Account Holder required"),
    bankAccountNumber: Yup.string().required("Bank Account Number required"),
  });
  const { list } = useSelector((state) => state.mybank);
  const { 
    myBankAccount,
    createMyBankAccount,
    dropMyBankAccount,
    updateMyBankAccount
  } = useMyBankAccount();
  
  const {
    control,
    setValue,
    getValues,
    reset,
    register,
    formState: { isSubmitting, isValid, errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    myBankAccount();
  }, []);
  const onToggleModal = () => {
    setShowForm(!showForm);
    setId(null);
    reset();
  }
  
  const onSubmit = async (e) => {
    e.preventDefault();
    const payload = getValues();
    if (id) {
      setModalAction({
        label: 'Are you sure want to update this bank account?',
        title: 'Update Bank Account',
        click: () => editBankAccount(id, payload)
      })
    } else {
      await createMyBankAccount({ 
        bankAccountHolder: payload.bankAccountHolder,
        bankAccountNumber: payload.bankAccountNumber,
        bankName: payload?.bankName?.value,
      });
    }
    onToggleModal();
  }

  const editBankAccount = async (id, payload) => {
    await updateMyBankAccount(id, { 
      bankAccountHolder: payload.bankAccountHolder,
      bankAccountNumber: payload.bankAccountNumber,
      bankName: payload?.bankName?.value,
    });
    setModalAction(null);
  }

  const deleteBankAccount = async (id) =>{
    await dropMyBankAccount(id);
    setModalAction(null);
  }

  const showUpdate = async (e) => {
    setId(e.id);
    setValue('bankName', { label: e.bankName, value: e.bankName });
    setValue('bankAccountHolder', e.bankAccountHolder);
    setValue('bankAccountNumber', e.bankAccountNumber);
    setShowForm(true);
  }

  return (
    <React.Fragment>
      <FormModal
        errors={errors}
        isSubmitting={isSubmitting}
        isValid={isValid}
        onToggleModal={onToggleModal}
        register={register}
        showForm={showForm}
        control={control}
        onSubmit={onSubmit}
        isUpdate={id ? true : false}
      />
      <div className="py-7 px-11">
        <div className="grid grid-cols-5">
          <p className="font-semibold text-2xl text-left col-span-4">
            Bank Account
            <span className="text-medium text-gray-500 font-normal block mt-1">
              Manage all your existing bank account or edit or add new bank account
            </span>
          </p>
          <Button
            btnType="button"
            label="+ Add Bank Account"
            type="primary"
            className="px-12 text-sm w-12"
            isDisable={false}
            onClick={() => setShowForm(true)}
          />
        </div>
        <Table
          // loading={loadingProduct}
          usePagination
          data={list}
          textTransform="none"
          columns={[
            {
              name: <p className="text-center w-full">No</p>,
              width: "8%",
              cell: (_row, i) => {
                return <p className="text-center w-full">{i + 1}</p>;
              },
            },
            {
              name: "Bank Name",
              sortable: true,
              selector: (row) => row.bankName || "-",
            },
            {
              name: "Bank Account Holder",
              sortable: true,
              selector: (row) => row.bankAccountHolder || "-",
            },
            {
              name: "Bank Account Number",
              sortable: true,
              selector: (row) => row.bankAccountNumber || "-",
            },
            {
              name: "Action",
              width: "13%",
              cell: (row, i) => (
                <div className="flex gap-3 absolute">
                  <div onClick={() =>  showUpdate(row)} className="p-[8px] bg-primary-50 rounded-lg hover:opacity-[0.7]">
                    <Image
                      src={IconPencil}
                      alt="Icon Pencil"
                      height={25}
                      width={25}
                    />
                  </div>
                  <div
                    onClick={() => setModalAction({
                          label: 'Are you sure want to remove this bank account?',
                          title: 'Remove Bank Account',
                          click: () => deleteBankAccount(row.id)
                        })}
                    className="p-[8px] bg-red-50 rounded-lg hover:opacity-[0.7]">
                    <Image src={IconTrash} alt="Icon Trash" height={25} width={25} />
                  </div>
                </div>
              ),
            },
          ]}
        />
        <Modal.Delete
          showModalDelete={modalAction ? true :  false }
          label={modalAction?.label}
          onCancel={() => setModalAction(null)}
          title={modalAction?.title}
          onDelete={modalAction?.click}
        />
      </div>
    </React.Fragment>
  );
};

export default BankAccount;
