import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IconCalendar } from "../../../assets/images";
import Button from "../../../components/Button";
import FormDate from "../../../components/Input/Date";
import SelectOption from "../../../components/Input/SelectOptions";
import Modal from "../../../components/Modal";
import { useCourt } from "../../../hooks/useCourt";
import { useTimeBooking } from "../../../hooks/useTimeBooking";

const AddException = ({
  showException,
  setShowException,
  choosenCourt,
  choosenTime,
}) => {
  const [court, setCourt] = useState(null);
  const [date, setDate] = useState(null);
  const [day, setDay] = useState(null);
  const [times, setTimes] = useState();

  const { allcourts } = useSelector((state) => state.court);
  const { masterDaysTime, masterTimeInactive } = useSelector(
    (state) => state.timebooking
  );

  const { fetchAllCourts } = useCourt();
  const {
    fetchMasterTimeDays,
    createException,
    fetchMasterTimeInactive,
    loading,
  } = useTimeBooking();

  useEffect(() => {
    if (!showException && !choosenTime) {
      setDate(undefined);
      setDay(undefined);
      setTimes(undefined);
    }
  }, [showException, choosenTime]);

  useEffect(() => {
    if (choosenCourt) setCourt(choosenCourt);
  }, [choosenCourt]);

  useEffect(() => {
    fetchAllCourts();
    choosenTime && fetchMasterTimeDays();
  }, []);

  useEffect(() => {
    if (choosenTime) {
      setTimes([
        {
          ...choosenTime,
          value: choosenTime?.startEndTime,
          label: `${choosenTime?.startTime} - ${choosenTime?.endTime}`,
        },
      ]);
      setDay({
        value: choosenTime?.day,
        label: choosenTime?.day,
      });
    }
  }, [choosenTime]);

  useEffect(() => {
    if (court && day && !choosenTime) {
      fetchMasterTimeInactive({ courtId: court?.value, day: day?.value });
      setTimes([]);
    }
  }, [court, day, choosenTime]);

  const onSubmit = async () => {
    const payload = {
      courtId: court?.value,
      date: moment(new Date(date)).format("yyyy-MM-DD"),
      day: day?.value,
      times,
    };
    await createException(payload);
    setShowException(false);
  };
  return (
    <React.Fragment>
      <Modal.Common
        showModal={showException || false}
        closeModal={() => setShowException(false)}
        title="Exception"
      >
        <div className="lg:w-[500px] md:w-[500px] px-10 pb-10">
          <SelectOption
            label="Court"
            placeholder="Choose Court"
            value={court}
            setValue={setCourt}
            options={allcourts}
            disabled={!!choosenCourt && !!choosenTime}
          />
          <div className="my-4 flex justify-between gap-3">
            <div className="block">
              <FormDate
                filterDay={!!choosenTime ? day?.value : undefined}
                label="Date"
                placeholder="mm/dd/yyyy"
                minDate={new Date()}
                icon={IconCalendar}
                iconPosition="left"
                selectedDate={date}
                setDate={(value) => {
                  setDate(value);
                  setDay(
                    value
                      ? {
                          value: moment(new Date(value))
                            .locale("en")
                            .format("dddd")
                            .toUpperCase(),
                          label: moment(new Date(value))
                            .locale("en")
                            .format("dddd")
                            .toUpperCase(),
                        }
                      : undefined
                  );
                }}
                format="MM/dd/yyyy"
              />
            </div>
            <div className="block w-[50%]">
              <SelectOption
                label="Day"
                placeholder="Choose Day"
                value={day}
                setValue={setDay}
                options={masterDaysTime?.master_days?.map((e) => ({
                  value: e,
                  label: e,
                }))}
                disabled={!!choosenCourt}
              />
            </div>
          </div>
          <SelectOption
            closeMenuOnSelect={false}
            isMulti
            labelClassName="mt-2"
            label="Booking Time"
            value={times}
            setValue={setTimes}
            placeholder="Choose Booking Time"
            disabled={
              !!choosenTime || (!choosenTime && !masterTimeInactive?.length)
            }
            options={(choosenTime
              ? masterDaysTime?.master_times
              : masterTimeInactive
            )?.map((e) => ({
              ...e,
              value: e?.startEndTime,
              label: `${e?.startTime} - ${e?.endTime}`,
            }))}
          />
          <div className="block w-[50%] mt-4">
            <SelectOption
              label="Status"
              placeholder="Choose Status"
              value={{ value: "Open", label: "Open" }}
              options={[{ value: "Open", label: "Open" }]}
              disabled
            />
          </div>
          <div className="flex justify-end gap-4 mt-20">
            <Button
              label="Save"
              type="primary"
              size="sm"
              className="!w-fit px-12"
              isDisable={!court || !date || !day || !times?.length}
              onClick={onSubmit}
              isSubmit={loading}
            />
            <Button
              className="!w-fit px-12"
              label="Cancel"
              type="outline-primary"
              size="sm"
              isDisable={false}
              onClick={() => setShowException(false)}
            />
          </div>
        </div>
      </Modal.Common>
    </React.Fragment>
  );
};

export default AddException;
