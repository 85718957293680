import React from "react";
import InputText from "../../../components/Input/Text";
import SelectOption from "../../../components/Input/SelectOptions";

const GeneralForm = ({ register, errors, control }) => {
  return (
    <React.Fragment>
      <div className="px-10 py-5">
        <InputText
          name="firtsName"
          required
          type="text"
          label="Name"
          placeholder="Insert name of staff/admin"
          register={register}
          errorMessage={errors?.firtsName?.message?.toString()}
        />
        <InputText
          labelClassName="mt-4"
          required
          name="email"
          type="email"
          label="Email"
          placeholder="Insert email address"
          register={register}
          errorMessage={errors?.email?.message?.toString()}
        />
        <SelectOption
          labelClassName="mt-4"
          name="role"
          label="Role"
          placeholder="Choose role"
          required
          options={[
            {
              label: "Admin",
              value: "Admin",
            },
            {
              label: "Staff",
              value: "Staff",
            },
          ]}
          control={control}
          errorMessage={errors?.role?.message?.toString()}
        />
        <SelectOption
          labelClassName="mt-4"
          name="status"
          label="Status"
          placeholder="Choose status"
          required
          options={[
            {
              label: "Active",
              value: "Active",
            },
            {
              label: "Inactive",
              value: "Inactive",
            },
          ]}
          control={control}
          errorMessage={errors?.status?.message?.toString()}
        />
      </div>
    </React.Fragment>
  );
};

export default GeneralForm;
