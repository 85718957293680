import api from "../api";

export const createOrUpdateConfig = async (body) => {
  return api
    .post({
      endpoint: "/config-venue/create-or-update",
      port: process.env.REACT_APP_PORT_VENUE,
      body
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
