import api from "./api";

export const getPaymentReportExport = async (params) => {
  return api
    .get({
      endpoint: "/reporting/download",
      port: process.env.REACT_APP_PORT_REPORT,
      queryParam: params,
      config: {
        responseType: "blob",
      },
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
