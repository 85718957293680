import api from "./api";

export const patchBulkEditTimeBooking = async (body) => {
  return api
    .patch({
      body,
      endpoint: "/all/hour/update-bulk/time-booking",
      port: process.env.REACT_APP_PORT_OPENHOUR,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
