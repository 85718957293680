import React, { useMemo } from "react";
import { IconCamera } from "../../../assets/images";
import Image from "../../Image";
import { toastNotif } from "../../../utils/toastNotif";
import "./UploadImage.style.css";

const Banner = ({
  images = [],
  setImages,
  maxSize = 1000000,
  height = 371,
  text,
  textClassName = "top-[63%] right-[34.5%]",
}) => {
  const uploadImage = (event) => {
    const input = event.target;
    const reader = new FileReader();
    if (input.files[0]) {
      if (
        input.files[0].type === "image/png" ||
        input.files[0].type === "image/jpeg"
      ) {
        if (input.files[0].size < maxSize) {
          reader.onload = () => {
            const ext = "." + input.files[0].type.split("image/")[1];
            const name = input.files[0].name.split("." + ext)[0];
            const dataURL = reader.result;
            const output = input.files[0];
            output["src"] = dataURL;
            const payload = {
              id: !images.length ? 1 : images[images.length - 1].id + 1,
              base64: output.src,
              fileImage: input.files[0],
              name,
              ext,
            };
            setImages([payload]);
          };
          reader.readAsDataURL(input.files[0]);
        } else {
          const message = `Ukuran Foto Maksimal ${maxSize / 1000000}MB!`;
          toastNotif({ message, type: "error" });
        }
      } else {
        const message = "Format Foto Salah!";
        toastNotif({ message, type: "error" });
      }
    }
  };

  return (
    <React.Fragment>
      <div
        className="inner-image-upload-wrap w-full"
        style={{
          backgroundImage: `url(${images[0]?.base64 || images[0]?.src})`,
          height,
        }}
      >
        <input
          className="file-upload-input w-fit z-10"
          id="input-file"
          type="file"
          accept="image/png, image/jpeg"
          onChange={uploadImage}
        />
        <div className="text-center h-full flex">
          <Image
            src={IconCamera}
            alt="upload images goapotik"
            className="m-auto icon-camera"
            height={48}
            width={48}
          />
          {text && textClassName && (
            <p className={`absolute text-white z-0 ${textClassName}`}>{text}</p>
          )}
        </div>
      </div>
      <p className="text-danger-default text-sm">Max Size 1 Mb</p>
    </React.Fragment>
  );
};

export default Banner;
