import React from "react";
import InputText from "../../../components/Input/Text";
import Phone from "./Phone";
import Sosmed from "./Sosmed";

const VenueContact = ({ errors, register }) => {
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-12 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Email Contact</p>
          <p className="font-normal text-medium">
            This email will receive some information about sportirena
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="email"
              required
              type="email"
              placeholder="Insert email contact"
              register={register}
              errorMessage={errors?.email?.message?.toString()}
            />
          </div>
        </div>
      </div>
      <Phone errors={errors} register={register} />
      <Sosmed errors={errors} register={register} />
    </React.Fragment>
  );
};

export default VenueContact;
