import api from "../api";

export const getMyConfiguration = async () => {
  return api
    .get({
      endpoint: "/config-venue/all",
      port: process.env.REACT_APP_PORT_VENUE
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
