import {
  SET_BOOKINGS,
  SET_BOOKINGS_ALL_HOURS,
  SET_CART_BOOKINGS,
  SET_CUSTOMERS,
  SET_DASHBOARD_CALENDAR,
  SET_DETAIL_BOOKING,
  SET_LIST_BOOKING,
  SET_LIST_CART,
  SET_QUERY_BOOKING,
  SET_ROWS_BOOKING,
  SET_TTL_CART,
} from "../../assets/constants/reducer.type";

export const setListBooking = (payload) => {
  return { type: SET_LIST_BOOKING, payload };
};

export const setRowsBooking = (payload) => {
  return { type: SET_ROWS_BOOKING, payload };
};

export const setQueryBooking = (payload) => {
  return { type: SET_QUERY_BOOKING, payload };
};

export const setDetailBooking = (payload) => {
  return { type: SET_DETAIL_BOOKING, payload };
};

export const setCutomers = (payload) => {
  return { type: SET_CUSTOMERS, payload };
};

export const setBookings = (payload) => {
  return { type: SET_BOOKINGS, payload };
};

export const setBookingsAllHours = (payload) => {
  return { type: SET_BOOKINGS_ALL_HOURS, payload };
};

export const setCartBookings = (payload) => {
  return { type: SET_CART_BOOKINGS, payload };
};

export const setTotalCart = (payload) => {
  return { type: SET_TTL_CART, payload };
};

export const setListCart = (payload) => {
  return { type: SET_LIST_CART, payload };
};

export const setDashboardCalendar = (payload) => {
  return { type: SET_DASHBOARD_CALENDAR, payload };
};
