import api from "./api";

export const putResponseBooking = async (id, body) => {
  return api
    .put({
      endpoint: `/booking/own/approved-rejected/${id}`,
      body,
      port: process.env.REACT_APP_PORT_BOOK,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
