import moment from "moment";
import React from "react";
import Button from "../../../components/Button";
import InputText from "../../../components/Input/Text";
import InputTextarea from "../../../components/Input/Textarea";
import Modal from "../../../components/Modal";

const Detail = ({ data, setData }) => {
  return (
    <Modal.Common
      showModal={!!data}
      title="Detail Anggota"
      closeModal={() => setData(undefined)}
    >
      <div className="w-[512px] px-10 pb-10">
        <InputText
          label="Nama"
          placeholder="Detail nama"
          value={data?.customerName || "-"}
          type="text"
          disabled
        />
        <InputText
          label="Email"
          labelClassName="mt-3"
          placeholder="Detail email"
          value={data?.customerEmail || "-"}
          type="text"
          disabled
        />
        <InputText
          label="Telepon"
          labelClassName="mt-3"
          placeholder="Detail telepon"
          value={data?.customerPhone || "-"}
          type="text"
          disabled
        />
        {data?.createdAt ? (
          <InputText
            label="Tanggal Pendaftaran"
            labelClassName="mt-3"
            placeholder="Detail tanggal pendaftaran"
            value={
              moment(new Date(data?.createdAt)).format("DD/MM/yyyy HH:mm") ||
              "-"
            }
            type="text"
            disabled
          />
        ) : undefined}
        {data?.expiredDate ? (
          <InputText
            label="Tanggal Kadaluarsa"
            labelClassName="mt-3"
            placeholder="Detail tanggal kadaluarsa"
            value={
              moment(new Date(data?.expiredDate)).format("DD/MM/yyyy HH:mm") ||
              "-"
            }
            type="text"
            disabled
          />
        ) : undefined}
        <InputText
          label="Status"
          labelClassName="mt-3"
          placeholder="Detail status"
          value={data?.status || "-"}
          type="text"
          disabled
        />
        <InputTextarea
          label="Keterangan Dari Customer"
          labelClassName="mt-3"
          placeholder="Detail keterangan"
          value={data?.remarksCust || "-"}
          type="text"
          disabled
        />
        <InputTextarea
          label="Keterangan Dari Owner"
          labelClassName="mt-3"
          placeholder="Detail keterangan"
          value={data?.remarks || ""}
          type="text"
          disabled
        />
        <div className="flex justify-end">
          <div className="w-fit">
            <Button
              className="mt-8 tracking-wider font-[14px] px-12"
              size="sm"
              label="Keluar"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={() => setData(undefined)}
            />
          </div>
        </div>
      </div>
    </Modal.Common>
  );
};

export default Detail;
