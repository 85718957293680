import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { IconEyeOutlineGreen, IconHistoryGreen } from "../../../assets/images";
import Image from "../../../components/Image";
import Table from "../../../components/Table";

const MemberList = ({
  setDetail,
  setHistory,
  loading,
  page,
  pageSize,
  setPage,
  setPageSize,
}) => {
  const { members } = useSelector((state) => state.myvenue);
  return (
    <Table
      progressPending={loading}
      usePagination
      paginationPerPage={pageSize}
      onChangePage={() => setPage(page + 1)}
      onChangeRowsPerPage={setPageSize}
      paginationTotalRows={members?.totalRow}
      paginationServer
      subHeader={false}
      data={members?.data}
      paginationRowsPerPageOptions={[10, 15, 20]}
      textTransform="none"
      columns={[
        {
          name: <p className="text-center w-full">No</p>,
          width: "8%",
          cell: (_row, i) => {
            return <p className="text-center w-full">{i + 1}</p>;
          },
        },
        {
          name: "Nama",
          sortable: true,
          selector: (row) => row?.customerName || "-",
        },
        {
          name: "Email",
          sortable: true,
          selector: (row) => row?.customerEmail || "-",
        },
        {
          name: "No. Telepon",
          selector: (row) => row?.customerPhone || "-",
        },
        {
          name: "Tanggal Pendaftaran",
          sortable: true,
          selector: (row) =>
            moment(new Date(row?.createdAt)).format("DD/MM/yy HH:mm") || "-",
        },
        {
          name: "Tanggal Expired",
          sortable: true,
          selector: (row) =>
            moment(new Date(row?.expiredDate)).format("DD/MM/yy HH:mm") || "-",
        },
        {
          name: "Status",
          sortable: true,
          selector: (row) => row?.status || "-",
          width: "8%",
        },
        {
          name: "Action",
          width: "13%",
          cell: (row, i) => {
            return (
              <div className="flex justify-between gap-3" key={i}>
                <div className="p-[8px] bg-primary-50 rounded-lg hover:opacity-[0.7]">
                  <Image
                    src={IconHistoryGreen}
                    alt="IconHistoryGreen"
                    height={25}
                    width={25}
                    onClick={() => setHistory(row?.customerId)}
                  />
                </div>
                <div className="p-[8px] bg-primary-50 rounded-lg hover:opacity-[0.7]">
                  <Image
                    src={IconEyeOutlineGreen}
                    alt="IconEyeOutlineGreen"
                    height={25}
                    width={25}
                    onClick={() => setDetail(row)}
                  />
                </div>
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default MemberList;
