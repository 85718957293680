import React, { useState } from "react";
import { useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { useCourt } from "../../../hooks/useCourt";
import CardGrid from "./CardGrid";
import CardList from "./CardList";

const BodyCourt = ({ grid = "list", onGetDetail, moreCourts }) => {
  /* Local State */
  const [tempId, setTempId] = useState();

  /* Redux */
  const { list } = useSelector((state) => state.court);

  /* Hooks */
  const { removeCourt } = useCourt();

  const onAction = (data, isDelete) => {
    if (isDelete) {
      return setTempId(data?.id);
    }
    return onGetDetail(data);
  };

  const onDeleteCourt = async () => {
    await removeCourt(tempId);
    setTempId();
  };

  return (
    <React.Fragment>
      <Modal.Delete
        showModalDelete={tempId ? true : false}
        label="Are you sure want to remove this court?"
        onCancel={() => setTempId()}
        title="Remove Court"
        onDelete={onDeleteCourt}
      />
      <div className="pt-5 mb-52">
        <p className="text-gray-500 text-md font-[300] mb-5">
          Show{" "}
          <span className="font-bold text-primary-default">
            {list?.rows} Courts available
          </span>
        </p>
        {grid === "list" ? (
          <CardList
            data={list?.data}
            rows={list?.rows}
            setAction={onAction}
            moreCourts={moreCourts}
          />
        ) : (
          <CardGrid
            data={list?.data}
            rows={list?.rows}
            setAction={onAction}
            moreCourts={moreCourts}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default BodyCourt;
