import React from "react";
import { BASE_URL_PREVIEW_IMG } from "../../../assets/constants/constants";
import { BgNoData, IconPencil, IconTrash } from "../../../assets/images";
import Image from "../../../components/Image";
import InfiniteScroll from "../../../components/InfiniteScroll";

const CardList = ({ data, setAction, moreCourts, rows }) => {
  return (
    <React.Fragment>
      <InfiniteScroll
        hasMore={rows > data?.length}
        next={moreCourts}
        dataLength={data?.length}
      >
        {data?.length ? (
          data?.map((e, i) => (
            <div
              key={i}
              className="w-full border-[1px] border-gray-300 rounded-[10px] bg-white shadow-lg p-5 lg:flex md:flex justify-start gap-4 transition-all duration-200 hover:shadow hover:border-gray-200 cursor-pointer mb-5"
            >
              <Image
                src={`${BASE_URL_PREVIEW_IMG}/${e.pathName}/${e.imageName}`}
                alt={`image court-${e?.courtName}`}
                height={230}
                width={363}
                className="rounded-[10px] lg:min-w-[363px] md:min-w-[363px] h-[230px] object-cover"
              />
              <div className="w-full">
                <p className="text-primary-default font-bold text-xl">
                  {e?.courtName}
                </p>
                <div className="flex justify-start gap-1 my-2">
                  <Image
                    src="/assets/icon-court-active.svg"
                    height={22}
                    width={22}
                    alt="icon court"
                  />
                  <p className="text-primary-default text-medium font-normal">
                    {e?.facility}
                  </p>
                </div>
                <p className="text-medium font-normal text-gray-500 w-full">
                  {e?.description}
                </p>
                <div className="flex justify-end gap-3 pt-15">
                  <div
                    className="p-[8px] bg-primary-50 rounded-lg hover:opacity-[0.7]"
                    onClick={() => setAction(e, false)}
                  >
                    <Image
                      src={IconPencil}
                      alt="Icon Pencil"
                      height={25}
                      width={25}
                    />
                  </div>
                  <div
                    className="p-[8px] bg-red-50 rounded-lg hover:opacity-[0.7]"
                    onClick={() => setAction(e, true)}
                  >
                    <Image
                      src={IconTrash}
                      alt="Icon Trash"
                      height={25}
                      width={25}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="flex flex-col justify-center items-center text-center mt-28">
            <Image src={BgNoData} height={175} width={175} alt="No Data" />
            <p className="italic text-xl text-gray-600">Courts not found</p>
          </div>
        )}
      </InfiniteScroll>
    </React.Fragment>
  );
};

export default CardList;
