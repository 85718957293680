import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { IconArrowRed, IconSearch, IconWallet } from "../../assets/images";
import Button from "../../components/Button";
import Image from "../../components/Image";
import InputText from "../../components/Input/Text";
import Table from "../../components/Table";
import { useDashboard } from "../../hooks/useDashboard";
import { IDRFormat } from "../../utils/formatters";
import { retrieveLocalStorageItem } from "../../utils/localStorage";
import CreateDisbursement from "./CreateDisbursement";
import ExportReport from "./ExportReport";
import { PaymentDateRange, PaymentFilter } from "./Filters";

const Payment = () => {
  const [reference, setReference] = useState("");
  const pageSize = 10;
  const [showForm, setShowForm] = useState(false);
  const [showExport, setShowExport] = useState(false);

  const { payment, paymentBalance, paymentPagination } = useSelector(
    (state) => state.dashboard
  );

  const { fetchPayment, fetchPaymentBalance, loading } = useDashboard();

  useEffect(() => {
    fetchPaymentBalance();
    fetchPayment(
      {
        limit: pageSize,
        after_id: "",
        list_id: "",
      },
      false
    );
  }, []);

  useEffect(() => {
    fetchPayment(
      {
        statuses: paymentPagination?.params?.statuses || "",
        channel_categories: paymentPagination?.params?.channel_categories || "",
        types: paymentPagination?.params?.types || "",
        created_from: paymentPagination?.params?.created_from || "",
        created_to: paymentPagination?.params?.created_to || "",
        updated_from: paymentPagination?.params?.updated_from || "",
        updated_to: paymentPagination?.params?.updated_to || "",
        limit: pageSize,
        after_id: "",
        list_id: "",
        reference_id: reference,
      },
      false
    );
  }, [reference]);

  const onShowMore = async () => {
    fetchPayment(
      {
        ...paymentPagination?.params,
        limit: pageSize,
        after_id: paymentPagination?.next_id || "",
        list_id: paymentPagination?.list_id || "",
      },
      true
    );
  };

  const onLoadPaymentByFilter = (payload = {}) => {
    fetchPayment(
      {
        ...payload,
        created_from: paymentPagination?.params?.created_from || "",
        created_to: paymentPagination?.params?.created_to || "",
        updated_from: paymentPagination?.params?.updated_from || "",
        updated_to: paymentPagination?.params?.updated_to || "",
        reference_id: reference,
        limit: pageSize,
        after_id: "",
        list_id: "",
      },
      false
    );
  };

  const onLoadPaymentByDate = (payload = {}) => {
    fetchPayment(
      {
        ...payload,
        statuses: paymentPagination?.params?.statuses || "",
        channel_categories: paymentPagination?.params?.channel_categories || "",
        types: paymentPagination?.params?.types || "",
        reference_id: reference,
        limit: 2,
        after_id: "",
        list_id: "",
      },
      false
    );
  };

  const role = useMemo(() => {
    return JSON.parse(
      retrieveLocalStorageItem("info") || "{}"
    )?.role?.toLowerCase();
  }, [retrieveLocalStorageItem("info")]);
  return (
    <React.Fragment>
      <CreateDisbursement setShowForm={setShowForm} showForm={showForm} />
      <ExportReport setShowForm={setShowExport} showForm={showExport} />
      <div className="pt-7 pb-36 px-11">
        <p className="font-semibold text-2xl text-left">
          Payment
          <span className="text-medium text-gray-500 font-normal block mt-1">
            Manage all your active balance and disbursement
          </span>
        </p>
        <div className="flex justify-between gap-4 p-6 rounded-lg border-[1px] border-gray-300 mt-8">
          <div className="flex justify-start gap-4">
            <img
              src={IconWallet}
              alt="sportirena wallet"
              className="my-auto p-4 rounded-xl shadow-full bg-white"
            />
            <div className="block my-auto">
              <p className="text-primary-default font-bold text-regular">
                Active Balance
              </p>
              <p className="text-lg">{IDRFormat(Number(paymentBalance))}</p>
            </div>
          </div>
          {role === "admin" && (
            <Button
              label="Disburse"
              size="sm"
              isDisable={false}
              type="primary"
              className="my-auto !w-fit !px-12"
              onClick={() => setShowForm(true)}
            />
          )}
        </div>
        <div className="mt-4 mb-2 flex justify-start gap-3">
          <PaymentFilter onSubmit={onLoadPaymentByFilter} />
          <div className="w-[30%]">
            <PaymentDateRange onSubmit={onLoadPaymentByDate} />
          </div>
          <InputText
            placeholder="Search by reference"
            icon={IconSearch}
            iconPosition="left"
            iconClassName="h-4"
            setValue={setReference}
            labelClassName="!w-[40%]"
            value={reference}
          />
          <div className="flex w-[30%] justify-end">
            <Button
              label="Export"
              size="sm"
              isDisable={false}
              type="warning"
              className="my-auto !w-fit !px-12"
              onClick={() => setShowExport(true)}
            />
          </div>
        </div>
        <Table
          paginationTotalRows={payment?.length}
          paginationServer
          subHeader={false}
          data={payment}
          textTransform="none"
          columns={[
            {
              name: "Completed Date",
              width: "15%",
              selector: (row) =>
                moment(row?.created)?.format("DD MMMM yyyy hh:mm") || "-",
            },
            {
              name: "Status",
              selector: (row) => row?.status || "-",
            },
            {
              name: "Settlement Status",
              selector: (row) => row?.settlement_status || "-",
            },
            {
              name: "Type",
              selector: (row) => row?.type || "-",
            },
            {
              name: "Channel",
              selector: (row) => row?.channel_category || "-",
            },
            {
              name: "Channel Code",
              selector: (row) => row?.channel_code || "-",
            },
            {
              name: "Reference",
              selector: (row) => row?.reference_id || "-",
            },
            {
              name: "Amount",
              selector: (row) => (row?.amount ? IDRFormat(row?.amount) : "-"),
            },
          ]}
        />
        {loading ? (
          <p className="flex justify-center gap-5 text-primary-default text-lg font-semibold mt-10 tracking-[0.3px]">
            Loading...
          </p>
        ) : paymentPagination?.next_id ? (
          <p
            onClick={() => {
              onShowMore();
              // setPage(page + 1);
            }}
            className="cursor-pointer flex justify-center gap-5 text-[#A33E4F] text-lg font-semibold mt-10 tracking-[0.3px] hover:opacity-[0.5] transition-all duration-200"
          >
            Show more result
            <Image src={IconArrowRed} alt="icon arrow" width={30} height={24} />
          </p>
        ) : payment?.length ? (
          <p className="flex justify-center gap-5 text-primary-default text-lg font-semibold mt-10 tracking-[0.3px]">
            All data has been displayed
          </p>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Payment;
