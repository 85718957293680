import React from "react";
import "./Checkbox.style.css";

const Checkbox = ({
  label,
  className,
  checked,
  onClick,
  name = null,
  register,
  id = "checkbox",
}) => {
  const onChange = () => onClick(!checked);
  return (
    <React.Fragment>
      <div className="checkbox-wrapper group">
        {name && register && !onClick ? (
          <input {...register(name)} type="checkbox" id={id} readOnly />
        ) : (
          <input
            type="checkbox"
            checked={checked}
            id={id}
            onClick={onChange}
            readOnly
          />
        )}
        <label htmlFor={id} className={`${className} label-checkbox my-auto`}>
          <span className="ml-1 my-auto font-light">{label}</span>
        </label>
      </div>
    </React.Fragment>
  );
};

export default Checkbox;
