import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import SelectOption from "../../components/Input/SelectOptions";
import Table from "../../components/Table";
import { usePaymentReport } from "../../hooks/usePaymentReport";
import { IDRFormat } from "../../utils/formatters";
import { FilterDateRange, Search, SelectTypeSearch } from "./Filters";

const PaymentReport = () => {
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(moment(new Date()).add(-30, "days"))
  );
  const [endDate, setEndDate] = useState(new Date());
  const [typeSearch, setTypeSearch] = useState();
  const [search, setSearch] = useState();
  const [status, setStatus] = useState();

  const { listPaymentReport } = useSelector((state) => state.paymentreport);

  const { getListPaymentReport, loading, exportPaymentReport } =
    usePaymentReport();

  useEffect(() => {
    if (typeSearch?.value === "invoiceCode" && search?.name?.length >= 5) {
      onLoad();
    } else if (
      typeSearch?.value === "customerName" &&
      search?.name?.length >= 3
    ) {
      onLoad();
    } else if (
      typeSearch?.value === "payment_channel" &&
      search?.name?.length >= 1
    ) {
      onLoad();
    } else if (!search?.name?.length) {
      onLoad();
    }
  }, [page, pageSize, startDate, endDate, typeSearch, search, status]);

  const onLoad = () => {
    getListPaymentReport({
      page,
      pageSize,
      startDate: startDate
        ? moment(new Date(startDate)).format("yyyy-MM-DD")
        : "",
      endDate: endDate ? moment(new Date(endDate)).format("yyyy-MM-DD") : "",
      keySearch: typeSearch?.value || "",
      valueSearch: search?.name || "",
      status: status?.value || "",
    });
  };
  return (
    <React.Fragment>
      <div className="pt-7 pb-36 px-11">
        <p className="font-semibold text-2xl text-left">
          Payment Report
          <span className="text-medium text-gray-500 font-normal block mt-1">
            Records all your payment history
          </span>
        </p>
        <div className="lg:flex my-6 justify-between gap-6 w-full">
          <div className="lg:grid w-full grid-cols-12 gap-4">
            <div className="lg:mt-0 mt-2 col-span-4">
              <FilterDateRange
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
              />
            </div>
            <div className="flex justify-start lg:mt-0 mt-2 col-span-6 w-full">
              <SelectTypeSearch
                placeholder="Choose Type Search"
                setValue={setTypeSearch}
                value={typeSearch}
                options={[
                  {
                    value: "invoiceCode",
                    label: "Invoice Code",
                  },
                  {
                    value: "customerName",
                    label: "Customer Name",
                  },
                  {
                    value: "payment_channel",
                    label: "Payment Channel",
                  },
                ]}
              />
              <Search
                labelClassName="w-full"
                value={search}
                setValue={setSearch}
                placeholder="Search"
              />
            </div>
            <div className="lg:mt-0 mt-2 col-span-2">
              <SelectOption
                isClearable
                placeholder="Status"
                setValue={setStatus}
                value={status}
                options={[
                  {
                    value: "WAITING_FOR_PAYMENT",
                    label: "Waiting Payment",
                  },
                  {
                    value: "PAID",
                    label: "Paid",
                  },
                  {
                    value: "EXPIRED",
                    label: "Expired",
                  },
                ]}
                height="64px"
                fontSize="18px"
              />
            </div>
          </div>
          <Button
            label="Export"
            type="primary"
            size="lg"
            className="lg:w-[200px] lg:mt-0 mt-2"
            isDisable={false}
            onClick={() =>
              exportPaymentReport({
                page,
                pageSize,
                startDate: startDate
                  ? moment(new Date(startDate)).format("yyyy-MM-DD")
                  : "",
                endDate: endDate
                  ? moment(new Date(endDate)).format("yyyy-MM-DD")
                  : "",
                keySearch: typeSearch?.value || "",
                valueSearch: search?.name || "",
                status: status?.value || "",
              })
            }
          />
        </div>
        <Table
          progressPending={loading}
          usePagination
          paginationPerPage={pageSize}
          onChangePage={() => setPage(page + 1)}
          onChangeRowsPerPage={setPageSize}
          paginationTotalRows={listPaymentReport?.count}
          paginationServer
          subHeader={false}
          data={listPaymentReport?.rows}
          paginationRowsPerPageOptions={[10, 15, 20]}
          textTransform="none"
          columns={[
            {
              name: "Invoice",
              width: "12%",
              selector: (row) => row.invoiceCode || "-",
            },
            {
              name: "Customer Name",
              selector: (row) => row.customerName || "-",
            },
            {
              name: "Status",
              width: "6%",
              selector: (row) => row.status || "-",
            },
            {
              name: "Price",
              selector: (row) =>
                row.normalPrice ? IDRFormat(row.normalPrice) : "-",
            },
            {
              name: "Fee TOP",
              width: "8%",
              selector: (row) =>
                row?.totalPrice || row?.normalPrice
                  ? IDRFormat(row.totalPrice - row?.normalPrice)
                  : "-",
            },
            {
              name: "Total Price",
              selector: (row) =>
                row.totalPrice ? IDRFormat(row.totalPrice) : "-",
            },
            {
              name: "Payment Channel",
              selector: (row) => row.payment_channel || "-",
            },
            {
              name: "Date",
              sortable: true,
              width: "15%",
              selector: (row) =>
                moment(row?.payment?.updatedAt).format("DD MMMM yyyy HH:mm") ||
                "-",
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default PaymentReport;
