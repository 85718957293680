import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { BASE_URL_PREVIEW_IMG } from "../../assets/constants/constants";
import Button from "../../components/Button";
import Image from "../../components/Image";
import InputText from "../../components/Input/Text";
import Tab from "../../components/Tab";
import { useAuth } from "../../hooks/useAuth";
import { toastNotif } from "../../utils/toastNotif";
import Security from "./Security";

const Profile = () => {
  /* Local State */
  const [tab, setTab] = useState();
  const [photo, setPhoto] = useState();

  /* Router DOM */
  const { search, state } = useLocation();

  const { fetchProfile, changeProfile, loading } = useAuth();

  const { profile } = useSelector((state) => state.common);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name required"),
    email: Yup.string().email("Invalid email").required("Email required"),
  });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    if (search) {
      setTab("security");
    } else {
      setTab("profile");
    }
  }, [search]);

  useEffect(() => {
    if (profile) {
      setPhoto({
        src: `${BASE_URL_PREVIEW_IMG}/${profile?.pathName}/${profile?.imageName}`,
      });
      setValue("email", profile?.email);
      setValue("name", `${profile?.firstName} ${profile?.lastName}`);
    }
  }, [profile]);

  const uploadImage = (event) => {
    const input = event.target;
    const reader = new FileReader();
    if (input.files[0]) {
      if (
        input.files[0].type === "image/png" ||
        input.files[0].type === "image/jpeg"
      ) {
        if (input.files[0].size < 2000000) {
          reader.onload = () => {
            const ext = "." + input.files[0].type.split("image/")[1];
            const name = input.files[0].name.split("." + ext)[0];
            const dataURL = reader.result;
            const output = input.files[0];
            output["src"] = dataURL;
            const payload = {
              base64: output.src,
              fileImage: input.files[0],
              name,
              ext,
              src: null,
            };
            setPhoto(payload);
          };
          reader.readAsDataURL(input.files[0]);
        } else {
          const message = `Ukuran Foto Maksimal ${2000000 / 1000000}MB!`;
          toastNotif({ message, type: "error" });
        }
      } else {
        const message = "Format Foto Salah!";
        toastNotif({ message, type: "error" });
      }
    }
  };

  const onSubmit = (data) => {
    const names = data?.name?.split(" ");
    changeProfile({
      ...data,
      firstName: names?.shift(),
      lastName: names?.length > 0 ? names?.join(" ") : "",
      base64: photo?.base64 || "",
    });
  };

  return (
    <React.Fragment>
      <div className="py-16 lg:px-18 sm:px-8 px-8 !overflow-x-hidden">
        <Tab tabs={["Profile", "Security"]} setTab={setTab} tab={tab} />
        {tab?.toLowerCase() === "security" ? (
          <Security isForgotPassword={state?.isForgotPassword} />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="lg:grid grid-cols-8 mt-14">
              <div className="col-span-3">
                <Image
                  src={photo?.base64 || photo?.src || "/image"}
                  alt="sportirena"
                  height={172}
                  width={172}
                  className="rounded-full bg-gray-150 w-44 h-44 mx-auto object-cover"
                />
                <div className="w-[191px] mx-auto mt-10">
                  <input
                    className={`absolute mt-3 w-fit z-10 ${
                      loading ? "cursor-not-allowed" : "cursor-pointer"
                    } opacity-0`}
                    id="input-file"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={uploadImage}
                    disabled={loading}
                  />
                  <Button
                    label="Change"
                    type="primary"
                    className="mx-auto"
                    isDisable={false}
                    btnType="button"
                    isSubmit={loading}
                  />
                </div>
              </div>
              <div className="col-span-5 lg:mt-0 mt-4">
                <div className="grid grid-cols-3 mb-10">
                  <div className="col-span-1 my-auto">
                    <p className="font-bold text-gray-500 text-lg my-auto">
                      Name
                    </p>
                  </div>
                  <div className="col-span-2">
                    <InputText
                      name="name"
                      required
                      type="text"
                      placeholder="Insert name"
                      register={register}
                      errorMessage={errors?.name?.message?.toString()}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 mb-14">
                  <div className="col-span-1 my-auto">
                    <p className="font-bold text-gray-500 text-lg my-auto">
                      Email
                    </p>
                  </div>
                  <div className="col-span-2">
                    <InputText
                      name="email"
                      required
                      type="email"
                      placeholder="Insert email"
                      register={register}
                      errorMessage={errors?.email?.message?.toString()}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  <div className="col-span-1">
                    <p className="font-bold text-gray-500 text-lg">QR Code</p>
                  </div>
                  <div className="col-span-2">
                    <Image
                      src="https://ps.w.org/doqrcode/assets/icon-256x256.png?rev=2143781"
                      alt="sportirena"
                      height={142}
                      width={142}
                      className="bg-gray-150 w-36 h-36"
                    />
                  </div>
                </div>
              </div>
            </div>
            {(isDirty || photo?.base64) && (
              <div className="float-right w-52">
                <Button
                  btnType="submit"
                  label="Save"
                  type="primary"
                  className="mt-12"
                  isDisable={false}
                  isSubmit={isSubmitting || loading}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </React.Fragment>
  );
};

export default Profile;
