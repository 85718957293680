
import React, { useState } from "react";

import Modal from "../../../components/Modal";
import "../StaffManagement.style.css";
import GeneralForm from "./GeneralForm";
import PasswordForm from "./PasswordForm";
import Button from "../../../components/Button";

const StaffMForm = ({
  showForm,
  onToggleModal,
  isSubmitting,
  isValid,
  errors,
  register,
  control,
  onSubmit,
  isUpdate
 }) => {
  /* Local State */
  const [isPasswordTab, setIsPasswordTab] = useState(false);

  return (
    <React.Fragment>
      <Modal.Common
        showModal={showForm}
        title={`${isUpdate ? 'Update': 'Add New'} Staff/Admin`}
        description="Add new Staff/Admin by complete form information below"
        closeModal={onToggleModal}
      >
        <div className="w-[55em] mt-5 text-center border-b border-gray-200 shadow-lg">
          <ul className="flex justify-between">
            <li
              className="w-full cursor-pointer"
              onClick={() => setIsPasswordTab(!isPasswordTab)}
            >
              <p
                className={`p-4 pb-3 text-[14px] inline-block rounded-t-lg form-tabs${
                  !isPasswordTab && "-active"
                } w-full`}
              >
                General
              </p>
              {!isPasswordTab && (
                <div className="w-full h-[4.5px] shadow rounded bg-primary-default transition-all duration-200" />
              )}
            </li>
            <li
              className="w-full cursor-pointer"
              onClick={() => setIsPasswordTab(!isPasswordTab)}
            >
              <p
                className={`p-4 pb-3 text-[14px] inline-block rounded-t-lg form-tabs${
                  isPasswordTab && "-active"
                } w-full`}
              >
                Password
              </p>
              {isPasswordTab && (
                <div className="w-full h-[4.5px] shadow rounded bg-primary-default transition-all duration-200" />
              )}
            </li>
          </ul>
        </div>
        <form>
          {isPasswordTab ? (
            <PasswordForm errors={errors} register={register} />
          ) : (
            <GeneralForm
              errors={errors}
              register={register}
              control={control}
            />
          )}
          <div className="flex relative -right-[400px] px-10 gap-5 pb-8 w-[30em]">
            <Button
              className="mt-10 tracking-wider font-[14px]"
              label="Save"
              type="primary"
              btnType="button"
              onClick={onSubmit}
              isDisable={!isValid || isSubmitting}
              isSubmit={isSubmitting && isValid}
            />
            <Button
              className="mt-10 tracking-wider font-[14px]"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={onToggleModal}
            />
          </div>
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default StaffMForm;
