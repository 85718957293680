import React, { useState } from "react";
import moment from "moment";
import { toastNotif } from "../../../utils/toastNotif";
import FormDate from "../../../components/Input/Date";
import { IconCalendar } from "../../../assets/images";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { useDashboard } from "../../../hooks/useDashboard";
import "react-datepicker/dist/react-datepicker.css";
import "../../../components/Input/Date/Date.style.css";
import "moment/locale/id";

const ExportReport = ({ showForm, setShowForm }) => {
  const { generateReport, loading } = useDashboard();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const params = {
        created_from: `${moment(startDate).format('YYYY-MM-DD')}T00:00:00.000Z`,
        created_to: `${moment(endDate).format('YYYY-MM-DD')}T23:59:59.000Z`
      }
      await generateReport(params);
    } catch (e) {
      toastNotif({
        message: e.message || e,
        type: "error",
      });
    } finally {
      setShowForm(false)
    }
  }
  return (
    <Modal.Common
      showModal={showForm}
      title="Export Report"
      description="Balance History Report"
      closeModal={() => setShowForm(false)}
    >
      <form
        onSubmit={onSubmit}
        className="w-[400px] px-10 pb-10"
      >
        {/* <DateRange
            setStartDate={(e) => setStartDate(e)}
            setEndDate={(e) => setEndDate(e)}
            startDate={startDate}
            endDate={endDate}
        /> */}
        <FormDate
            label="Select Range Date"
            placeholder="mm/dd/yyyy"
            icon={IconCalendar}
            iconPosition="left"
            selectsRange
            startDate={startDate}
            endDate={endDate}
            setStartDate={(e) => setStartDate(e)}
            setEndDate={(e) => setEndDate(e)}
            format="dd/MM/yyyy"
        />
        <div className="flex justify-end gap-4">
          <Button
            className="mt-8 tracking-wider font-[14px] w-40"
            label="Export"
            type="primary"
            btnType="submit"
            isDisable={loading}
            // isSubmit={isSubmitting && isValid}
          />
        </div>
      </form>
    </Modal.Common>
  );
};

export default ExportReport;
