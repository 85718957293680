import { Popover } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL_PREVIEW_IMG } from "../../assets/constants/constants";
import { BgNoData, IconStar, IconThreeDots } from "../../assets/images";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Modal from "../../components/Modal";
import { useGallery } from "../../hooks/useGallery";
import FormGallery from "./Form";

const Gallery = () => {
  /* Local State */
  const [showForm, setShowForm] = useState(false);
  const [tempItem, setTempItem] = useState();
  const [modalAction, setModalAction] = useState(null);
  const [modalImage, setModalImage] = useState(null);

  /* Redux */
  const { list } = useSelector((state) => state.gallery);

  /* Hooks */
  const {
    fetchGalleries,
    uploadGallery,
    removeGallery,
    setMainImage,
    loading,
  } = useGallery();

  useEffect(() => {
    fetchGalleries({ page: 0, pageSize: 1000 });
  }, []);

  useEffect(() => {
    if (!loading) setShowForm(false);
  }, [loading]);

  const onDeleteImage = async (id) => {
    await removeGallery(id);
    setModalAction(null);
  };

  const onSetMain = async (id) => {
    await setMainImage(id);
    setModalAction(null);
  };
  return (
    <React.Fragment>
      <Modal.Delete
        showModalDelete={modalAction ? true : false}
        label={modalAction?.label}
        onCancel={() => setModalAction(null)}
        title={modalAction?.title}
        onDelete={modalAction?.click}
      />
      <Modal.Image
        showModal={modalImage ? true : false}
        children={
          <div className="block w-[550px] rounded-md">
            <div
              onClick={() => setModalImage(null)}
              className="absolute top-2 right-3"
              alt="icon star"
            >
              X
            </div>
            <Image
              src={`${BASE_URL_PREVIEW_IMG}/${modalImage?.path}/${modalImage?.name}`}
              alt={`${BASE_URL_PREVIEW_IMG}/${modalImage?.path}/${modalImage?.name}`}
              className="rounded-s-sm w-full h-[250px] object-fill"
            />
            <div className="p-4">
              <h2>Description:</h2>
              <p className="text-xs">{modalImage?.description}</p>
            </div>
          </div>
        }
        title="Images"
      />
      <FormGallery
        setShowForm={setShowForm}
        showForm={showForm}
        onSubmit={uploadGallery}
      />
      <div className="py-7 px-11">
        <div className="grid lg:grid-cols-5 grid-cols-1 gap-3 w-full">
          <p className="font-semibold text-2xl text-left col-span-4">
            Gallery
            <span className="text-medium text-gray-500 font-normal block mt-1">
              Manage all your existing Images or edit or add new Image
            </span>
          </p>
          <Button
            btnType="button"
            label="+ Add New Image"
            type="primary"
            className="px-12 text-sm w-12"
            isDisable={false}
            onClick={() => setShowForm(true)}
          />
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-4 mt-10">
          {list?.data?.map((e) => (
            <div
              className="relative hover:shadow-xl cursor-pointer transition-all duration-200"
              key={e?.id}
            >
              {e && e.status === "Main" && (
                <Image
                  src={IconStar}
                  className="absolute top-0 left-0"
                  alt="icon star"
                />
              )}
              <Image
                onClick={() => setModalImage(e)}
                src={`${BASE_URL_PREVIEW_IMG}/${e.path}/${e.name}`}
                alt={`${BASE_URL_PREVIEW_IMG}/${e.path}/${e.name}`}
                className="rounded-[10px] min-w-full h-[178px] object-cover"
              />
              <Popover className="my-auto">
                <Popover.Button className="absolute top-0 right-0">
                  <Image src={IconThreeDots} alt="icon three dots" />
                </Popover.Button>
                <Popover.Panel className="absolute right-4 top-10 z-10 mt-2">
                  <div className="grid grid-cols-1 min-w-[180px] rounded-lg bg-white border-[1px] border-gray-300 shadow">
                    <p
                      onClick={() =>
                        setModalAction({
                          label: "Are you sure want to set main this image?",
                          title: "Set Main Image",
                          click: () => onSetMain(e.id),
                        })
                      }
                      className="cursor-pointer px-4 py-3 hover:bg-primary-50 transition-all duration-100 rounded-t-lg"
                    >
                      Set Thumbnail
                    </p>
                    <p
                      onClick={() =>
                        setModalAction({
                          label: "Are you sure want to remove this image?",
                          title: "Remove Image",
                          click: () => onDeleteImage(e.id),
                        })
                      }
                      className="cursor-pointer px-4 py-3 hover:bg-primary-50 transition-all duration-100 rounded-lg"
                    >
                      Delete Image
                    </p>
                  </div>
                </Popover.Panel>
              </Popover>
            </div>
          ))}
        </div>
        {(!list ||
          (list && list?.data.length === 0) ||
          (list && list?.data.length === null)) && (
          <div className="w-full flex flex-col justify-center items-center text-center mt-28">
            <Image src={BgNoData} height={175} width={175} alt="No Data" />
            <p className="italic text-xl text-gray-600">Images not found</p>
          </div>
        )}
        {/* <p className="cursor-pointer flex justify-center gap-5 text-[#A33E4F] text-lg font-semibold mt-10 tracking-[0.3px] hover:opacity-[0.5] transition-all duration-200">
          Show more result
          <Image src={IconArrowRed} alt="icon arrow" width={30} height={24} />
        </p> */}
      </div>
    </React.Fragment>
  );
};

export default Gallery;
