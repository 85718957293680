import React from "react";
import InfiniteScrollM from "react-infinite-scroll-component";
import Image from "../Image";
import { IconArrowRed } from "../../assets/images";

const InfiniteScroll = ({
  children,
  dataLength = 0,
  next,
  hasMore,
  className = "",
}) => {
  return (
    <React.Fragment>
      <InfiniteScrollM
        dataLength={dataLength}
        next={next}
        hasMore={hasMore}
        className={className}
        loader={
          <p className="cursor-pointer flex justify-center gap-5 text-[#A33E4F] text-lg font-semibold mt-10 tracking-[0.3px] hover:opacity-[0.5] transition-all duration-200">
            Show more result
            <Image src={IconArrowRed} alt="icon arrow" width={30} height={24} />
          </p>
        }
        endMessage={
          dataLength ? (
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          ) : null
        }
      >
        {children}
      </InfiniteScrollM>
    </React.Fragment>
  );
};

export default InfiniteScroll;
