import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../components/Breadcrumb";
import { useCourt } from "../../hooks/useCourt";
import { useMyBooking } from "../../hooks/useMyBooking";
import { useMyVenue } from "../../hooks/useMyVenue";
import { setBookingsAllHours } from "../../stores/actions/mybooking.action";
import AddBookingForm from "./Form/AddBookingForm";
import AddNewCustomer from "./Form/AddNewCustomer";
import ChangeCourt from "./Form/ChangeCourt";
import SearchCustomer from "./Form/SearchCustomer";

const AddBooking = () => {
  /* Local State */
  const [showChangeCourt, setShowChangeCourt] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [court, setCourt] = useState();
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(moment().add(6, "days")));
  const [choosenDate, setChoosenDate] = useState(new Date());

  /* Redux */
  const { detail, allcourts } = useSelector((state) => state.court);
  const { detail: venueDetail } = useSelector((state) => state.myvenue);
  const { bookingsallhours } = useSelector((state) => state.mybook);
  const dispatch = useDispatch();

  /* Hooks */
  const { fetchAllCourts, detailCourt } = useCourt();
  const { myVenueDetail } = useMyVenue();
  const { fetchBookingAllHours, loading } = useMyBooking();

  useEffect(() => {
    dispatch(setBookingsAllHours());
    if (search?.id && choosenDate) {
      const customerId = search?.id;
      fetchBookingAllHours({
        date: moment(new Date(choosenDate)).format("yyyy-MM-DD"),
        customerId,
      });
    }
  }, [court, choosenDate, search]);

  useEffect(() => {
    fetchAllCourts();
    if (!venueDetail) myVenueDetail();
  }, []);

  useEffect(() => {
    if (allcourts?.length) {
      setCourt(allcourts[0]);
      detailCourt(allcourts[0]?.value);
    }
  }, [allcourts]);

  const onChangeCourt = async () => {
    await detailCourt(court?.value);
    setShowChangeCourt(false);
  };

  useEffect(() => {
    setChoosenDate(startDate);
  }, [startDate]);
  return (
    <React.Fragment>
      <ChangeCourt
        setShowChangeCourt={setShowChangeCourt}
        showChangeCourt={showChangeCourt}
        court={court}
        setCourt={setCourt}
        onChangeCourt={onChangeCourt}
      />
      <AddNewCustomer
        setShowAddCustomer={setShowAddCustomer}
        showAddCustomer={showAddCustomer}
      />
      <div className="w-full pb-12">
        <div className="md:block hidden">
          <Breadcrumb
            tabs={[
              { name: "Booking Management", route: "/booking-management" },
              { name: "Add Booking", route: "/booking-management/add-booking" },
            ]}
          />
        </div>
        <div className="py-7 md:px-11">
          <SearchCustomer
            search={search}
            setSearch={setSearch}
            setShowAddCustomer={setShowAddCustomer}
          />
          <AddBookingForm
            allCourts={allcourts}
            useActionButtons
            search={search}
            canChooseItem
            choosenDate={choosenDate}
            setChoosenDate={setChoosenDate}
            detailCourt={detail}
            venueDetail={venueDetail}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            dataAllHour={bookingsallhours}
            isLoading={loading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddBooking;
