import {
  SET_BANK,
  SET_DETAIL_MY_VENUE,
  SET_MEMBERS,
  SET_MEMBER_HISTORY,
  SET_SERVICE_FEE,
} from "../../assets/constants/reducer.type";

export const setDetailMyVenue = (payload) => {
  return { type: SET_DETAIL_MY_VENUE, payload };
};

export const setServiceFee = (payload) => {
  return { type: SET_SERVICE_FEE, payload };
};

export const setBank = (payload) => {
  return { type: SET_BANK, payload };
};

export const setMembers = (payload) => {
  return { type: SET_MEMBERS, payload };
};

export const setMemberHistory = (payload) => {
  return { type: SET_MEMBER_HISTORY, payload };
};
