import React from "react";
import Skeleton from "../Skeleton";

const TableSkeleton = () => {
  return (
    <React.Fragment>
      <div className="flex justify-between">
        <Skeleton.Square className="lg:w-[250px] w-[50px] h-[40px]" />
        <div>
          <div className="flex justify-end gap-3">
            <Skeleton.Square className="lg:w-[200px] w-[50px] h-[40px]" />
            <Skeleton.Square className="lg:w-[200px] w-[50px] h-[40px]" />
          </div>
        </div>
      </div>
      <Skeleton.Square className="w-full h-[340px] mt-5" />
    </React.Fragment>
  );
};

export default TableSkeleton;
