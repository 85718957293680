import moment from "moment";
import "moment/locale/id";
import numeral from "numeral";
import React, { useMemo } from "react";
import { BASE_URL_PREVIEW_IMG } from "../../../assets/constants/constants";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import Image from "../../../components/Image";

const CardBookingClose = ({ item, key, onDetail }) => {
  const paymentDetail = useMemo(() => {
    if (item?.detailbooking?.booking?.paymentDetail) {
      return JSON.parse(item?.detailbooking?.booking?.paymentDetail);
    }
    return undefined;
  }, [item]);
  return (
    <React.Fragment key={key}>
      <Card className="mt-8 select-none pb-6">
        <Card.Header className="w-full">
          <div className="flex justify-between gap-3 w-full text-primary-default">
            <div className="block my-auto">
              <p className="text-regular my-auto font-semibold">
                #{item?.detailbooking?.booking?.invoiceCode}
              </p>
              <p className="text-regular my-auto font-medium text-black">
                {item?.detailbooking?.venueName}
              </p>
            </div>
            <p className="my-auto text-regular font-semibold border-[1px] border-primary-default rounded-[10px] px-4 py-3">
              {item?.statusBook}
            </p>
          </div>
        </Card.Header>
        <div className="flex justify-start gap-4 px-8">
          <Image
            src={`${BASE_URL_PREVIEW_IMG}/${item.pathName}/${item.imageName}`}
            alt="icon venue"
            height={167}
            width={276}
            className="rounded-md object-cover h-40 w-[213px]"
          />
          <div className="flex justify-stretch gap-4 w-[70%]">
            <div className="block text-regular font-normal text-dark-default">
              <p className="text-lg font-semibold text-primary-default">
                {item?.courtName}
              </p>
              <div className="grid grid-cols-3 w-full mt-2 text-regular gap-4">
                <div className="col-span-1">
                  <div className="block">
                    <p className="font-semibold">Customer Name</p>
                    <p className="truncate">
                      {item?.detailbooking?.customerName || "-"}
                    </p>
                  </div>
                  <div className="block">
                    <p className="font-semibold">Customer Email</p>
                    <p className="truncate">
                      {item.detailbooking?.booking?.customerEmail}
                    </p>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="block">
                    <p className="font-semibold">Booking Date</p>
                    <p className="truncate">
                      {moment(item.bookDate).format("DD MMMM YYYY")}
                    </p>
                  </div>
                  <div className="block">
                    <p className="font-semibold">Time Booking</p>
                    <p className="truncate">
                      {item.startTime} - {item.endTime}
                    </p>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="block">
                    <p className="font-semibold">Payment Method</p>
                    <p className="truncate">
                      {paymentDetail?.payment_channel || "-"}
                    </p>
                  </div>
                  <div className="block">
                    <p className="font-semibold">Payment Time</p>
                    <p>
                      {paymentDetail?.paid_at
                        ? moment(new Date(paymentDetail?.paid_at)).format(
                            "dddd, DD MMMM yyyy HH:mm"
                          )
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-end block my-auto text-regular w-[30%]">
            <p>Total Pesanan</p>
            <p className="text-primary-default text-xl font-bold">
              Rp {numeral(item.price).format("0,0")}
            </p>
          </div>
        </div>
        <div className="flex justify-end gap-4 mt-4 mr-6">
          <Button
            className="!w-fit px-16"
            size="sm"
            label="Detail"
            type="outline-primary"
            btnType="button"
            isDisable={false}
            onClick={() =>
              onDetail(
                {
                  ...item,
                  image: `${BASE_URL_PREVIEW_IMG}/${item.pathName}/${item.imageName}`,
                },
                "CLOSE"
              )
            }
          />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default CardBookingClose;
