import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGeneralSetting } from "../../../hooks/useGeneralSetting";
import { retrieveLocalStorageItem } from "../../../utils/localStorage";
import FormPIN from "./FormPIN/formpin";

const GeneralSetting = () => {
  const [modalPin, setModalPin] = useState(false);
  const { status_pin } = useSelector((state) => state.generalSetting);
  const { checkStatusPin } = useGeneralSetting();

  const navigate = useNavigate();

  const role = useMemo(() => {
    return JSON.parse(
      retrieveLocalStorageItem("info") || "{}"
    )?.role?.toLowerCase();
  }, [retrieveLocalStorageItem("info")]);

  useEffect(() => {
    if (role !== "admin") navigate("/", { replace: true });
  }, [role]);

  useEffect(() => {
    checkStatusPin();
  }, []);
  return (
    <React.Fragment>
      <FormPIN
        showForm={modalPin}
        statusPin={status_pin}
        onToggleModal={() => setModalPin(!modalPin)}
      />
      <div className="pt-7 pb-6 px-11">
        <div className="flex justify-between">
          <p className="font-semibold text-2xl text-left">
            Setting General
            <span className="text-medium text-gray-500 font-normal block mt-1">
              Some information setting general.
            </span>
          </p>
        </div>
      </div>
      <div className="p-10">
        <div className="flex flex-col">
          <div className="flex bg-[#004a2f] p-4 mb-4">
            <p className="text-white">PIN</p>
          </div>
          <div className="flex justify-between">
            <div className="w-[30%] p-10">
              <p>PIN</p>
              <p>
                Buat PIN untuk menjaga keamanan Anda dalam melakukan proses
                disbursement
              </p>
            </div>
            <div className="w-[70%] p-10">
              <div className="flex flex-col">
                <div>
                  <p className="mb-8 text-xl">* * * * * *</p>
                  <div
                    onClick={() => setModalPin(true)}
                    className="underline cursor-pointer"
                  >
                    Ganti PIN
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GeneralSetting;
