import React from "react";

const VenueFormTabs = ({ setTab, tab }) => {
  return (
    <React.Fragment>
      <div className="w-full mt-5 text-center border-b border-gray-200 shadow-lg">
        <ul className="flex justify-between">
          <li
            className="w-full cursor-pointer"
            onClick={() => setTab("general")}
          >
            <p
              className={`p-4 pb-3 text-[14px] inline-block rounded-t-lg form-tabs${
                tab === "general" && "-active"
              } w-full`}
            >
              General
            </p>
            {tab === "general" && (
              <div className="w-full h-[4.5px] shadow rounded bg-primary-default transition-all duration-200" />
            )}
          </li>
          <li
            className="w-full cursor-pointer"
            onClick={() => setTab("contact")}
          >
            <p
              className={`p-4 pb-3 text-[14px] inline-block rounded-t-lg form-tabs${
                tab === "contact" && "-active"
              } w-full`}
            >
              Contact & Social
            </p>
            {tab === "contact" && (
              <div className="w-full h-[4.5px] shadow rounded bg-primary-default transition-all duration-200" />
            )}
          </li>
          <li
            className="w-full cursor-pointer"
            onClick={() => setTab("others")}
          >
            <p
              className={`p-4 pb-3 text-[14px] inline-block rounded-t-lg form-tabs${
                tab === "others" && "-active"
              } w-full`}
            >
              Others
            </p>
            {tab === "others" && (
              <div className="w-full h-[4.5px] shadow rounded bg-primary-default transition-all duration-200" />
            )}
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default VenueFormTabs;
