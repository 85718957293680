import React from "react";
import InputText from "../../../components/Input/Text";

const Info = ({ register, errors }) => {
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-8 pb-8">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Venue’s Label</p>
          <p className="font-normal text-[12px]">
            This will be the url of the venue
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <p className="text-primary-default text-medium my-auto">
            www.sportirena.com/venue/
          </p>
          <div className="w-full">
            <InputText
              name="linkUrl"
              required
              type="text"
              placeholder="Insert venue label"
              register={register}
              errorMessage={errors?.linkUrl?.message?.toString()}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Venue’s Name</p>
          <p className="font-normal text-[12px]">
            This venue’s name will be displayed in users page
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="venueName"
              required
              type="text"
              placeholder="Insert venue name"
              register={register}
              errorMessage={errors?.linkUrl?.message?.toString()}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Info;
