import api from "./api";

export const putCourt = async (id, body) => {
  return api
    .put({
      body,
      port: process.env.REACT_APP_PORT_COURT,
      endpoint: `/own/court/${id}`,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
