import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { toastNotif } from "../utils/toastNotif";
import { getMyConfiguration } from "../core/configurations/GET_Config";
import { createOrUpdateConfig } from "../core/configurations/POST_CreateOrUpdateConfig";
import {
    setConfigVenueList
  } from "../stores/actions/configvenue.action";

export const useConfigVenue = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();

    const fetchConfiguration = async () => {
        setLoading(true);
        try {
          const { success, message, result } = await getMyConfiguration();
    
          if (success && result) {
            dispatch(setConfigVenueList(result));
          } else {
            dispatch(setConfigVenueList());
            setMessage("Data not found");
            toastNotif({ message: message || "Data not found", type: "error" });
          }
          return setLoading(false);
        } catch (err) {
          setMessage("Internal server error!");
          toastNotif({
            message: err.message || "Internal server error!",
            type: "error",
          });
          return setLoading(false);
      }
    };

    const createOrUpdateConfigVenue = async (payload) => {
      setLoading(true);
        try {
          const { success, message } = await createOrUpdateConfig(payload);
    
          if (success) {
            await fetchConfiguration();
            toastNotif({ message: "Success change config", type: "info" });
          } else {
            setMessage("Data not found");
            toastNotif({ message: message || "Data not found", type: "error" });
          }
          return setLoading(false);
        } catch (err) {
          setMessage("Internal server error!");
          toastNotif({
            message: err.message || "Internal server error!",
            type: "error",
          });
          return setLoading(false);
      }
    }

    return {
        error,
        message,
        loading,
        fetchConfiguration,
        createOrUpdateConfigVenue
      };

}