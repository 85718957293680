import React, { useMemo } from "react";
import { LoadingWhite } from "../../assets/images";
import Image from "../Image";

const Button = ({
  label,
  rightIcon,
  icon,
  className,
  onClick,
  isDisable = true,
  btnType = "button",
  type,
  isSubmit,
  size = "md", // "md" | "sm" | "lg" | "xl"
  iconHeight = 15,
  iconWidth = 15,
  buttonRef,
}) => {
  const btnColor = useMemo(() => {
    return type === "primary"
      ? "bg-primary-default text-white font-bold hover:bg-primary-lighter"
      : type === "outline-primary"
      ? "bg-white text-primary-default font-bold border border-primary-default hover:bg-green-700 hover:text-white hover:border-green-700 hover:shadow"
      : type === "danger"
      ? "text-white bg-red-700 font-bold hover:bg-red-800 focus:ring-red-300 rounded-lg text-center items-center"
      : type === "success"
      ? "text-white bg-green-700 font-bold hover:bg-green-800 focus:ring-green-300 rounded-lg px-5 py-2.5 text-center inline-flex items-center"
      : type === "info"
      ? "text-white bg-cyan-700 font-bold hover:bg-cyan-800 focus:ring-cyan-300 rounded-lg px-5 py-2.5 text-center inline-flex items-center"
      : type === "secondary"
      ? "text-white bg-stone-700 font-bold hover:bg-stone-800 focus:ring-stone-300 rounded-lg px-5 py-2.5 text-center inline-flex items-center"
      : type === "warning"
      ? "text-white bg-amber-700 font-bold hover:bg-amber-800 focus:ring-amber-300 rounded-lg px-5 py-2.5 text-center inline-flex items-center"
      : type === "disabled"
      ? "bg-gray-300/[0.9] border-gray-400/[0.4]"
      : "bg-transparent font-bold hover:opacity-[0.5] text-center inline-flex items-center";
  }, [type, isDisable]); // eslint-disable-line

  const handleOnClick = (e) => {
    if (btnType === "button" && onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <React.Fragment>
      <button
        ref={buttonRef}
        type={btnType}
        onClick={handleOnClick}
        disabled={isDisable || isSubmit}
        className={`${
          isDisable || isSubmit
            ? "bg-primary-200 text-gray-200 font-bold hover:cursor-not-allowed"
            : btnColor
        } ${className} ${
          size === "md"
            ? "h-[51px]"
            : size === "sm"
            ? "h-[41px]"
            : size === "lg"
            ? "h-[61px]"
            : "h-[71px]"
        } w-full rounded-[8px] text-[14px] transition-all duration-200`}
      >
        {isSubmit && (
          <span className="-ml-8 mr-2 absolute">
            <Image
              src={LoadingWhite}
              alt="loading"
              height={25}
              width={25}
              className="mr-2"
            />
          </span>
        )}
        {icon ? (
          rightIcon ? (
            <div className="flex justify-between">
              <span className="my-auto group-hover:opacity-[0.60]">
                {label}
              </span>
              <span>
                <Image
                  className={"group-hover:opacity-[0.50] ml-3"}
                  src={icon}
                  alt={`${label}-loading`}
                  height={iconHeight}
                  width={iconWidth}
                />
              </span>
            </div>
          ) : (
            <div className="flex justify-between">
              <span>
                <Image
                  className={"group-hover:opacity-[0.50]"}
                  src={icon}
                  alt={`${label}-loading`}
                  height={iconHeight}
                  width={iconWidth}
                />
              </span>
              <span className="my-auto group-hover:opacity-[0.60] ml-3">
                {label}
              </span>
            </div>
          )
        ) : (
          label
        )}
      </button>
    </React.Fragment>
  );
};

export default Button;
