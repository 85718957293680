import React from "react";
import { IconArrowGray, IconSearch } from "../../../assets/images";
import "./Search.style.css";

const InputSearch = ({
  value,
  setValue,
  labelClassName = "",
  className = "",
  placeholder = "",
  disabled = false,
  label = "",
  results = [1, 2, 3, 4],
  iconLeftClassName = "",
  iconRightClassName = "",
}) => {
  return (
    <React.Fragment>
      <div className={labelClassName}>
        {label && (
          <div className="mb-[4px]">
            <label className={`${labelClassName} form-label`}>{label}</label>
          </div>
        )}
        <span className="block relative group">
          <span className={`${iconLeftClassName} form-search-input-left-icon`}>
            <img alt="search input" src={IconSearch} />
          </span>
          <input
            type="text"
            value={value?.name || ""}
            onChange={({ target }) => setValue({ name: target.value })}
            placeholder={placeholder}
            disabled={disabled}
            className={`${
              disabled ? "cursor-not-allowed" : ""
            } ${className} form-search-input`}
          />
          <span
            className={`${iconRightClassName} form-search-input-right-icon`}
          >
            <img alt="search input" src={IconArrowGray} />
          </span>
          {results?.length ? (
            <div className="card-search-result z-50">
              {results?.map((e, i) => (
                <>
                  <div
                    onClick={() => setValue(e)}
                    className={`block list-search-result ${
                      i === 0 && "rounded-t-lg"
                    } ${i === results.length - 1 && "rounded-b-lg"}`}
                    key={i}
                  >
                    <span className="block">{e?.name || "-"}</span>
                    <span className="block">
                      {e?.email || "-"} | {e?.phoneNumber || "-"}
                    </span>
                  </div>
                  {i !== results.length - 1 && <hr />}
                </>
              ))}
            </div>
          ) : (
            <div className="card-search-result italic text-gray-500 text-center py-12 z-50">
              -- Data not found --
            </div>
          )}
        </span>
      </div>
    </React.Fragment>
  );
};

export default InputSearch;
