import {
  SET_BOOKINGS,
  SET_BOOKINGS_ALL_HOURS,
  SET_CART_BOOKINGS,
  SET_CUSTOMERS,
  SET_DASHBOARD_CALENDAR,
  SET_DETAIL_BOOKING,
  SET_LIST_BOOKING,
  SET_LIST_CART,
  SET_QUERY_BOOKING,
  SET_ROWS_BOOKING,
  SET_TTL_CART,
} from "../../assets/constants/reducer.type";

const initialState = {
  list: [],
  query: {
    page: 1,
    pageSize: 10,
    date: "",
  },
  rows: 0,
  detail: null,
  customers: null,
  bookings: null,
  bookingsallhours: null,
  cartbookings: null,
  ttlcart: 0,
  listcart: [],
  dashboardCalendar: null,
};

const myBookingReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_LIST_BOOKING:
      return { ...state, list: payload };
    case SET_ROWS_BOOKING:
      return { ...state, rows: payload };
    case SET_DETAIL_BOOKING:
      return { ...state, detail: payload };
    case SET_QUERY_BOOKING:
      return { ...state, query: payload };
    case SET_CUSTOMERS:
      return { ...state, customers: payload };
    case SET_BOOKINGS:
      return { ...state, bookings: payload };
    case SET_BOOKINGS_ALL_HOURS:
      return { ...state, bookingsallhours: payload };
    case SET_CART_BOOKINGS:
      return { ...state, cartbookings: payload };
    case SET_TTL_CART:
      return { ...state, ttlcart: payload };
    case SET_LIST_CART:
      return { ...state, listcart: payload };
    case SET_DASHBOARD_CALENDAR:
      return { ...state, dashboardCalendar: payload };
    default:
      return state;
  }
};

export default myBookingReducer;
