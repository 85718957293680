import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../../components/Button";
import { useCommon } from "../../hooks/useCommon";
import { useMyVenue } from "../../hooks/useMyVenue";
import VenueContact from "./Contact";
import VenueGeneral from "./General";
import VenueOthers from "./Others";
import VenueFormTabs from "./Tabs";

const MyVenue = () => {
  /* Local State */
  const [tabForm, setTabForm] = useState("general");

  /* Hooks */
  const { myVenueDetail, updateMyVenue } = useMyVenue();
  const {
    fetchDistricts,
    fetchProvinces,
    fetchRegencies,
    fetchVillages,
    fetchFacilities,
  } = useCommon();

  /* Redux */
  const { detail } = useSelector((state) => state.myvenue);

  const validationSchema = Yup.object().shape({
    status: Yup.boolean().default(true),
    linkUrl: Yup.string().required("Venue label required"),
    venueName: Yup.string().required("Venue name required"),
    address: Yup.string().required("Address required"),
    province: Yup.object().nullable().required("Province required"),
    regency: Yup.object().nullable().required("Regency required"),
    district: Yup.object().nullable().required("District required"),
    village: Yup.object().nullable().required("Village required"),
    longitude: Yup.number().required("Longitude required"),
    latitude: Yup.number().required("Latitude required"),
    facility: Yup.array()
      .of(Yup.object().nullable().required("Facility name required"))
      .required("Facility required"),
    openTime: Yup.date().required("Open time required"),
    closeTime: Yup.date().required("Close time required"),
    description: Yup.string().required("Description required"),
    email: Yup.string()
      .email("Wrong format contact email")
      .required("Contact email required"),
    telephone: Yup.string().required("Contact phone required"),
    contactWa: Yup.string().required("Whatsapp phone required"),
    linkFb: Yup.string().required("Facebook link required"),
    linkIg: Yup.string().required("Instagram link required"),
    linkTwitter: Yup.string().required("Twitter link required"),
    privacyPolicy: Yup.string().required("Privacy policy required"),
  });

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    myVenueDetail();
    fetchProvinces();
    fetchFacilities();
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "province") {
        const province = value?.province;
        setValue("regency", null);
        setValue("district", null);
        setValue("village", null);
        if (province) {
          fetchRegencies({ provinceId: province.value });
        }
      }
      if (name === "regency") {
        const regency = value?.regency;
        setValue("district", null);
        setValue("village", null);
        if (regency) {
          fetchDistricts({ regencyId: regency.value });
        }
      }
      if (name === "district") {
        const district = value?.district;
        setValue("village", null);
        if (district) {
          fetchVillages({ districtId: district.value });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setData();
  }, [detail]);

  const setData = async () => {
    reset();
    if (detail) {
      await fetchRegencies({ provinceId: detail.provinceId });
      await fetchDistricts({ regencyId: detail.regencyId });
      await fetchVillages({ districtId: detail.villageId });
      setValue("status", detail.status === "ACTIVE");
      setValue("linkUrl", detail.linkUrl);
      setValue("venueName", detail.venueName);
      setValue("address", detail.address);
      setValue("longitude", +detail.longitude);
      setValue("latitude", +detail.latitude);
      setValue(
        "openTime",
        new Date(`12 Jan 2012, ${detail.openTime.replace(".", ":")}`)
      );
      setValue(
        "closeTime",
        new Date(`12 Jan 2012, ${detail.closeTime.replace(".", ":")}`)
      );
      setValue("description", detail.description);
      setValue("email", detail.email);
      setValue("telephone", detail.telephone);
      setValue("contactWa", detail.contactWa);
      setValue("linkFb", detail.linkFb);
      setValue("linkIg", detail.linkIg);
      setValue("linkTwitter", detail.linkTwitter);
      setValue(
        "facility",
        detail.facilities.map((e) => ({
          ...e,
          label: e.facilityTypeName,
          value: e.facilityTypeId,
        }))
      );
      setValue("province", {
        value: detail.provinceId,
        label: detail.provinceName,
      });
      setValue("regency", {
        value: detail.regencyId,
        label: detail.regencyName,
      });
      setValue("district", {
        value: detail.districtId,
        label: detail.districtName,
      });
      setValue("village", {
        value: detail.villageId,
        label: detail.villageName,
      });
      setValue("privacyPolicy", detail.privacyPolicy);
    }
  };

  const onSubmit = async () => {
    const payload = getValues();
    await updateMyVenue({
      ...payload,
      status: payload.status ? "ACTIVE" : "INACTIVE",
      openTime: moment(new Date(payload.openTime)).format("HH.mm"),
      closeTime: moment(new Date(payload.closeTime)).format("HH.mm"),
      longitude: "" + payload.longitude,
      latitude: "" + payload.latitude,
      provinceId: payload?.province?.value,
      provinceName: payload?.province?.label,
      regencyId: payload?.regency?.value,
      regencyName: payload?.regency?.label,
      districtId: payload?.district?.value,
      districtName: payload?.district?.label,
      villageId: payload?.village?.value,
      villageName: payload?.village?.label,
      facilities: payload.facility,
      privacyPolicy: payload.privacyPolicy,
    });
  };
  return (
    <React.Fragment>
      <form className="pt-7 pb-36 px-11">
        <div className="flex justify-between">
          <p className="font-semibold text-2xl text-left">
            My Venue
            <span className="text-medium text-gray-500 font-normal block mt-1">
              Some information will be displayed publicly, so be careful what
              you share.
            </span>
          </p>
          {isDirty && (
            <div className="flex justify-end gap-4 w-84">
              <Button
                className="tracking-wider font-[14px]"
                label="Discard Changes"
                type="outline-primary"
                btnType="button"
                isDisable={false}
                onClick={setData}
              />
              <Button
                className="tracking-wider font-[14px]"
                label="Save Changes"
                type="primary"
                btnType="button"
                isDisable={false}
                onClick={onSubmit}
                isSubmit={isSubmitting && isValid}
              />
            </div>
          )}
        </div>
        <VenueFormTabs setTab={setTabForm} tab={tabForm} />
        <div className="text-gray-500">
          {tabForm === "general" ? (
            <VenueGeneral
              control={control}
              errors={errors}
              register={register}
              watch={watch}
            />
          ) : tabForm === "contact" ? (
            <VenueContact errors={errors} register={register} />
          ) : tabForm === "others" ? (
            <VenueOthers
              errors={errors}
              value={watch("privacyPolicy")}
              setValue={(value) =>
                setValue("privacyPolicy", value, {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                })
              }
            />
          ) : null}
        </div>
      </form>
    </React.Fragment>
  );
};

export default MyVenue;
