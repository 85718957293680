import {
  SET_BANK,
  SET_DETAIL_MY_VENUE,
  SET_MEMBERS,
  SET_MEMBER_HISTORY,
  SET_SERVICE_FEE,
} from "../../assets/constants/reducer.type";

const initialState = {
  detail: null,
  servicefee: null,
  bank: null,
  members: null,
  memberHistory: null,
};

const myVenueReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_DETAIL_MY_VENUE:
      return { ...state, detail: payload };
    case SET_SERVICE_FEE:
      return { ...state, servicefee: payload };
    case SET_BANK:
      return { ...state, bank: payload };
    case SET_MEMBERS:
      return { ...state, members: payload };
    case SET_MEMBER_HISTORY:
      return { ...state, memberHistory: payload };
    default:
      return state;
  }
};

export default myVenueReducer;
