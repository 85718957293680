import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../components/Button";
import InputText from "../../components/Input/Text";
import InputTextarea from "../../components/Input/Textarea";
import Toggle from "../../components/Input/Toggle";

const MembershipConfig = () => {
  const validationSchema = Yup.object().shape({
    status: Yup.boolean().default(true),
    benefit: Yup.string().required("Benefit required"),
    period: Yup.string().required("Period required"),
    memberBookingDate: Yup.string().required("Member booking date required"),
    nonMemberBookingDate: Yup.string().required(
      "Non Member booking date required"
    ),
  });

  const {
    register,
    control,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  return (
    <form className="pt-7 pb-36 px-11">
      <p className="font-semibold text-2xl text-left">
        Keanggotaan {`>`} Configuration
        <span className="text-medium text-gray-500 font-normal block mt-1">
          Kelola konfigurasi terkait keuntungan dari keanggotaan yang Anda
          miliki
        </span>
      </p>
      <div className="flex justify-between gap-8 mt-12 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%]">
          <p className="font-bold text-lg text-gray-500">Status Keanggotaan</p>
        </div>
        <div className="w-[60%] my-auto flex">
          <Toggle className="my-auto" name="status" control={control} />
          <p className="text-primary-default text-medium">
            Keanggotaan
            {watch("status") ? " " : " tidak"} aktif
          </p>
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%]">
          <p className="font-bold text-lg text-gray-500">Keuntungan</p>
          <p className="font-normal text-[12px]">
            Tuliskan keuntungan yang didapat oleh anggota Anda
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputTextarea
              name="benefit"
              required
              placeholder="Insert benefit/rules"
              register={register}
              errorMessage={errors?.benefit?.message?.toString()}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%]">
          <p className="font-bold text-lg text-gray-500">
            Periode Masa Aktif Keanggotaan
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="period"
              type="number"
              required
              placeholder="Input amount of days of period"
              register={register}
              errorMessage={errors?.period?.message?.toString()}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8 pb-8">
        <div className="w-[40%]">
          <p className="font-bold text-lg text-gray-500">
            Persyaratan Pemesanan Lapangan
          </p>
          <p className="font-normal text-[12px]">
            Tentukan jumlah minimal hari yang dapat dipesan oleh anggota dan
            non-anggota
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full flex justify-start gap-4">
            <div className="block w-full">
              <InputText
                label="Anggota"
                name="memberBookingDate"
                type="number"
                placeholder="Input minimum days before month ends"
                register={register}
                errorMessage={errors?.memberBookingDate?.message?.toString()}
              />
            </div>
            <div className="block w-full">
              <InputText
                label="Non-Anggota"
                name="nonMemberBookingDate"
                type="number"
                placeholder="Input minimum days before month ends"
                register={register}
                errorMessage={errors?.nonMemberBookingDate?.message?.toString()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-4">
        <div className="w-fit">
          <Button
            className="mt-8 tracking-wider font-[14px] px-12"
            size="sm"
            label="Simpan"
            type="primary"
            btnType="submit"
            isDisable={!isValid || isSubmitting}
            isSubmit={isSubmitting && isValid}
          />
        </div>
        <div className="w-fit">
          <Button
            className="mt-8 tracking-wider font-[14px] px-12"
            size="sm"
            label="Keluar"
            type="outline-primary"
            btnType="button"
            isDisable={false}
          />
        </div>
      </div>
    </form>
  );
};

export default MembershipConfig;
