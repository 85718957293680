import React from "react";
import { IconClock } from "../../../assets/images";
import FormDate from "../../../components/Input/Date";

const OpenCloseTime = ({ errors, control }) => {
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Open - Close Time</p>
          <p className="font-normal text-[12px]">
            Please provide when your venue will be open and closed
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <FormDate
              name="openTime"
              placeholder="Insert open time"
              icon={IconClock}
              iconPosition="right"
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              timeCaption="Time"
              control={control}
              format="h:mm aa"
              errorMessage={errors?.openTime?.message?.toString()}
            />
            <div className="mt-4">
              <FormDate
                name="closeTime"
                placeholder="Insert open time"
                icon={IconClock}
                iconPosition="right"
                showTimeSelect
                showTimeSelectOnly
                errorMessage={errors?.closeTime?.message?.toString()}
                timeIntervals={30}
                timeCaption="Time"
                control={control}
                format="h:mm aa"
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OpenCloseTime;
