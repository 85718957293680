import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconCart, IconCartHand } from "../../../assets/images";
import Button from "../../../components/Button";
import Card from "../../../components/Card";
import DateRange from "../../../components/Input/Date/DateRange";
import Skeleton from "../../../components/Skeleton";
import { useMyBooking } from "../../../hooks/useMyBooking";
import { setCutomers } from "../../../stores/actions/mybooking.action";
import { convertNumToArr, formatDateArr } from "../../../utils/formatters";
import { toastNotif } from "../../../utils/toastNotif";
import CardBookingHour from "./CardBookingHour";

moment.locale("en");

const AddBookingForm = ({
  search,
  detailCourt,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setChoosenDate,
  choosenDate,
  dataAllHour = undefined,
  canChooseItem = true,
  isLoading,
  useActionButtons,
  venueDetail,
  allCourts,
}) => {
  /* Redux */
  const dispatch = useDispatch();

  /* Router */
  const navigate = useNavigate();

  /* Hooks */
  const { searchCustomer, addToCart } = useMyBooking();

  const maxDate = useMemo(() => {
    return new Date(moment().add(3000, "years"));
  }, [new Date()]);

  useEffect(() => {
    if (search?.name?.length >= 3) {
      searchCustomer(search?.name);
    }
    if (search?.name?.length < 3) {
      dispatch(setCutomers(null));
    }
  }, [search]);

  const onChangeStatus = async (data) => {
    try {
      onAddToCart(data);
    } catch (error) {
      toastNotif({ message: "Process failed", type: "warning" });
    }
  };

  const onAddToCart = (payload) => {
    addToCart(
      { ...payload, customerId: search?.id },
      {
        date: moment(new Date(choosenDate)).format("yyyy-MM-DD"),
        customerId: search?.id,
      },
      {
        courtId: detailCourt?.id,
        customerId: search?.id,
        courtName: detailCourt?.label,
        date: moment(new Date(startDate)).format("yyyy-MM-DD"),
      },
      true
    );
  };

  const cartBooking = useMemo(() => {
    const result = [];
    dataAllHour?.forEach((e) => {
      e?.times?.forEach((el) => {
        if (el?.statusBook?.toLowerCase() === "cart") {
          result.push(el);
        }
      });
    });
    return result;
  }, [dataAllHour]);

  return (
    <div className="relative">
      <DateRange
        totalDiffDays={6}
        minDate={new Date()}
        maxDate={maxDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        startDate={startDate}
        className="mt-9 lg:!w-[50%] md:!w-10/12 !w-full md:rounded-lg rounded-none md:border-x-1.5 border-x-0 !mx-auto"
      />
      <Card className="!shadow-none lg:border-[1px] border-gray-200 mt-4 py-4 !pt-0 lg:px-4 px-2 lg:flex justify-center">
        <div className="block lg:w-[85%] w-full overflow-x-auto">
          <div className="flex lg:justify-center gap-4 w-full">
            {formatDateArr(startDate, endDate, 7)?.map((e, i) =>
              e ? (
                <Card
                  onClick={() => setChoosenDate(e?.toString())}
                  key={i}
                  className={`${
                    moment(new Date(e)).format("DD-MM-yyyy") ===
                      moment(new Date(choosenDate)).format("DD-MM-yyyy") &&
                    "!bg-primary-default !text-white"
                  } !shadow-none border-[1px] border-primary-default !p-4 transition-colors duration-200 mt-6 my-auto hover:bg-primary-100 w-full`}
                >
                  <div className="my-auto text-center">
                    <p
                      className={`${
                        moment(new Date(e)).format("DD-MM-yyyy") ===
                        moment(new Date(choosenDate)).format("DD-MM-yyyy")
                          ? "text-white"
                          : "text-gray-500"
                      } text-center text-medium font-semibold transition-colors duration-200`}
                    >
                      {moment(new Date(e)).format("ddd")}
                    </p>
                    <p className="text-center text-xl font-medium text-nowrap">
                      {moment(new Date(e)).format("D MMM")}
                    </p>
                  </div>
                </Card>
              ) : (
                <Card
                  key={i}
                  className="col-span-1 !shadow-none border-[1px] bg-gray-300 py-1 transition-colors duration-200 mt-6 w-full h-[66px]"
                />
              )
            )}
          </div>
        </div>
      </Card>
      <div className="mt-4 py-4 lg:px-4 px-0 mx-auto min-w-screen overflow-x-auto overflow-y-hidden">
        <div className="flex justify-start gap-1">
          <p className="min-w-[80px]" />
          <div className="block w-full">
            <div className="mt-6 mb-4 flex mx-auto justify-between">
              {allCourts?.map((e, i) => (
                <p
                  className="text-center text-primary-default lg:text-medium text-regular font-bold md:min-w-[203px] min-w-[120px] w-full"
                  key={i}
                >
                  {e?.label}
                </p>
              ))}
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="flex flex-col gap-2">
            {convertNumToArr(5).map((e) => (
              <Skeleton.Square className="h-44 w-full" key={e} />
            ))}
          </div>
        ) : !dataAllHour?.length ? (
          <p className="text-center italic text-gray-400 text-small mt-20">
            --{" "}
            {canChooseItem && !search?.id
              ? "Select customer first please"
              : "Data not found"}{" "}
            --
          </p>
        ) : (
          dataAllHour?.map((e, i) => (
            <div key={i} className="flex justify-start gap-1">
              <p className="min-w-[80px] text-medium my-auto text-center text-gray-400">
                <p className="block w-full md:text-regular text-small">
                  {e?.startTime}
                </p>
                <p className="block w-full md:text-regular text-small">s/d</p>
                <p className="block w-full md:text-regular text-small">
                  {e?.endTime}
                </p>
              </p>
              <div className="block w-full">
                <div className="flex mx-auto justify-between">
                  {e?.times?.map((e, j) => (
                    <div
                      className="border-t-[1px] border-r-[1px] p-1 border-gray-150 md:min-w-[203px] min-w-[120px] w-full"
                      key={j}
                    >
                      <CardBookingHour
                        type={e?.statusBook?.toLowerCase()}
                        price={e?.price}
                        booking={e?.booking}
                        data={e}
                        onClick={
                          canChooseItem ? () => onChangeStatus(e) : undefined
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {useActionButtons && cartBooking?.length ? (
        <div className="fixed bottom-0 w-full bg-white border-t-[1px] border-gray-300 left-0 lg:px-11 px-2 py-3">
          <div className="flex justify-end lg:gap-5 gap-2">
            <div className="w-[202px]">
              <Button
                className="px-12"
                icon={IconCart}
                iconHeight={22}
                iconWidth={22}
                rightIcon={false}
                label="Check Out"
                type="primary"
                size="sm"
                btnType="button"
                isDisable={false}
                onClick={() =>
                  navigate("/booking-management/checkout-booking", {
                    state: {
                      cartBooking,
                      venueDetail,
                      detailCourt,
                      customer: search,
                    },
                  })
                }
              />
            </div>
            <div className="w-[88px]">
              <Button
                icon={IconCartHand}
                iconHeight={24}
                iconWidth={24}
                size="sm"
                rightIcon={false}
                className="px-5"
                label={cartBooking?.length}
                type="primary"
                btnType="button"
                isDisable={!cartBooking?.length}
                onClick={() =>
                  navigate("/booking-management/checkout-booking", {
                    state: {
                      cartBooking,
                      venueDetail,
                      detailCourt,
                      customer: search,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AddBookingForm;
