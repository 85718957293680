import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { useCommon } from "../../hooks/useCommon";
import { useCourt } from "../../hooks/useCourt";
import BodyCourt from "./Body";
import FormCourt from "./Form";
import HeaderCourt from "./Header";

const CourtManagement = () => {
  /* Local State */
  const [grid, setGrid] = useState("list");
  const [facility, setFacility] = useState();
  const [courtName, setCourtName] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [courtDetail, setCourtDetail] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, _setPageSize] = useState(6);

  /* Hooks */
  const { fetchCourts } = useCourt();
  const { fetchFacilities } = useCommon();

  useEffect(() => {
    fetchFacilities();
  }, []);

  useEffect(() => {
    fetchCourts({
      page: 1,
      pageSize,
      searchName: courtName,
      facility: facility?.label || "",
    });
    setPage(1);
  }, [courtName, facility]);

  useEffect(() => {
    if (!showForm) setCourtDetail(undefined);
  }, [showForm]);

  const onGetDetail = (data) => {
    setCourtDetail(data);
    setShowForm(true);
  };

  const getCourts = () => {
    fetchCourts(
      {
        pageSize,
        searchName: courtName,
        facility: facility?.label || "",
        page: page + 1,
      },
      true
    );
    setPage(page + 1);
  };

  return (
    <React.Fragment>
      <FormCourt
        setShowForm={setShowForm}
        showForm={showForm}
        data={courtDetail}
      />
      <div className="py-7 px-11">
        <div className="grid lg:grid-cols-5 grid-cols-4 gap-3">
          <p className="font-semibold text-2xl text-left col-span-4">
            Courts Management
            <span className="text-medium text-gray-500 font-normal block mt-1">
              Manage all your existing courts or edit or add new courts
            </span>
          </p>
          <Button
            btnType="button"
            label="+ Add New Court"
            type="primary"
            className="px-12 text-sm w-12 col-span-4 lg:col-span-1"
            isDisable={false}
            onClick={() => setShowForm(true)}
          />
        </div>
        <HeaderCourt
          setGrid={setGrid}
          facility={facility}
          name={courtName}
          setFacility={setFacility}
          setName={setCourtName}
          grid={grid}
        />
        <BodyCourt
          grid={grid}
          onGetDetail={onGetDetail}
          moreCourts={getCourts}
        />
      </div>
    </React.Fragment>
  );
};

export default CourtManagement;
