import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";

const Exception = ({ showException, setShowException, isLoading, data }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Modal.Common
        showModal={showException || false}
        closeModal={() => setShowException(false)}
        title="Exception Date"
        description={`There are ${data?.length} exception  on the selected row`}
      >
        <div className="w-[420px] px-10 pb-10">
          {isLoading ? (
            <p className="text-center my-8">Loading ...</p>
          ) : data?.length ? (
            <p className="line-clamp-2 text-mediumSmall">
              {data
                ?.map((e) => moment(new Date(e?.date)).format("DD MMMM yyyy"))
                ?.toString()}
            </p>
          ) : (
            <p className="text-center italic text-gray-400 text-small">
              -- Data not found --
            </p>
          )}
          <p
            onClick={() =>
              navigate("/time-booking/exception", {
                state: {
                  court: data[0]?.courtId,
                  startEndTime: data[0]?.startEndTime,
                },
              })
            }
            className="text-end cursor-pointer text-[#1212DB] hover:text-blue-500 mt-12 text-mediumSmall"
          >
            Show more
          </p>
        </div>
      </Modal.Common>
    </React.Fragment>
  );
};

export default Exception;
