import React from "react";
import Image from "../Image";
import { useLocation, useNavigate } from "react-router-dom";

const Breadcrumb = ({ tabs = [] }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="flex justify-start gap-3 text-xl font-[500] text-white w-full bg-primary-default py-7 px-10">
        {tabs.map((e, i) => (
          <div className="flex" key={i}>
            <p
              onClick={() => e.route !== pathname && navigate(e.route)}
              className={`transition-all duration-200 my-auto ${
                e.route === pathname
                  ? "cursor-default"
                  : "cursor-pointer hover:text-gray-400 hover:shadow"
              }`}
            >
              {e.name}
            </p>
            {i < tabs.length - 1 && (
              <Image
                className="my-auto rotate-[270deg] ml-3"
                src="/assets/icon-arrow-white.svg"
                alt="icon arrow"
                height={15}
                width={15}
              />
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default Breadcrumb;
