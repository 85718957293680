import {
  SET_COURT_DETAIL,
  SET_COURT_LIST,
  SET_ALL_COURT_LIST,
} from "../../assets/constants/reducer.type";

export const setCourtList = (payload) => {
  return { type: SET_COURT_LIST, payload };
};

export const setCourtDetail = (payload) => {
  return { type: SET_COURT_DETAIL, payload };
};

export const setAllCourt = (payload) => {
  return { type: SET_ALL_COURT_LIST, payload };
};
