import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBank } from "../core/GET_Bank";
import { getMembers } from "../core/GET_Members";
import { getMemberHistory } from "../core/GET_MemberHistory";
import { getMyVenueDetail } from "../core/GET_MyVenueDetail";
import { getServiceFee } from "../core/GET_ServiceFee";
import { patchMyVenue } from "../core/PATCH_MyVenue";
import { postAddMember } from "../core/POST_AddMember";
import { putMembership } from "../core/PUT_Membership";
import {
  setBank,
  setDetailMyVenue,
  setMemberHistory,
  setMembers,
  setServiceFee,
} from "../stores/actions/myvenue.action";
import { toastNotif } from "../utils/toastNotif";

export const useMyVenue = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  /* get my venue detail */
  const myVenueDetail = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getMyVenueDetail();

      if (success && result) {
        dispatch(setDetailMyVenue(result));
        setLoading(false);
        return result;
      } else {
        dispatch(setDetailMyVenue());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
        setLoading(false);
        return null;
      }
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      setLoading(false);
      return null;
    }
  };

  const onGetServiceFee = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getServiceFee();

      if (success && result) {
        const { value } = result || {};
        dispatch(setServiceFee(parseInt(value)));
      } else {
        dispatch(setServiceFee(0));
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* update my venue */
  const updateMyVenue = async (payload) => {
    setLoading(true);
    try {
      const { success, message } = await patchMyVenue(payload);

      if (success) {
        myVenueDetail();
        toastNotif({
          message: message || "Update successfully",
          type: "success",
        });
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchBank = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getBank();

      if (success && result) {
        dispatch(
          setBank(
            result?.map((e) => ({
              ...e,
              value: e?.bankName,
              label: e?.bankName,
            }))
          )
        );
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchMembers = async (filter) => {
    setLoading(true);
    try {
      const { success, message, result, totalRow, page, pageSize, totalPage } =
        await getMembers(filter);
      if (success && result) {
        dispatch(
          setMembers({
            data: result,
            totalRow,
            page,
            pageSize,
            totalPage,
          })
        );
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const addMember = async (payload) => {
    setLoading(true);
    try {
      const { success, message } = await postAddMember(payload);
      if (success) {
        fetchMembers({ page: 1, pageSize: 10, status: payload?.tab });
        toastNotif({
          message: message || "Create member successfully",
          type: "success",
        });
      } else {
        setMessage("Create member failed");
        toastNotif({
          message: message || "Create member failed",
          type: "error",
        });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const updateMembership = async (id, payload) => {
    setLoading(true);
    try {
      const { success, message } = await putMembership(id, payload);
      if (success) {
        fetchMembers({ page: 1, pageSize: 10, status: "WAITING APPROVAL" });
        toastNotif({
          message: message || "Update successfully",
          type: "success",
        });
        setLoading(false);
        return true;
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
        setLoading(false);
        return false;
      }
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      setLoading(false);
      return false;
    }
  };

  const fetchMemberHistory = async (customerId) => {
    setLoading(true);
    try {
      const { success, message, result, totalRow, page, pageSize, totalPage } =
        await getMemberHistory({ customerId, page: 1, pageSize: 100 });
      if (success && result) {
        dispatch(
          setMemberHistory({
            data: result,
            totalRow,
            page,
            pageSize,
            totalPage,
          })
        );
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };
  return {
    error,
    message,
    loading,
    myVenueDetail,
    updateMyVenue,
    onGetServiceFee,
    fetchBank,
    fetchMembers,
    addMember,
    updateMembership,
    fetchMemberHistory,
  };
};
