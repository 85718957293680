import React from "react";
import { IconSearch } from "../../../assets/images";
import "../../../components/Input/Search/Search.style.css";

const InputSearch = ({
  value,
  setValue,
  labelClassName = "",
  className = "",
  placeholder = "",
  disabled = false,
  label = "",
}) => {
  return (
    <React.Fragment>
      <div className={labelClassName}>
        {label && (
          <div className="mb-[4px]">
            <label className={`${labelClassName} form-label`}>{label}</label>
          </div>
        )}
        <span className="block relative group">
          <span className="form-search-input-left-icon !top-[15%]">
            <img alt="search input" src={IconSearch} />
          </span>
          <input
            type="text"
            value={value?.name || ""}
            onChange={({ target }) => setValue({ name: target.value })}
            placeholder={placeholder}
            disabled={disabled}
            className={`${
              disabled ? "cursor-not-allowed" : ""
            } ${className} !text-lg form-search-input !border-l-0 focus-within:!border-l-[1px] !transition-all !duration-200 !rounded-l-none !h-[64px]`}
          />
        </span>
      </div>
    </React.Fragment>
  );
};

export default InputSearch;
