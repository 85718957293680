import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import { IconArrowGreen } from "../../../assets/images";
import { formatDateArr } from "../../../utils/formatters";
import Image from "../../Image";

const DateRange = ({
  totalDiffDays = 6,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  className = "",
  displayDaysClassName = "",
  minDate,
  isDisplayDays = false,
  selectedDate,
  setSelectedDate,
  maxDate,
  isDisabled,
}) => {
  const onNext = () => {
    const totalDay = totalDiffDays + 1;
    const dateMax = moment(maxDate);
    const dateEnd = moment(endDate).add(totalDay, "days");
    const diff = dateMax.diff(dateEnd, "days");
    if (
      (maxDate &&
        new Date(maxDate)?.getTime() <= new Date(moment(endDate))?.getTime()) ||
      diff < 1
    ) {
      setStartDate(new Date(moment(startDate).add(totalDay, "days")));
      return setEndDate(maxDate);
    }
    setStartDate(new Date(moment(startDate).add(totalDay, "days")));
    setEndDate(new Date(moment(endDate).add(totalDay, "days")));
  };

  const onPrev = () => {
    const totalDay = totalDiffDays + 1;
    const dateMin = moment(minDate);
    const dateStart = moment(startDate).subtract(totalDay, "days");
    const diff = dateStart.diff(dateMin, "days");
    if (
      (minDate &&
        new Date(minDate)?.getTime() >=
          new Date(moment(startDate))?.getTime()) ||
      diff <= 0
    ) {
      setStartDate(minDate);
      return setEndDate(new Date(moment(minDate).add(totalDiffDays, "days")));
    }
    setStartDate(new Date(moment(startDate).subtract(totalDay, "days")));
    setEndDate(
      new Date(
        moment(new Date(moment(startDate).subtract(totalDay, "days"))).add(
          totalDiffDays,
          "days"
        )
      )
    );
  };

  const onEndDate = (date) => {
    const date1 = moment(startDate);
    const date2 = moment(date);
    const diff = date2.diff(date1, "days");
    if (
      diff > totalDiffDays ||
      diff < totalDiffDays ||
      moment(date).format("D MMMM yyyy") ===
        moment(startDate).format("D MMMM yyyy")
    ) {
      return setEndDate(new Date(moment(startDate).add(totalDiffDays, "days")));
    }
    return setEndDate(date);
  };

  const onStartDate = (date) => {
    const dateMax = moment(maxDate);
    const choosenDate = moment(date);
    const diff = dateMax.diff(choosenDate, "days");
    if (diff < totalDiffDays) {
      setStartDate(new Date(moment(maxDate).subtract(totalDiffDays, "days")));
      return setEndDate(maxDate);
    }
    setStartDate(date);
    return setEndDate(new Date(moment(date).add(totalDiffDays, "days")));
  };
  return (
    <React.Fragment>
      <div
        className={`${className} flex justify-between gap-2 border-1.5 border-gray-300 rounded-lg py-3 px-10`}
      >
        <Image
          src={IconArrowGreen}
          alt="icon arrow green"
          height={19}
          width={19}
          className={`${
            new Date(minDate)?.getTime() >=
            new Date(moment(startDate))?.getTime()
              ? "cursor-not-allowed !opacity-40"
              : "cursor-pointer"
          } my-auto rotate-90`}
          onClick={() =>
            new Date(minDate)?.getTime() <=
              new Date(moment(startDate))?.getTime() && onPrev()
          }
        />
        <div className="my-auto flex justify-between text-gray-500">
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            className="w-full text-center !bg-white"
            dateFormat="d MMMM yyyy"
            selected={startDate}
            onChange={onStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            disabled={isDisabled}
          />
          <p className="px-1">-</p>
          <DatePicker
            className="w-full text-center !bg-white"
            dateFormat="d MMMM yyyy"
            selected={endDate}
            onChange={onEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={maxDate}
            disabled={isDisabled}
          />
        </div>
        <Image
          src={IconArrowGreen}
          alt="icon arrow green"
          height={19}
          width={19}
          className={`${
            (maxDate &&
              new Date(maxDate)?.getTime() <=
                new Date(moment(endDate))?.getTime()) ||
            moment(maxDate).diff(moment(endDate).add(0, "days"), "days") < 1
              ? "cursor-not-allowed !opacity-40 pointer-events-none"
              : "cursor-pointer"
          } my-auto rotate-[270deg]`}
          onClick={onNext}
        />
      </div>
      {isDisplayDays && (
        <div className={`${displayDaysClassName} grid grid-cols-7`}>
          {formatDateArr(startDate, endDate)?.map((e, i) => (
            <div
              key={i}
              onClick={() => setSelectedDate && setSelectedDate(e)}
              className={`${
                moment(new Date(selectedDate)).format("dddd, D MMMM yyyy") ===
                  moment(new Date(e)).format("dddd, D MMMM yyyy") &&
                "!text-white !bg-primary-default"
              } ${
                setSelectedDate && "cursor-pointer hover:bg-primary-50"
              } col-span-1 border-[1px] border-gray-300 py-1 transition-colors duration-200 mt-6`}
            >
              <p
                className={`${
                  moment(new Date(selectedDate)).format("dddd, D MMMM yyyy") ===
                    moment(new Date(e)).format("dddd, D MMMM yyyy") &&
                  "!text-white"
                } text-center text-xl font-semibold text-gray-500`}
              >
                {moment(new Date(e)).format("dddd")}
              </p>
              <p className="text-center text-xl">
                {moment(new Date(e)).format("D MMM")}
              </p>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default DateRange;
