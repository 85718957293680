import api from "./api";

export const getStaff = async (
  body = {
    email: null,
    password: null,
  }
) => {
  return api
    .get({
      endpoint: "/staff",
      port: process.env.REACT_APP_PORT_OWNER,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
