import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import SelectOption from "../../../components/Input/SelectOptions";
import InputTextarea from "../../../components/Input/Textarea";
import Modal from "../../../components/Modal";
import { useMyBooking } from "../../../hooks/useMyBooking";
import { useMyVenue } from "../../../hooks/useMyVenue";

const Add = ({ isShow, setIsShow, tab }) => {
  const [note, setNote] = useState();
  const [customer, setCustomer] = useState();
  const [searchCust, setSearchCust] = useState();

  const { customers } = useSelector((state) => state.mybook);

  const { addMember, loading } = useMyVenue();
  const { searchCustomer } = useMyBooking();

  useEffect(() => {
    if (isShow) {
      setCustomer(undefined);
      setNote(undefined);
    }
  }, [isShow, setNote, setCustomer]);

  useEffect(() => {
    if (searchCust?.length > 2) {
      (async () => {
        await searchCustomer(searchCust || "");
      })();
    }
  }, [searchCust]);

  const onSubmit = async () => {
    await addMember({
      customerId: customer?.value,
      customerName: customer?.name,
      customerEmail: customer?.email,
      customerPhone: customer?.phoneNumber,
      remaks: note,
      tab: tab === "menunggu persetujuan" ? "WAITING APPROVAL" : "ACTIVE",
    });
    setIsShow(false);
  };
  return (
    <Modal.Common
      showModal={isShow}
      title="Tambah Anggota"
      closeModal={() => setIsShow(false)}
    >
      <div className="w-[512px] px-10 pb-10">
        <SelectOption
          required
          isSearchable
          loadOptions={setSearchCust}
          value={customer}
          setValue={setCustomer}
          label="Customer"
          placeholder="Choose customer"
          options={customers?.map((e) => ({
            ...e,
            value: e?.id,
            label: `${e?.name} (${e?.email})`,
          }))}
        />
        <InputTextarea
          label="Catatan"
          labelClassName="mt-3"
          setValue={setNote}
          value={note}
          placeholder="Tulis catatan"
        />
        <div className="flex justify-end gap-4">
          <div className="w-fit">
            <Button
              className="mt-8 tracking-wider font-[14px] px-12"
              size="sm"
              label="Simpan"
              type="primary"
              btnType="button"
              isDisable={!customer}
              isSubmit={loading}
              onClick={onSubmit}
            />
          </div>
          <div className="w-fit">
            <Button
              className="mt-8 tracking-wider font-[14px] px-12"
              size="sm"
              label="Keluar"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={() => setIsShow(false)}
            />
          </div>
        </div>
      </div>
    </Modal.Common>
  );
};

export default Add;
