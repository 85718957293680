import api from "./api";

export const postGallery = async (body) => {
  return api
    .post({
      body,
      endpoint: "/own/images/venue/upload",
      port: process.env.REACT_APP_PORT_IMG,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
