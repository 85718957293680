import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../../../components/Button";
import SelectOption from "../../../components/Input/SelectOptions";
import InputText from "../../../components/Input/Text";
import Modal from "../../../components/Modal";
import { useDashboard } from "../../../hooks/useDashboard";
import { unformatRupiah } from "../../../utils/currency";
import { isAlphabet } from "../../../utils/validator";

const CreateDisbursement = ({ showForm, setShowForm }) => {
  const { availableDisbursebank } = useSelector((state) => state.dashboard);

  const { createPaymentDisbursement, fetchAvailableDisburseBank } =
    useDashboard();

  const validationSchema = Yup.object().shape({
    bankName: Yup.object().nullable().required("Bank required"),
    bankAccountHolder: Yup.string().required("Recipient account name required"),
    bankAccountNumber: Yup.string().required(
      "Recipient account number required"
    ),
    amount: Yup.string().required("Amount required"),
    reference: Yup.string().default("Payment").notRequired(),
    description: Yup.string().required("Description required"),
    pin: Yup.string().max(6).min(6).required("PIN required"),
  });

  const {
    register,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setTimeout(() => {
      if (showForm) {
        reset();
        fetchAvailableDisburseBank();
      }
    }, 300);
  }, [showForm]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const b = getValues();
      createPaymentDisbursement({
        ...b,
        amount: Number(unformatRupiah(b?.amount)),
        bankName: b?.bankName?.value,
      });
    } finally {
      setShowForm(false);
    }
  };
  return (
    <Modal.Common
      showModal={showForm}
      title="Create Disbursement"
      description="Select your bank account and amount by complete form information below"
      closeModal={() => setShowForm(false)}
    >
      <form onSubmit={onSubmit} className="w-[757px] px-10 pb-10">
        <SelectOption
          name="bankName"
          label="Bank"
          isSearchable
          placeholder="Choose bankName"
          required
          options={availableDisbursebank}
          control={control}
          errorMessage={errors?.bankName?.message?.toString()}
        />
        <InputText
          labelClassName="mt-3"
          value={watch("bankAccountHolder")}
          required
          type="text"
          label="Recipient Account Name"
          placeholder="Insert recipient account name"
          setValue={(value) => {
            if (isAlphabet(value) || !value) {
              return setValue("bankAccountHolder", value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }
          }}
          errorMessage={errors?.bankAccountHolder?.message?.toString()}
        />
        <InputText
          labelClassName="mt-3"
          name="bankAccountNumber"
          required
          type="number"
          label="Recipient Account Number"
          placeholder="Insert recipient account number"
          register={register}
          errorMessage={errors?.bankAccountNumber?.message?.toString()}
        />
        <InputText
          labelClassName="mt-3"
          name="amount"
          required
          type="price"
          label="Amount"
          placeholder="Insert amount"
          value={watch("amount")}
          setValue={(value) => setValue("amount", value)}
          errorMessage={errors?.amount?.message?.toString()}
        />
        <InputText
          labelClassName="mt-3"
          name="reference"
          type="text"
          label="Reference"
          placeholder="Insert reference"
          register={register}
          errorMessage={errors?.reference?.message?.toString()}
        />
        <InputText
          labelClassName="mt-3"
          name="description"
          required
          type="text"
          label="Description"
          placeholder="Insert description"
          register={register}
          errorMessage={errors?.description?.message?.toString()}
        />
        <div className="mt-6">
          <InputText
            labelClassName="mt-3 !w-[200px]"
            name="pin"
            required
            type="password"
            label="PIN"
            placeholder="* * * * * *"
            register={register}
            errorMessage={errors?.pin?.message?.toString()}
          />
        </div>
        <div className="flex justify-end gap-4">
          <div className="w-[180%]" />
          <Button
            className="mt-8 tracking-wider font-[14px] w-40"
            label="Create"
            type="primary"
            btnType="submit"
            isDisable={!isValid || isSubmitting}
            isSubmit={isSubmitting && isValid}
          />
          <Button
            className="mt-8 tracking-wider font-[14px] w-40"
            label="Cancel"
            type="outline-primary"
            btnType="button"
            isDisable={false}
            onClick={() => setShowForm(false)}
          />
        </div>
      </form>
    </Modal.Common>
  );
};

export default CreateDisbursement;
