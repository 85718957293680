import React, { useMemo } from "react";
import { Navigate } from "react-router-dom";
import { retrieveLocalStorageItem } from "../../utils/localStorage";
import Dashboard from "../Layout";

const Authorization = ({ children, redirectTo, useDashboard = false }) => {
  const accessToken = retrieveLocalStorageItem("accessToken");
  const Auth = useMemo(() => {
    if (accessToken) {
      return true;
    }
    return false;
  }, [accessToken]);

  const content = useMemo(() => {
    if (useDashboard) {
      return <Dashboard>{children}</Dashboard>;
    }
    return children;
  }, [useDashboard, children]);

  return Auth ? content : <Navigate to={redirectTo} />;
};

export default Authorization;
