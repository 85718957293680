import api from "./api";

export const getMasterTimeInactive = async (params) => {
  return api
    .get({
      endpoint: "/exception-time-booking/master/time/booking",
      port: process.env.REACT_APP_PORT_OPENHOUR,
      queryParam: params,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
