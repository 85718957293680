import { SET_GENERAL_SETTING_STATUS_PIN } from "../../assets/constants/reducer.type";

const initialState = {
  status_pin: "",
};

const generalSettingReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_GENERAL_SETTING_STATUS_PIN:
      return { ...state, status_pin: payload };
    default:
      return state;
  }
};

export default generalSettingReducer;
