export const formatRupiah = (currency, char, withoutRp) => {
  if (!currency) return "Rp0";
  let rupiah = "";
  if (typeof currency === "string") {
    return currency;
  }
  const currencyFloat = currency.toFixed(0);
  const angkarev = currencyFloat.toString().split("").reverse().join("");
  for (let i = 0; i < angkarev.length; i++) {
    if (i % 3 === 0) {
      rupiah += angkarev.substr(i, 3) + (char || ".");
    }
  }
  return (
    (withoutRp ?? false ? "" : "Rp ") +
    rupiah
      .split("", rupiah.length - 1)
      .reverse()
      .join("")
  );
};

export const unformatRupiah = (price) => {
  try {
    return price.replace(/\D/g, "");
  } catch (error) {
    return "0";
  }
};
