import moment from "moment";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { IconSports } from "../../../assets/images";
import Image from "../../../components/Image";

const GreetingCard = ({ className = "", venueName = "" }) => {
  /* Route */
  const navigate = useNavigate();

  const currentDate = useMemo(() => {
    return moment(new Date()).format("dddd, DD MMM yyyy");
  }, [new Date()]);

  return (
    <React.Fragment>
      <div
        className={`${className} transition-all duration-[0.5s] bg-[#EEF3F1] rounded-lg lg:flex`}
      >
        <div className="block pt-7 pl-8 mr-20 relative">
          <div className="lg:min-w-[195px] w-fit text-center uppercase font-semibold text-primary-default text-[12px] bg-primary-50 rounded-[100px] px-5 py-2">
            {currentDate}
          </div>
          <Image
            src={IconSports}
            alt="Icon Sports"
            className="absolute bottom-0 lg:block hidden"
          />
        </div>
        <div className="lg:pt-[35px] lg:pr-[45px] px-9 py-4">
          <p className="text-primary-default mb-2 text-[16px] font-bold tracking-[0.3px]">
            Hi, {venueName}
          </p>
          <p className="text-dark-default text-2xl font-bold tracking-[0.3px]">
            Welcome to SPORTIRENA!
          </p>
          <p className="text-gray-500 text-[14px] mt-2 leading-[20px] mb-2">
            SPORTIRENA is an integrated and easy to use online booking and
            facility management solution designed to help you manage your
            resources with ease.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GreetingCard;
