import api from "./api";

export const postForgotPassword = async (body) => {
  return api
    .post({
      body,
      endpoint: "/auth/request/forgot-password",
      port: process.env.REACT_APP_PORT_OWNER,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
