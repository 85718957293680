import React from "react";
import { IconWarning } from "../../../assets/images";
import { formatRupiah, unformatRupiah } from "../../../utils/currency";
import { isNumber } from "../../../utils/validator";
import Image from "../../Image";
import "./Text.style.css";

const InputText = ({
  type = "text", // "text" | "password" | "number" | "email"
  className = "",
  icon,
  iconClassName = "",
  iconOnClick,
  iconPosition,
  register,
  label,
  required,
  labelClassName,
  errorMessage,
  placeholder,
  name,
  value,
  setValue,
  disabled = false,
  iconType = "image", // "image" | "string"
  maxLength,
  maxPrice,
}) => {
  const onChange = (e) => {
    const tempValue = e.target.value;
    if (maxLength && maxLength < tempValue?.length) {
      return;
    }
    if (type === "price") {
      const nonIDR = unformatRupiah(tempValue);
      if (isNumber(nonIDR)) {
        const IDR = formatRupiah(
          maxPrice && Number(nonIDR || "0") > maxPrice
            ? maxPrice
            : Number(nonIDR || "0"),
          "."
        );
        return setValue && setValue(IDR);
      }
      return (
        setValue &&
        setValue(tempValue === "" || tempValue.length < 4 ? "" : value)
      );
    }
    return setValue && setValue(tempValue);
  };

  return (
    <React.Fragment>
      <div className={labelClassName}>
        {label && (
          <div className="mb-[4px]">
            <label className={`${labelClassName} form-label`}>
              {label}{" "}
              {required && <span className="text-danger-default">*</span>}
            </label>
          </div>
        )}
        <span className="block relative">
          {register && name ? (
            <input
              {...register(name)}
              type={type === "price" ? "text" : type}
              placeholder={placeholder}
              disabled={disabled}
              className={`${
                disabled ? "cursor-not-allowed" : ""
              } ${className} ${
                errorMessage
                  ? `form-input${
                      icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                    }-error`
                  : `form-input${
                      icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                    }`
              }`}
            />
          ) : (
            <input
              type={type === "price" ? "text" : type}
              onWheel={(event) => event.currentTarget.blur()}
              value={value || ""}
              onChange={onChange}
              placeholder={placeholder}
              disabled={disabled}
              className={`${
                disabled ? "cursor-not-allowed" : ""
              } ${className} ${
                errorMessage
                  ? `form-input${
                      icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                    }-error`
                  : `form-input${
                      icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                    }`
              }`}
            />
          )}
          {icon && iconPosition && iconType === "image" && (
            <span className={`form-input-${iconPosition}-icon`}>
              <img
                alt={`input ${name}`}
                src={icon}
                className={iconClassName}
                onClick={iconOnClick}
              />
            </span>
          )}
          {icon && iconPosition && iconType === "string" && (
            <span
              className={`form-input-${iconPosition}-icon ${iconClassName}`}
            >
              {icon}
            </span>
          )}
        </span>
        {errorMessage && (
          <div className={"form-input-message"}>
            <Image
              src={IconWarning}
              alt={`icon warning-${name}`}
              className="max-h-[15px]"
            />
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InputText;
