import { yupResolver } from "@hookform/resolvers/yup";
import CryptoJS from "crypto-js";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { IconPencil, IconTrash } from "../../assets/images";
import Image from "../../components/Image";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import { useStaff } from "../../hooks/useStaff";
import { retrieveLocalStorageItem } from "../../utils/localStorage";
import StaffMForm from "./StaffMForm";

async function decryptAES(ciphertext, secretkey) {
  var bytes = CryptoJS.AES.decrypt(ciphertext, secretkey);
  var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedData;
}

const StaffManagement = () => {
  const [showForm, setShowForm] = useState(false);
  const [id, setId] = useState(null);
  const [modalAction, setModalAction] = useState(null);

  const { list } = useSelector((state) => state.staff);
  const { getListStaff, createStaff, updateStaff, dropStaff } = useStaff();

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    firtsName: Yup.string().required("Name required"),
    role: Yup.object().nullable().required("Role required"),
    status: Yup.object().nullable().required("Status required"),
    email: Yup.string().email("Wrong format email").required("Email required"),
    password: Yup.string().required("Password required"),
    confirmPassword: Yup.string()
      .required("Confirm password required")
      .oneOf([Yup.ref("password")], "Wrong password"),
  });

  const {
    register,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const role = useMemo(() => {
    return JSON.parse(
      retrieveLocalStorageItem("info") || "{}"
    )?.role?.toLowerCase();
  }, [retrieveLocalStorageItem("info")]);

  useEffect(() => {
    if (role !== "admin") navigate("/", { replace: true });
  }, [role]);

  useEffect(() => {
    getListStaff();
  }, []);

  const onToggleModal = () => {
    setShowForm(!showForm);
    setId(null);
    reset();
  };

  const onSubmit = async () => {
    const payload = getValues();
    if (id) {
      setModalAction({
        label: "Are you sure want to update this staff?",
        title: "Update Staff",
        click: () => editStaff(id, payload),
      });
    } else {
      await createStaff({
        email: payload.email,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        name: payload.firtsName,
        role: payload.role?.value,
        status: payload.status?.value,
      });
    }
    onToggleModal();
  };

  const editStaff = async (id, payload) => {
    await updateStaff(id, {
      email: payload.email,
      password: payload.password,
      confirmPassword: payload.confirmPassword,
      name: payload.firtsName,
      role: payload.role?.value,
      status: payload.status?.value,
    });
    setModalAction(null);
  };

  const deleteStaff = async (id) => {
    await dropStaff(id);
    setModalAction(null);
  };

  const showUpdate = async (e) => {
    setId(e.id);
    setValue("role", { label: e.role, value: e.role });
    setValue("status", { label: e.status, value: e.status });
    setValue("firtsName", e.firstName);
    setValue("email", e.email);
    setValue("password", await decryptAES(e.password, e.secretKey));
    setValue("confirmPassword", await decryptAES(e.password, e.secretKey));
    setShowForm(true);
  };
  return (
    <React.Fragment>
      <StaffMForm
        errors={errors}
        isSubmitting={isSubmitting}
        isValid={isValid}
        onToggleModal={onToggleModal}
        register={register}
        showForm={showForm}
        control={control}
        onSubmit={onSubmit}
        isUpdate={id ? true : false}
      />
      <div className="py-7 px-11">
        <Table
          subHeader
          usePagination
          useButtonAdd
          buttonAddTitle="+ Add New Staff"
          onClickBtnAdd={() => setShowForm(true)}
          title="User Management"
          description="Manage all your existing staff/admin or edit or add new staff/admin"
          data={list}
          textTransform="none"
          columns={[
            {
              name: <p className="text-center w-full">No</p>,
              width: "8%",
              cell: (_row, i) => {
                return <p className="text-center w-full">{i + 1}</p>;
              },
            },
            {
              name: "Name",
              sortable: true,
              selector: (row) => row.firstName || "-",
            },
            {
              name: "Email",
              sortable: true,
              selector: (row) => row.email || "-",
            },
            {
              name: "Status",
              sortable: true,
              selector: (row) => row.status || "-",
            },
            {
              name: "Role",
              sortable: true,
              selector: (row) => row.role || "-",
            },
            {
              name: "Action",
              width: "13%",
              cell: (row, i) => {
                if (row.role !== "Admin") {
                  return (
                    <div className="flex justify-between gap-3" key={i}>
                      <div
                        onClick={() => showUpdate(row)}
                        className="p-[8px] bg-primary-50 rounded-lg hover:opacity-[0.7]"
                      >
                        <Image
                          src={IconPencil}
                          alt="Icon Pencil"
                          height={25}
                          width={25}
                        />
                      </div>
                      <div
                        onClick={() =>
                          setModalAction({
                            label: "Are you sure want to remove this staff?",
                            title: "Remove Staff",
                            click: () => deleteStaff(row.id),
                          })
                        }
                        className="p-[8px] bg-red-50 rounded-lg hover:opacity-[0.7]"
                      >
                        <Image
                          src={IconTrash}
                          alt="Icon Pencil"
                          height={25}
                          width={25}
                        />
                      </div>
                    </div>
                  );
                }
              },
            },
          ]}
        />
        <Modal.Delete
          showModalDelete={modalAction ? true : false}
          label={modalAction?.label}
          onCancel={() => setModalAction(null)}
          title={modalAction?.title}
          onDelete={modalAction?.click}
        />
      </div>
    </React.Fragment>
  );
};

export default StaffManagement;
