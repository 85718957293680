import api from "./api";

export const getCourtDetail = async (id) => {
  return api
    .get({
      endpoint: `/own/court/${id}`,
      port: process.env.REACT_APP_PORT_COURT,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
