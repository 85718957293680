import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../components/Button";
import InputText from "../../../components/Input/Text";
import Modal from "../../../components/Modal";
import { useAuth } from "../../../hooks/useAuth";

const ForgotPassword = ({ showModal, setShowModal }) => {
  /* Hooks */
  const { forgotPassword } = useAuth();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Wrong format email").required("Email required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    reset();
  }, [showModal]);
  return (
    <React.Fragment>
      <Modal.Common
        title="Forgot Your Password?"
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        description="Enter your email for a reset password link"
      >
        <form
          className="w-full md:w-[594px] px-8 pb-10"
          onSubmit={handleSubmit(forgotPassword)}
        >
          <InputText
            name="email"
            type="email"
            label="Email"
            placeholder="Insert email address"
            register={register}
            errorMessage={errors?.email?.message?.toString()}
          />
          <Button
            className="mt-4 tracking-wider font-[14px]"
            label="Send Reset Password Link"
            type="primary"
            btnType="submit"
            isDisable={!isValid || isSubmitting}
            isSubmit={isSubmitting && isValid}
          />
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default ForgotPassword;
