import {
    SET_CONFIGURATION_VENUE
  } from "../../assets/constants/reducer.type";

  const initialState = {
    listConfig: null,
  };

  const configVenueReducer = (
    state = initialState,
    actions = {
      type: "",
      payload: null,
    }
  ) => {
    const { type, payload } = actions;
  
    switch (type) {
      case SET_CONFIGURATION_VENUE:
        return { ...state, listConfig: payload };
      default:
        return state;
    }
  };
  
  export default configVenueReducer;
