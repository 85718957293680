import React from "react";
import Modal from "../../../components/Modal";
import SelectOption from "../../../components/Input/SelectOptions";
import Button from "../../../components/Button";
import { useSelector } from "react-redux";

const ChangeCourt = ({
  setShowChangeCourt,
  showChangeCourt,
  court,
  setCourt,
  onChangeCourt,
}) => {
  /* Redux */
  const { allcourts } = useSelector((state) => state.court);
  return (
    <React.Fragment>
      <Modal.Common
        closeModal={() => setShowChangeCourt(false)}
        showModal={showChangeCourt}
        title="Change Court"
        description={
          <span className="text-danger-default">
            by changing the court will cause the lost of previous input!
          </span>
        }
      >
        <div className="px-8 pb-8 w-[675px]">
          <SelectOption
            placeholder="Choose court"
            options={allcourts}
            setValue={setCourt}
            value={court}
            label="Courts"
          />
          <div className="flex justify-end gap-4">
            <div className="w-[180%]" />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Apply"
              type="primary"
              btnType="button"
              isDisable={!court}
              onClick={onChangeCourt}
            />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={() => setShowChangeCourt(false)}
            />
          </div>
        </div>
      </Modal.Common>
    </React.Fragment>
  );
};

export default ChangeCourt;
