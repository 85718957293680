export const storeLocalStorageItem = ({
  storageKey = "accessToken" | "info",
  storageValue,
}) => {
  try {
    return localStorage.setItem(storageKey, storageValue);
  } catch (error) {
    return error;
  }
};

export const retrieveLocalStorageItem = (
  storageKey = "accessToken" | "info"
) => {
  try {
    return localStorage.getItem(storageKey);
  } catch (error) {
    return error;
  }
};

export const removeLocalStorageItem = (storageKey = "accessToken" | "info") => {
  try {
    return localStorage.removeItem(storageKey);
  } catch (error) {
    return error;
  }
};

export const setDocumentCookie = (value) => {
  return (document.cookie = value);
};

export const getDocumentCookie = () => {
  return document.cookie;
};
