import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import Button from "../Button";
import Card from "../Card";

const Modal = (children) => {
  return (
    <React.Fragment>
      <div>{children}</div>
    </React.Fragment>
  );
};

const CommonModal = ({
  title = "Modal Title",
  description = "",
  showModal = false,
  closeModal,
  children,
  confirmButton,
}) => {
  return (
    <React.Fragment>
      <Transition appear show={showModal || false} as="div">
        <Dialog as="div" onClose={closeModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 z-[1000000]" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto z-[1000000]">
            <div className="flex min-h-full items-center justify-center p-4 text-left">
              <Transition.Child
                as="div"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Card>
                  <Card.Header onClose={closeModal}>
                    <p className="font-bold text-2xl w-full">
                      {title}
                      {description && (
                        <span className="block text-gray-500 text-[14px] font-normal">
                          {description}
                        </span>
                      )}
                    </p>
                  </Card.Header>
                  {children}
                  <div className={confirmButton ? "visible" : "hidden"}>
                    <Card.Footer>
                      <Button
                        isDisable={false}
                        isSubmit={false}
                        label={confirmButton || "OKE"}
                        onClick={closeModal}
                        type="primary"
                      />
                    </Card.Footer>
                  </div>
                </Card>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

const ModalDelete = ({
  label,
  onCancel,
  onDelete,
  showModalDelete,
  title,
  className = "",
}) => {
  return (
    <CommonModal
      showModal={showModalDelete}
      title={title || "Konfirmasi Hapus"}
      closeModal={onCancel}
    >
      <p className={`${className} text-center text-lg px-10 w-92`}>
        {label || "Apakah Anda yakin ingin menghapus item ini?"}
      </p>
      <div className="flex justify-between mt-6 gap-2 px-8 pb-5">
        <Button
          label="Yes"
          type="primary"
          btnType="button"
          isDisable={false}
          onClick={onDelete}
        />
        <Button
          label="Cancel"
          type="outline-primary"
          isDisable={false}
          onClick={onCancel}
          btnType="button"
        />
      </div>
    </CommonModal>
  );
};

const ImageModal = ({ showModal, children }) => {
  return (
    <React.Fragment>
      <Transition appear show={showModal} as="div">
        <Dialog as="div">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 z-[1000000]" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto z-[1000000]">
            <div className="flex min-h-full items-center justify-center p-4 text-left">
              <Transition.Child
                as="div"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Card>{children}</Card>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
};

Modal.Common = CommonModal;
Modal.Delete = ModalDelete;
Modal.Image = ImageModal;

export default Modal;
