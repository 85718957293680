import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../../components/Button";
import InputRadio from "../../../components/Input/Radio";
import SelectOption from "../../../components/Input/SelectOptions";
import InputText from "../../../components/Input/Text";
import Modal from "../../../components/Modal";
import { useTimeBooking } from "../../../hooks/useTimeBooking";

const BulkEdit = ({ setShowBulkEdit, showBulkEdit }) => {
  const [isActive, setIsActive] = useState("1");
  const [price, setPrice] = useState();
  const [days, setDays] = useState();
  const [times, setTimes] = useState();

  const { masterDaysTime } = useSelector((state) => state.timebooking);

  const { loading, fetchMasterTimeDays, bulkUpdateTimeBooking } =
    useTimeBooking();

  useEffect(() => {
    if (showBulkEdit) {
      !masterDaysTime && fetchMasterTimeDays();
      setPrice(undefined);
      setDays(undefined);
      setTimes(undefined);
    }
  }, [showBulkEdit]);

  const onSubmit = async () => {
    await bulkUpdateTimeBooking({
      status: !!Number(isActive),
      days: days?.map((e) => e?.value),
      price,
      times,
    });
    setShowBulkEdit(false);
  };
  return (
    <React.Fragment>
      <Modal.Common
        closeModal={() => setShowBulkEdit(false)}
        showModal={showBulkEdit || false}
        title="Bulk Edit"
      >
        <div className="lg:w-[471px] md:w-[471px] w-full px-10 pb-10">
          <SelectOption
            closeMenuOnSelect={false}
            isLoading={loading}
            isMulti
            label="Days"
            name="bankName"
            value={days}
            setValue={setDays}
            placeholder="Choose Days"
            options={masterDaysTime?.master_days?.map((e) => ({
              value: e,
              label: e,
            }))}
          />
          <SelectOption
            closeMenuOnSelect={false}
            isLoading={loading}
            isMulti
            labelClassName="mt-2"
            label="Booking Time"
            name="bankName"
            value={times}
            setValue={setTimes}
            placeholder="Choose Booking Time"
            options={masterDaysTime?.master_times?.map((e) => ({
              ...e,
              value: e?.id,
              label: `${e?.startTime} - ${e?.endTime}`,
            }))}
          />
          <InputRadio
            items={[
              { label: "Active", value: "1" },
              { label: "Inactive", value: "0" },
            ]}
            value={isActive}
            setValue={setIsActive}
            col={6}
            labelClassName="mt-4"
          />
          <hr className="mt-4" />
          <div className="flex justify-start gap-16 mt-5">
            <p className="font-bold text-mediumSmall text-gray-500 my-auto w-28">
              Booking Price
            </p>
            <div className="w-full">
              <InputText
                type="number"
                className="!w-full"
                placeholder="Insert price"
                value={price}
                setValue={setPrice}
              />
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-20">
            <Button
              label="Save"
              type="primary"
              size="sm"
              className="!w-fit px-12"
              isDisable={!days?.length || !times?.length || !price}
              onClick={onSubmit}
              isSubmit={loading}
            />
            <Button
              className="!w-fit px-12"
              label="Cancel"
              type="outline-primary"
              size="sm"
              isDisable={false}
              onClick={() => setShowBulkEdit(false)}
            />
          </div>
        </div>
      </Modal.Common>
    </React.Fragment>
  );
};

export default BulkEdit;
