import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourts } from "../core/GET_Courts";
import { getAllCourts } from "../core/GET_AllCourt";
import {
  setCourtList,
  setAllCourt,
  setCourtDetail,
} from "../stores/actions/court.action";
import { toastNotif } from "../utils/toastNotif";
import { postCourt } from "../core/POST_Court";
import { deleteCourt } from "../core/DELETE_Court";
import { putCourt } from "../core/PUT_Court";
import { getCourtDetail } from "../core/GET_CourtDetail";

export const useCourt = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const { list } = useSelector((state) => state.court);

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  /* get courts */
  const fetchCourts = async (param, isScroll) => {
    setLoading(true);
    try {
      const { success, message, result, rows } = await getCourts(param);

      if (success && result) {
        dispatch(
          setCourtList(
            list && isScroll && param?.page > 1
              ? { ...list, data: [...list?.data, ...result] }
              : { data: result, rows }
          )
        );
      } else {
        dispatch(setCourtList());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchAllCourts = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getAllCourts();

      if (success && result) {
        dispatch(
          setAllCourt(
            result.map((item) => ({ label: item?.courtName, value: item?.id }))
          )
        );
      } else {
        dispatch(setAllCourt());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* post courts */
  const createCourt = async (payload) => {
    setLoading(true);
    try {
      const { success, message } = await postCourt(payload);

      if (!success) {
        setMessage("Create court failed");
        toastNotif({
          message: message || "Create court failed",
          type: "error",
        });
      }
      toastNotif({
        message: message || "Create court successfully",
        type: "success",
      });
      return await fetchCourts();
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* delete courts */
  const removeCourt = async (id) => {
    setLoading(true);
    try {
      const { success, message } = await deleteCourt(id);

      if (!success) {
        setMessage("Delete court failed");
        toastNotif({
          message: message || "Delete court failed",
          type: "error",
        });
      }
      toastNotif({
        message: message || "Delete court successfully",
        type: "success",
      });
      return await fetchCourts();
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* update courts */
  const updateCourt = async (id, body) => {
    setLoading(true);
    try {
      const { success, message } = await putCourt(id, body);

      if (!success) {
        setMessage("Delete court failed");
        toastNotif({
          message: message || "Delete court failed",
          type: "error",
        });
      }
      toastNotif({
        message: message || "Delete court successfully",
        type: "success",
      });
      return await fetchCourts();
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* detail courts */
  const detailCourt = async (id) => {
    setLoading(true);
    try {
      const { success, message, result } = await getCourtDetail(id);

      if (!success) {
        setMessage("Get detail court failed");
        toastNotif({
          message: message || "Get detail court failed",
          type: "error",
        });
      }
      return dispatch(setCourtDetail(result));
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  return {
    error,
    message,
    loading,
    fetchCourts,
    fetchAllCourts,
    createCourt,
    removeCourt,
    updateCourt,
    detailCourt,
  };
};
