import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "../components/Layout";
import Authorization from "../components/PrivateRoute";
import BankAccount from "../pages/BankAccount";
import BookingManagement from "../pages/BookingManagement";
import AddBooking from "../pages/BookingManagement/AddBooking";
import CheckoutBooking from "../pages/BookingManagement/CheckoutBooking";
import ConfigurationPage from "../pages/Configuration";
import CourtManagement from "../pages/CourtManagement";
import Gallery from "../pages/Gallery";
import Home from "../pages/Home";
import DashboardCalendar from "../pages/Home/Calendar";
import Payment from "../pages/Home/Payment";
import PaymentReport from "../pages/Home/PaymentReport";
import Login from "../pages/Login";
import Membership from "../pages/Membership";
import MembershipConfig from "../pages/Membership/Config";
import MyVenue from "../pages/MyVenue";
import Profile from "../pages/Profile";
import Role from "../pages/Role";
import GeneralSetting from "../pages/Setting/General/general-setting";
import StaffManagement from "../pages/StaffManagement";
import TimeBooking from "../pages/TimeBooking";
import ExceptionPage from "../pages/TimeBooking/Exception";

const App = () => {
  return (
    <React.Fragment>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <Home />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/staff-management"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <StaffManagement />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/payment-report"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <PaymentReport />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/payment"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <Payment />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/time-booking"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <TimeBooking />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/time-booking/exception"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <ExceptionPage />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/roles"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <Role />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/venue"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <MyVenue />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/court-management"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <CourtManagement />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/bank-account"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <BankAccount />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/booking-management"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <BookingManagement />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/booking-management/add-booking"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <AddBooking />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/booking-management/checkout-booking"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <CheckoutBooking />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/gallery"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <Gallery />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/profile"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <Profile />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/calendar"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <DashboardCalendar />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/membership"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <Membership />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/configuration"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <ConfigurationPage />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/membership/config"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <MembershipConfig />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/setting-general"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <GeneralSetting />
                </Layout>
              </Authorization>
            }
          />
          <Route
            path="/*"
            element={
              <Authorization redirectTo="/">
                <Layout>
                  <Home />
                </Layout>
              </Authorization>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
