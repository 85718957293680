import { SET_PAYMENT_REPORT } from "../../assets/constants/reducer.type";

const initialState = {
  listPaymentReport: null,
};

const paymentReportReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_PAYMENT_REPORT:
      return { ...state, listPaymentReport: payload };
    default:
      return state;
  }
};

export default paymentReportReducer;
