import React, { useMemo } from "react";
import "../Role.style.css";
import Toggle from "../../../components/Input/Toggle";

const Permission = ({ tabs, setTabs }) => {
  const onChangeTab = (id) => {
    setTabs(
      tabs.map((e) => {
        if (e.id === id) {
          return { ...e, active: true };
        }
        return { ...e, active: false };
      })
    );
  };

  const itemPermissions = useMemo(() => {
    if (tabs) {
      return tabs.filter((tab) => tab.active)[0].items;
    }
    return null;
  }, [tabs]);
  return (
    <React.Fragment>
      <label className="form-label">Permission</label>
      <div className="border-[1px] mt-3 w-full border-[#DFE0EB] rounded-lg grid grid-cols-4 text-gray-500">
        <div className="col-span-1 pl-7 border-r-2 border-gray-300 text-sm">
          {tabs &&
            tabs.map((e) => (
              <p
                key={e.id}
                onClick={() => !e.active && onChangeTab(e.id)}
                className={`form-role-tab${e.active ? "-active" : ""}`}
              >
                {e.name}
              </p>
            ))}
        </div>
        <div className="col-span-3 text-sm">
          <div className="grid px-8 grid-cols-2 py-3 font-bold border-b-2 border-gray-300">
            <p className="text-center">Permissions</p>
            <p className="text-center">Grant</p>
          </div>
          <div className="max-h-32 overflow-y-auto">
            {itemPermissions &&
              itemPermissions.map((e) => (
                <div className="grid grid-cols-2 py-3 mt-2 px-8" key={e.id}>
                  <p className="text-center">{e.name}</p>
                  <div className="flex mx-auto pl-6">
                    <Toggle
                      key={e.id}
                      id={e.id}
                      checked={e.isGrant}
                      // setChecked={onChangeStatus}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Permission;
