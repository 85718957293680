import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { postChangePasswordForgot } from "../core/POST_ChangePassForgot";
import { postChangePassword } from "../core/POST_ChangePassword";
import { postForgotPassword } from "../core/POST_ForgotPassword";
import {
  removeLocalStorageItem,
  retrieveLocalStorageItem,
  storeLocalStorageItem,
} from "../utils/localStorage";
import { toastNotif } from "../utils/toastNotif";
import { getProfile } from "../core/GET_Profile";
import { useDispatch } from "react-redux";
import { setProfile } from "../stores/actions/common.action";
import { postChangeProfile } from "../core/POST_ChangeProfile";

export const useAuth = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const dispatch = useDispatch();

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  /* forgot password */
  const forgotPassword = async (payload) => {
    setLoading(true);
    try {
      const { success, message, token } = await postForgotPassword(payload);

      if (!success && !token) {
        toastNotif({
          message: message || "Request forgot password failed",
          type: "error",
        });
        return setLoading(false);
      }
      storeLocalStorageItem({ storageKey: "accessToken", storageValue: token });
      const user = await getProfile();
      if (user.success && user.result) {
        storeLocalStorageItem({
          storageKey: "info",
          storageValue: JSON.stringify(user.result),
        });
      }
      toastNotif({
        message: message || "Request forgot password successfully",
        type: "success",
      });
      navigate("/profile?tab=security", {
        state: { isForgotPassword: true },
      });
      return setLoading(false);
    } catch (err) {
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* change password from forgot password */
  const changePasswordForgot = async (payload) => {
    setLoading(true);
    try {
      const token = await retrieveLocalStorageItem("accessToken");
      const { success, message } = await postChangePasswordForgot({
        ...payload,
        token,
      });

      if (!success) {
        toastNotif({
          message: message || "Change password failed",
          type: "error",
        });
        return setLoading(false);
      }
      toastNotif({
        message: message || "Change password successfully",
        type: "success",
      });
      navigate("/dashboard");
      return setLoading(false);
    } catch (err) {
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* change password */
  const changePassword = async (payload) => {
    setLoading(true);
    try {
      const { success, message } = await postChangePassword(payload);

      if (!success) {
        toastNotif({
          message: message || "Change password failed",
          type: "error",
        });
        return setLoading(false);
      }
      toastNotif({
        message: message || "Change password successfully",
        type: "success",
      });
      navigate("/dashboard");
      return setLoading(false);
    } catch (err) {
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get profile */
  const fetchProfile = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getProfile();

      if (!success) {
        toastNotif({
          message: message || "Get profile failed",
          type: "error",
        });
        return setLoading(false);
      }
      dispatch(setProfile(result));
      return setLoading(false);
    } catch (err) {
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* change profile */
  const changeProfile = async (payload) => {
    setLoading(true);
    try {
      const { success, message } = await postChangeProfile(payload);
      if (!success) {
        toastNotif({
          message: message || "Change profile failed",
          type: "error",
        });
        return setLoading(false);
      }
      const user = await getProfile();
      removeLocalStorageItem("info");
      if (user.success && user.result) {
        storeLocalStorageItem({
          storageKey: "info",
          storageValue: JSON.stringify(user.result),
        });
        dispatch(setProfile(user.result));
      } else {
        removeLocalStorageItem("accessToken");
        window.location.replace("/");
      }
      window.location.reload();
      return setLoading(false);
    } catch (err) {
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  return {
    error,
    message,
    loading,
    forgotPassword,
    changePassword,
    changePasswordForgot,
    fetchProfile,
    changeProfile,
  };
};
