import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getGalleries } from "../core/GET_Galleries";
import { setGalleries } from "../stores/actions/gallery.action";
import { toastNotif } from "../utils/toastNotif";
import { postGallery } from "../core/POST_Gallery";
import { deleteGallery } from "../core/DELETE_Gallery";
import { putMainImage } from "../core/PUT_SetMainImage";

export const useGallery = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  /* get galleries */
  const fetchGalleries = async (param) => {
    setLoading(true);
    try {
      const { message, result, rows } = await getGalleries(param);

      if (result) {
        dispatch(setGalleries({ data: result, rows }));
      } else {
        dispatch(setGalleries());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* post gallery */
  const uploadGallery = async (body) => {
    setLoading(true);
    try {
      const { message, success, succcess } = await postGallery(body);

      if (success || succcess) {
        toastNotif({
          message: message || "Upload image successfully",
          type: "success",
        });
        return await fetchGalleries();
      }
      setMessage("Upload image failed");
      return toastNotif({
        message: message || "Upload image failed",
        type: "error",
      });
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* post gallery */
  const removeGallery = async (id) => {
    setLoading(true);
    try {
      const { message, success, succcess } = await deleteGallery(id);

      if (success || succcess) {
        toastNotif({
          message: message || "Delete image successfully",
          type: "success",
        });
        return await fetchGalleries();
      }
      setMessage("Delete image failed");
      return toastNotif({
        message: message || "Delete image failed",
        type: "error",
      });
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const setMainImage = async (id) => {
    setLoading(true);
    try {
      const { message, success, succcess } = await putMainImage(id);

      if (success || succcess) {
        toastNotif({
          message: message || "Set main image successfully",
          type: "success",
        });
        return await fetchGalleries();
      }
      setMessage("Set main image failed");
      return toastNotif({
        message: message || "Set main image failed",
        type: "error",
      });
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  return {
    error,
    message,
    loading,
    fetchGalleries,
    uploadGallery,
    removeGallery,
    setMainImage,
  };
};
