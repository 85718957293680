import api from "./api";

export const postPaymentDisbursement = async (payload) => {
  return api
    .post({
      endpoint: "/disbursement/create",
      port: process.env.REACT_APP_PORT_PAYMENT,
      body: payload,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
