import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IconCalendar } from "../../../assets/images";
import "../../../components/Input/Date/Date.style.css";

const DateRange = ({ setStartDate, setEndDate, startDate, endDate }) => {
  return (
    <React.Fragment>
      <label
        htmlFor="date-picker"
        className="flex justify-start gap-2 !h-[62px] w-full form-input"
      >
        <img
          alt="sportirena icon date"
          src={IconCalendar}
          className="my-auto h-[34px] w-[34px]"
        />
        <DatePicker
          id="date-picker"
          selectsRange
          onChange={(dates) => {
            const [start, end] = dates;
            setStartDate(start);
            setEndDate(end);
          }}
          startDate={startDate}
          endDate={endDate}
          className="hidden"
          monthClassName="!bg-danger-default"
        />
        <div className="flex justify-between gap-2 my-auto">
          <div className="block">
            <p>{startDate ? moment(startDate).format("DD/MM/yyy") : ""}</p>
            <p className="text-center">
              {startDate ? `${moment(startDate).format("hh:mm")} AM` : ""}
            </p>
          </div>
          <p>-</p>
          <div className="block">
            <p>{endDate ? moment(endDate).format("DD/MM/yyy") : ""}</p>
            <p className="text-center">
              {endDate ? `${moment(endDate).format("hh:mm")} AM` : ""}
            </p>
          </div>
        </div>
      </label>
    </React.Fragment>
  );
};

export default DateRange;
