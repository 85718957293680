import React from "react";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import InputText from "../../../components/Input/Text";
import SelectOption from "../../../components/Input/SelectOptions";

const listBank = [
  {
    label: "BCA",
    value: "BCA",
  },
  {
    label: "MANDIRI",
    value: "MANDIRI",
  },
  {
    label: "BNI",
    value: "BNI",
  },
  {
    label: "BSI",
    value: "BSI",
  },
  {
    label: "BRI",
    value: "BRI",
  },
]

const FormModal = ({
  showForm,
  onToggleModal,
  isSubmitting,
  isValid,
  errors,
  register,
  control,
  onSubmit,
  isUpdate
}) => {
  return (
    <React.Fragment>
      <Modal.Common
        showModal={showForm}
        title="Bank Account"
        description={`${isUpdate ? 'Update' : 'Add new'} bank account by complete form information below`}
        closeModal={onToggleModal}
      >
        <form className="w-[757px] px-10 pb-10" onSubmit={onSubmit}>
        <SelectOption
            label="Bank Name"
            labelClassName="mt-3"
            name="bankName"
            placeholder="Choose Bank Name"
            required
            options={listBank}
            control={control}
            errorMessage={errors?.bankName?.message?.toString()}
          />
          <InputText
            labelClassName="mt-3"
            name="bankAccountHolder"
            required
            type="text"
            label="Bank Account Holder"
            placeholder="Insert Bank Account Holder"
            register={register}
            errorMessage={errors?.bankAccountHolder?.message?.toString()}
          />
          <InputText
            className="number"
            labelClassName="mt-3"
            name="bankAccountNumber"
            min={0}
            required
            type="number"
            label="Bank Account Number"
            placeholder="Insert Bank Account Number"
            register={register}
            errorMessage={errors?.bankAccountNumber?.message?.toString()}
          />
          <div className="flex justify-end gap-4">
            <div className="w-[180%]" />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label={`${isUpdate ? 'Update' : 'Submit'}`}
              type="primary"
              btnType="submit"
              isDisable={!isValid || isSubmitting}
              isSubmit={isSubmitting && isValid}
            />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={onToggleModal}
            />
          </div>
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default FormModal;
