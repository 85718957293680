import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IconTrash } from "../../assets/images";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import Image from "../../components/Image";
import SelectOption from "../../components/Input/SelectOptions";
import Modal from "../../components/Modal";
import Table from "../../components/Table";
import { useCourt } from "../../hooks/useCourt";
import { useTimeBooking } from "../../hooks/useTimeBooking";
import AddException from "./Modal/AddException";

const ExceptionPage = () => {
  const [pageSize, setPageSize] = useState(10);
  const [court, setCourt] = useState(null);
  const [day, setDay] = useState(null);
  const [time, setTime] = useState(null);
  const [idRemoved, setIdRemoved] = useState();
  const [isShowAddException, setIsShowAddException] = useState(false);

  const { masterDaysTime, exceptionList, rows } = useSelector(
    (state) => state.timebooking
  );
  const { allcourts } = useSelector((state) => state.court);

  const { fetchMasterTimeDays, loading, fetchExceptionList, removeException } =
    useTimeBooking();
  const { fetchAllCourts } = useCourt();

  const { state } = useLocation();

  useEffect(() => {
    fetchAllCourts();
    fetchMasterTimeDays();
  }, []);

  useEffect(() => {
    if (state?.court) {
      setCourt(allcourts?.find((e) => e?.value === state?.court));
    }
  }, [state?.court, allcourts]);

  useEffect(() => {
    if (state?.startEndTime) {
      setTime(
        masterDaysTime?.master_times
          ?.map((e) => ({
            ...e,
            value: e?.id,
            label: `${e?.startTime} - ${e?.endTime}`,
          }))
          ?.find((e) => e?.startEndTime === state?.startEndTime)
      );
    }
  }, [state?.startEndTime, masterDaysTime]);

  useEffect(() => {
    !idRemoved && onLoadData(10, 1);
  }, [time, court, day, idRemoved]);

  const onLoadData = (row, paging) => {
    const params = {
      page: paging,
      pageSize: row || pageSize,
      day: day?.value || "",
      startEndTime: time?.startEndTime || "",
      courtId: court?.value || "",
    };
    row && setPageSize(row || pageSize);
    fetchExceptionList(params);
  };
  return (
    <React.Fragment>
      <AddException
        setShowException={setIsShowAddException}
        showException={isShowAddException}
        choosenCourt={court}
      />
      <Modal.Delete
        label="Apakah Anda yakin ingin menghapus 
Exception Date ini?"
        showModalDelete={!!idRemoved}
        onDelete={async () => {
          const res = await removeException(idRemoved);
          res && setIdRemoved(undefined);
        }}
        onCancel={() => setIdRemoved(undefined)}
      />
      <Breadcrumb
        tabs={[
          { name: "Time Booking", route: "/time-booking" },
          { name: "Exception Booking Date", route: "/time-booking/exception" },
        ]}
      />
      <div className="py-7 px-11">
        <div className="grid grid-cols-5">
          <p className="font-semibold text-2xl text-left col-span-4">
            Exception Booking Date
            <span className="text-medium text-gray-500 font-normal block mt-1">
              Manage all your existing close time booking for specific date
            </span>
          </p>
        </div>
        <div className="lg:flex justify-between mt-8 mb-7">
          <div className="lg:flex md:flex justify-start gap-4">
            <div className="lg:w-56 md:w-56 w-full">
              <SelectOption
                placeholder="Choose Court"
                required
                value={court}
                setValue={setCourt}
                options={allcourts}
              />
            </div>
            <div className="lg:w-56 md:w-56 w-full lg:mt-0 md:mt-0 mt-4">
              <SelectOption
                placeholder="Choose Day"
                setValue={setDay}
                value={day}
                options={masterDaysTime?.master_days?.map((e) => ({
                  value: e,
                  label: e,
                }))}
              />
            </div>
            <div className="lg:w-56 md:w-56 w-full lg:mt-0 md:mt-0 mt-4">
              <SelectOption
                placeholder="Choose Time"
                required
                value={time}
                setValue={setTime}
                options={masterDaysTime?.master_times?.map((e) => ({
                  ...e,
                  value: e?.id,
                  label: `${e?.startTime} - ${e?.endTime}`,
                }))}
              />
            </div>
          </div>
          <Button
            label="Add Exception"
            type="primary"
            size="sm"
            className="lg:w-fit md:w-fit w-full px-5 lg:my-auto mt-4"
            isDisable={false}
            onClick={() => setIsShowAddException(true)}
          />
        </div>
        <Table
          progressPending={loading}
          usePagination
          paginationPerPage={pageSize}
          onChangePage={(e) => {
            onLoadData(undefined, e);
          }}
          onChangeRowsPerPage={onLoadData}
          paginationTotalRows={rows}
          paginationServer
          subHeader={false}
          data={exceptionList}
          textTransform="none"
          columns={[
            {
              name: <p className="text-center w-full">No</p>,
              width: "8%",
              cell: (_row, i) => {
                return <p className="text-center w-full">{i + 1}</p>;
              },
            },
            {
              name: "Court",
              sortable: true,
              selector: () => court?.label || "-",
            },
            {
              name: "Day",
              sortable: true,
              selector: (row) => row?.day || "-",
            },
            {
              name: "Date",
              sortable: true,
              selector: (row) =>
                moment(new Date(row?.date)).format("DD MMMM yyyy") || "-",
            },
            {
              name: "Time",
              selector: (row) => `${row?.startTime} - ${row?.endTime}` || "-",
            },
            {
              name: "Status",
              sortable: true,
              width: "13%",
              selector: (row) => row?.status || "-",
            },
            {
              name: "Action",
              width: "8%",
              cell: (row, i) => (
                <Image
                  onClick={() => setIdRemoved(row?.id)}
                  src={IconTrash}
                  alt="Icon Trash"
                  height={25}
                  width={25}
                />
              ),
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default ExceptionPage;
