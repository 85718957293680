import {
    SET_LIST_MY_BANK_ACCOUNT,
    SET_DETAIL_MY_BANK_ACCOUNT
} from "../../assets/constants/reducer.type";

const initialState = {
  list: null,
  detail: null
};

const mybankaccoutReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_LIST_MY_BANK_ACCOUNT:
      return { ...state, list: payload };
    case SET_DETAIL_MY_BANK_ACCOUNT:
        return { ...state, detail: payload };
    default:
      return state;
  }
};

export default mybankaccoutReducer;
