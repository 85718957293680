import React from "react";
import { IconWarning } from "../../../assets/images";
import "./Textarea.style.css";

const InputTextarea = ({
  className = "",
  register,
  label = "",
  required,
  labelClassName = "",
  errorMessage,
  placeholder = "",
  name = "",
  value,
  setValue,
  disabled = false,
  maxLength = 120,
  textLength = 0,
  useDisplayLengthChar = false,
  rows = 5,
}) => {
  return (
    <React.Fragment>
      <div className={labelClassName}>
        {label && (
          <div className="mb-[4px]">
            <label className={`${labelClassName} form-label`}>
              {label}{" "}
              {required && <span className="text-danger-default">*</span>}
            </label>
          </div>
        )}
        <span className="block relative">
          {register && name ? (
            <textarea
              {...register(name)}
              placeholder={placeholder}
              disabled={disabled}
              className={`${
                disabled ? "cursor-not-allowed" : ""
              } ${className} ${
                errorMessage ? "form-input-area-error" : "form-input-area"
              }`}
              maxLength={maxLength}
              rows={rows}
            />
          ) : (
            <textarea
              value={value}
              onChange={({ target }) => setValue(target.value)}
              placeholder={placeholder}
              disabled={disabled}
              className={`${
                disabled ? "cursor-not-allowed" : ""
              } ${className} ${
                errorMessage ? "form-input-area-error" : "form-input-area"
              }`}
              maxLength={maxLength}
              rows={rows}
            />
          )}
        </span>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-9">
            {errorMessage && (
              <div className="form-input-message">
                <img
                  src={IconWarning}
                  alt={`icon warning hijab meezha-${name}`}
                  className="max-h-[15px]"
                />
                <p>{errorMessage}</p>
              </div>
            )}
          </div>
          {useDisplayLengthChar && (
            <div className="col-span-3 text-right text-[13px] text-gray-600">
              {textLength || 0}/{maxLength}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default InputTextarea;
