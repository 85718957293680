import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCourt } from "../../hooks/useCourt";
import { useMyBooking } from "../../hooks/useMyBooking";
import { useMyVenue } from "../../hooks/useMyVenue";
import { setDashboardCalendar } from "../../stores/actions/mybooking.action";
import AddBookingForm from "../BookingManagement/Form/AddBookingForm";

const DashboardCalendar = () => {
  /* Local State */
  const [court, setCourt] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(moment().add(6, "days")));
  const [choosenDate, setChoosenDate] = useState(new Date());

  /* Redux */
  const { detail, allcourts } = useSelector((state) => state.court);
  const { detail: venueDetail } = useSelector((state) => state.myvenue);
  const { ttlcart, dashboardCalendar } = useSelector((state) => state.mybook);
  const dispatch = useDispatch();

  /* Hooks */
  const { fetchAllCourts, detailCourt } = useCourt();
  const { myVenueDetail } = useMyVenue();
  const { fetchDashboardCalendar, loading } = useMyBooking();

  useEffect(() => {
    fetchAllCourts();
    if (!venueDetail) myVenueDetail();
  }, []);

  useEffect(() => {
    if (allcourts?.length) {
      setCourt(allcourts[0]);
      detailCourt(allcourts[0]?.value);
    }
  }, [allcourts]);

  useEffect(() => {
    dispatch(setDashboardCalendar());
    if (choosenDate) {
      fetchDashboardCalendar({
        date: moment(new Date(choosenDate)).format("yyyy-MM-DD"),
      });
    }
  }, [court, choosenDate]);

  useEffect(() => {
    setChoosenDate(startDate);
  }, [startDate]);

  return (
    <React.Fragment>
      <div className="w-full">
        <div className="py-7 md:px-11">
          <p className="font-semibold text-2xl text-left mb-6 md:px-0 px-11">
            Dashboard Calendar
            <span className="text-medium text-gray-500 font-normal block mt-1">
              View all your booking in the calendar
            </span>
          </p>
          <AddBookingForm
            allCourts={allcourts}
            canChooseItem={false}
            choosenDate={choosenDate}
            setChoosenDate={setChoosenDate}
            detailCourt={detail}
            venueDetail={venueDetail}
            totalcart={ttlcart}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            dataAllHour={dashboardCalendar}
            isLoading={loading}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardCalendar;
