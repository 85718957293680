import React, { useMemo } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import AsyncSelect from "react-select/async";
import { IconWarning } from "../../../assets/images";

export const animatedComponents = makeAnimated();

const SelectOption = ({
  placeholder = "Pilih ...",
  setValue,
  value,
  options,
  disabled,
  errorMessage,
  label,
  labelClassName,
  name,
  required,
  isClearable = false,
  control,
  type = "default", // 'transparent' | 'default',
  isSearchable = false,
  isLoading = false,
  isMulti = false,
  closeMenuOnSelect = true,
  height,
  fontSize = "12px",
  useAsync,
  loadOptions,
}) => {
  const customStyles = useMemo(() => {
    if (errorMessage) {
      return {
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            fontSize,
            color: "#C5C7CD",
            fontWeight: "400",
            marginTop: "auto",
            marginBottom: "auto",
          };
        },
        dropdownIndicator: (base) => ({
          ...base,
          color: "#CCCCCC",
        }),
        control: (base, state) => ({
          ...base,
          background: "#FCFDFE",
          minHeight: "45px",
          color: "#CCCCCC",
          fontSize: "16px",
          fontWeight: "400",
          borderColor: "#CC3434",
          borderRadius: "0.5rem",
          maxHeight: "auto",
          borderWidth: "1px",
          padding: "0 11px",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            borderColor: "#CC3434",
          },
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontSize: "16px",
            lineHeight: "25px",
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? "#F5F7FA"
              : isFocused
              ? "#F5F7FA"
              : undefined,
            color: isDisabled ? "#ccc" : isSelected ? "#979797" : "black",
            cursor: isDisabled ? "not-allowed" : "default",
            ":active": {
              ...styles[":active"],
              backgroundColor: isSelected ? "#F5F7FA" : undefined,
            },
          };
        },
      };
    }
    if (type === "transparent") {
      return {
        placeholder: (defaultStyles) => {
          return {
            ...defaultStyles,
            color: "#C5C7CD",
            fontSize,
            fontWeight: "400",
            marginTop: "auto",
            marginBottom: "auto",
          };
        },
        dropdownIndicator: (base) => ({
          ...base,
          color: "#ccccc",
        }),
        control: (base, state) => ({
          ...base,
          background: "transparent",
          minHeight: "45px",
          fontWeight: "400",
          borderColor: !state.isFocused ? "rgb(229 231 235)" : "#eab1a2",
          maxHeight: "auto",
          padding: "0 11px",
          border: "none",
          cursor: state.isDisabled ? "not-allowed" : "default",
          boxShadow: state.isFocused ? null : null,
          "&:hover": {
            borderColor: state.isFocused ? "#eab1a2" : "rgb(229 231 235)",
          },
        }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            fontSize: "16px",
            lineHeight: "25px",
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? "#F5F7FA"
              : isFocused
              ? "#F5F7FA"
              : undefined,
            color: isDisabled ? "#ccc" : isSelected ? "#979797" : "black",
            cursor: isDisabled ? "not-allowed" : "default",
            ":active": {
              ...styles[":active"],
              backgroundColor: isSelected ? "#F5F7FA" : undefined,
            },
          };
        },
      };
    }
    return {
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: "#C5C7CD",
          fontSize,
          fontWeight: "400",
          marginTop: "auto",
          marginBottom: "auto",
        };
      },
      dropdownIndicator: (base) => ({
        ...base,
        color: "#ccccc",
      }),
      control: (base, state) => ({
        ...base,
        background: state.isDisabled ? "#F9F9F9" : "#FCFDFE",
        fontSize,
        minHeight: "45px",
        height,
        fontWeight: "400",
        borderColor: !state.isFocused ? "#F0F1F7" : "#737374",
        borderRadius: "0.5rem",
        maxHeight: "auto",
        padding: "0 11px",
        borderWidth: "1px",
        cursor: state.isDisabled ? "not-allowed" : "default",
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          borderColor: state.isFocused ? "#737374" : "#C5C7CD",
          cursor: "pointer",
        },
      }),
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          fontSize,
          lineHeight: "25px",
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ? "#F5F7FA"
            : isFocused
            ? "#F5F7FA"
            : undefined,
          color: isDisabled ? "#ccc" : isSelected ? "#979797" : "black",
          cursor: isDisabled ? "not-allowed" : "default",
          ":active": {
            ...styles[":active"],
            backgroundColor: isSelected ? "#F5F7FA" : undefined,
            cursor: "pointer",
            fontSize,
          },
        };
      },
    };
  }, [errorMessage, type, height, fontSize]);
  return (
    <React.Fragment>
      {label && (
        <div className={`${labelClassName} mb-[4px]`}>
          <label className={"form-label"}>
            {label} {required && <span className="text-danger-default">*</span>}
          </label>
        </div>
      )}
      {control && !setValue ? (
        <Controller
          name={name || "select"}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              closeMenuOnSelect={closeMenuOnSelect}
              placeholder={placeholder}
              components={{
                ...animatedComponents,
                IndicatorSeparator: () => null,
              }}
              options={options}
              styles={customStyles}
              isDisabled={disabled}
              isClearable={isClearable}
              isSearchable={isSearchable}
              isLoading={isLoading}
              isMulti={isMulti}
            />
          )}
        />
      ) : useAsync ? (
        <AsyncSelect
          cacheOptions
          defaultOptions
          closeMenuOnSelect={closeMenuOnSelect}
          placeholder={placeholder}
          components={{
            ...animatedComponents,
            IndicatorSeparator: () => null,
          }}
          options={options}
          styles={customStyles}
          isDisabled={disabled}
          value={value}
          onChange={setValue}
          isClearable={isClearable}
          isSearchable
          isLoading={isLoading}
          isMulti={isMulti}
          loadOptions={loadOptions}
        />
      ) : (
        <Select
          onInputChange={loadOptions}
          closeMenuOnSelect={closeMenuOnSelect}
          placeholder={placeholder}
          components={{
            ...animatedComponents,
            IndicatorSeparator: () => null,
          }}
          options={options}
          styles={customStyles}
          isDisabled={disabled}
          value={value}
          onChange={setValue}
          isClearable={isClearable}
          isSearchable={isSearchable}
          isLoading={isLoading}
          isMulti={isMulti}
        />
      )}
      {errorMessage && (
        <div className={"form-input-message"}>
          <img
            src={IconWarning}
            alt={`icon warning-${name}`}
            className="max-h-[15px]"
          />
          <p>{errorMessage}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectOption;
