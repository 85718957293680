import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { BASE_URL_PREVIEW_IMG } from "../../assets/constants/constants";
import { IconTrashGreen } from "../../assets/images";
import Breadcrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Checkbox from "../../components/Input/Checkbox";
import InputRadio from "../../components/Input/Radio";
import Modal from "../../components/Modal";
import { useMyBooking } from "../../hooks/useMyBooking";
import { useMyVenue } from "../../hooks/useMyVenue";
import { IDRFormat } from "../../utils/formatters";

const CheckoutBooking = () => {
  const { state } = useLocation();
  const [customer, setCustomer] = useState();
  const [cartBooking, setCartBooking] = useState();
  const [venueDetail, setVenueDetail] = useState();
  const [detailCourt, setDetailCourt] = useState();
  const [deleteHour, setDeleteHour] = useState();
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showPaymentConfirmation, setShowPaymetConfirmation] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("TUNAI");

  const { listcart } = useSelector((state) => state.mybook);
  const { servicefee } = useSelector((state) => state.myvenue);

  const { onGetServiceFee } = useMyVenue();
  const { checkoutBooking, fetchBookingCart, eventDeleteCartBooking, loading } =
    useMyBooking();

  useEffect(() => {
    onGetServiceFee();
  }, []);

  useEffect(() => {
    if (
      !state ||
      !state?.customer ||
      !state?.venueDetail ||
      !state?.detailCourt
    ) {
      window.location.replace("/booking-management/add-booking");
    } else {
      fetchBookingCart(state?.customer?.id);
      setCustomer(state?.customer);
      setDetailCourt(state?.detailCourt);
      setVenueDetail(state?.venueDetail);
    }
  }, [state?.customer, state?.venueDetail, state?.detailCourt]);

  useEffect(() => {
    setCartBooking(listcart?.map((e) => ({ ...e, isSelect: true })));
  }, [listcart]);

  const totalPrice = useMemo(() => {
    let itemPrice = 0;
    const items = cartBooking?.filter((e) => e?.isSelect);
    if (items?.length) {
      items?.forEach((e) => (itemPrice += e?.price));
    }
    const grandTotal = itemPrice > 0 ? itemPrice + servicefee : itemPrice;
    return {
      itemPrice,
      grandTotal,
    };
  }, [cartBooking, servicefee]);

  const onCheck = (id, isSelect) => {
    if (id) {
      setCartBooking(
        cartBooking?.map((e) => {
          if (id === e.id) {
            return { ...e, isSelect };
          }
          return e;
        })
      );
    }
  };

  const onDelete = () => {
    eventDeleteCartBooking(deleteHour?.id, state?.customer?.id);
    setDeleteHour();
  };

  const onCheckout = () => {
    const payload = {
      paymentType: paymentMethod,
      customerId: state?.customer?.id,
      customerName: state?.customer?.name,
      customerEmail: state?.customer?.email,
      serviceFee: servicefee,
      data: cartBooking
        ?.filter((e) => e?.isSelect)
        ?.map((e) => ({
          ...e,
          cartId: e?.id,
          bookDate: e?.date,
        })),
    };
    checkoutBooking(payload);
  };
  return (
    <React.Fragment>
      <Modal.Delete
        label={`Are you sure you want to use a ${
          paymentMethod === "NON_TUNAI" ? "virtual account" : "cash"
        } as the payment method?`}
        title="Payment Confirmation"
        className="w-[420px] text-center"
        onCancel={() => setShowPaymetConfirmation(false)}
        onDelete={onCheckout}
        showModalDelete={showPaymentConfirmation}
      />
      <Modal.Delete
        label={`Are you sure want to delete booking at ${moment(
          deleteHour?.date
        ).format("DD MMMM yyyy")} in ${deleteHour?.startTime} to ${
          deleteHour?.endTime
        }`}
        title="Delete Court Booking Hour"
        className="w-[420px] text-center"
        showModalDelete={deleteHour ? true : false}
        onCancel={() => setDeleteHour()}
        onDelete={onDelete}
      />
      <Modal.Common
        children={
          <div
            className="p-4 w-[500px]"
            dangerouslySetInnerHTML={{ __html: venueDetail?.privacyPolicy }}
          />
        }
        title="Term & Conditions"
        className="w-[420px] text-center"
        showModal={showPrivacyPolicy}
        closeModal={() => setShowPrivacyPolicy(false)}
      />
      <div className="w-full">
        <Breadcrumb
          tabs={[
            { name: "Booking Management", route: "/booking-management" },
            { name: "Add Booking", route: "/booking-management/add-booking" },
            {
              name: "Checkout Booking",
              route: "/booking-management/checkout-booking",
            },
          ]}
        />
        <div className="py-7 px-11">
          <p className="font-bold text-primary-default my-auto text-3xl">
            {venueDetail?.venueName || "-"}
          </p>
          <p className="font-[500] text-[#292A2E] text-xl mt-4 mb-7">
            Booking Lapangan
          </p>
          <div className="bg-primary-50 py-4 pl-7 pr-24 rounded-lg w-fit">
            <p className="text-sm font-bold mb-1">{customer?.name}</p>
            <p className="text-gray-500 text-small">
              {customer?.email} - {customer?.phoneNumber}
            </p>
          </div>
          <div className="grid grid-cols-3 gap-7">
            <div className="col-span-2 mt-7 w-full block">
              <div className="border-primary-default rounded-lg border-[1px] py-6 px-2 grid grid-cols-12 gap-2 w-full mb-8">
                <div className="text-center col-span-1">
                  <Checkbox
                    id="all"
                    onClick={(value) =>
                      setCartBooking(
                        cartBooking.map((e) => ({ ...e, isSelect: value }))
                      )
                    }
                    checked={
                      cartBooking?.filter((e) => !e.isSelect)?.length
                        ? false
                        : true
                    }
                  />
                </div>
                <p className="text-center col-span-3 text-primary-default text-lg">
                  Court
                </p>
                <p className="text-center col-span-3 text-primary-default text-lg">
                  Date
                </p>
                <p className="text-center col-span-2 text-primary-default text-lg">
                  Time
                </p>
                <p className="text-center col-span-2 text-primary-default text-lg">
                  Price
                </p>
                <p className="text-center col-span-1 text-primary-default text-lg">
                  Action
                </p>
              </div>
              {cartBooking?.map((e) => (
                <div
                  className="border-gray-400 rounded-lg border-[1px] py-6 px-2 grid grid-cols-12 gap-2 w-full mb-4"
                  key={e?.id}
                >
                  <div className="text-center col-span-1 my-auto">
                    <Checkbox
                      checked={e?.isSelect}
                      id={e?.id}
                      onClick={(value) => onCheck(e?.id, value)}
                    />
                  </div>
                  <div className="col-span-3 flex justify-center gap-1 items-center">
                    <Image
                      className="text-center h-[40px] w-[40px] rounded-lg m-auto object-cover"
                      src={`${BASE_URL_PREVIEW_IMG}/${detailCourt?.pathName}/${detailCourt?.imageName}`}
                      alt={`${BASE_URL_PREVIEW_IMG}/${detailCourt?.pathName}/${detailCourt?.imageName}`}
                    />
                    <p className="text-small truncate">{e?.courtName}</p>
                  </div>
                  <p className="text-center col-span-3 my-auto text-sm">
                    {moment(e?.date).format("DD MMMM yyyy")}
                  </p>
                  <p className="text-center col-span-2 my-auto text-sm">
                    {e?.startTime} - {e?.endTime}
                  </p>
                  <p className="text-center col-span-2 text-primary-default my-auto text-sm font-bold">
                    {IDRFormat(e?.price)},-
                  </p>
                  <Image
                    src={IconTrashGreen}
                    alt="icon trash"
                    className="text-center col-span-1 m-auto w-8 h-9 cursor-pointer hover:opacity-60 transition-all duration-200"
                    onClick={() => setDeleteHour(e)}
                  />
                </div>
              ))}
            </div>
            <div className="col-span-1 mt-7 w-full block">
              <div className="border-primary-default rounded-lg border-[1px] py-6 px-7 w-full">
                <p className="font-semibold text-lg text-primary-default mb-5">
                  PRICE SUMMARY
                </p>
                <div className="flex justify-between mb-2">
                  <p className="text-sm">Total Hours</p>
                  <p className="text-sm">
                    {cartBooking?.filter((e) => e?.isSelect)?.length} Hour
                  </p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className="text-sm">Item Price</p>
                  <p className="text-sm">
                    {IDRFormat(totalPrice?.itemPrice || 0)}
                  </p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className="text-sm">Service Fee</p>
                  <p className="text-sm">{IDRFormat(servicefee || 0)}</p>
                </div>
                <div className="flex justify-between mb-2">
                  <p className="text-sm">Subtotal</p>
                  <p className="text-sm">
                    {IDRFormat(totalPrice?.grandTotal || 0)}
                  </p>
                </div>
                <div className="flex justify-between mb-2 mt-4">
                  <p className="text-medium font-[500] text-primary-default">
                    Grand Total
                  </p>
                  <p className="text-medium font-[500] text-primary-default">
                    {IDRFormat(totalPrice?.grandTotal || 0)}
                  </p>
                </div>
                <hr className="my-3" />
                <div className="flex gap-4">
                  <Checkbox
                    onClick={() => setPrivacyPolicy(!privacyPolicy)}
                    checked={privacyPolicy}
                    id="rtd"
                  />
                  <p className="text-small text-justify">
                    Dengan melakukan proses checkout, saya menyatakan bahwa saya
                    telah membaca, memahami, dan setuju dengan semua{" "}
                    <span
                      onClick={() => setShowPrivacyPolicy(true)}
                      className="text-[#34880C] hover:text-primary-300 hover:underline cursor-pointer transition-all duration-200"
                    >
                      Term & Conditions
                    </span>{" "}
                    yang berlaku sesuai standar dari venue ini.
                  </p>
                </div>
              </div>
              <InputRadio
                items={[
                  { label: "Tunai", value: "TUNAI" },
                  { label: "Non Tunai", value: "NON_TUNAI" },
                ]}
                value={paymentMethod}
                setValue={setPaymentMethod}
                col={6}
                labelClassName="mt-4"
              />
              <Button
                label="Continue Payment"
                type="primary"
                btnType="button"
                isDisable={
                  !privacyPolicy ||
                  !cartBooking?.filter((e) => e?.isSelect)?.length
                }
                className="mt-4"
                onClick={() => setShowPaymetConfirmation(true)}
                isSubmit={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CheckoutBooking;
