import {
  SET_LIST_MY_BANK_ACCOUNT, 
  SET_DETAIL_MY_BANK_ACCOUNT
} from "../../assets/constants/reducer.type";

export const setListBankAccount = (payload) => {
  return { type: SET_LIST_MY_BANK_ACCOUNT, payload };
};
export const setDetailBankAccount = (payload) => {
  return { type: SET_DETAIL_MY_BANK_ACCOUNT, payload };
};
