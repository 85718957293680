import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Swal from 'sweetalert2'
import { getStaff } from "../core/GET_Staff";
import { postStaff } from "../core/POST_Staff";
import { deleteStaff } from "../core/DELETE_Staff";
import { putStaff } from '../core/PUT_Staff'
import { setListStaff } from "../stores/actions/staff.actions";
import { toastNotif } from "../utils/toastNotif";

export const useStaff = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
  
    const resetState = () => {
      setError(false);
      setMessage(undefined);
    };
  
    useEffect(() => {
      const timer = setTimeout(() => resetState(), 2500);
  
      return () => clearTimeout(timer);
    }, [message]);
  
    /* get my venue detail */
    const getListStaff = async () => {
      setLoading(true);
      try {
        const { success, message, result } = await getStaff();
  
        if (success && result) {
          dispatch(setListStaff(result));
        } else {
          dispatch(setListStaff());
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    };

    const createStaff = async (data) => {
      setLoading(true);
      try {
        const { success, message } = await postStaff(data);
  
        if (success) {
        getListStaff();
          toastNotif({
            message: message || "Created successfully",
            type: "success",
          });
        } else {
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    }

    const dropStaff = async (id) => {
      setLoading(true);
      try {
        const { success, message } = await deleteStaff(id);
        if (success) {
          getListStaff();
          toastNotif({
            message: message || "Deleted successfully",
            type: "success",
          });
        } else {
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    }

    const updateStaff = async (id, data) => {
      setLoading(true);
      try {
        const { success, message } = await putStaff(id, data);
        if (success) {
          getListStaff();
          toastNotif({
            message: message || "Updated successfully",
            type: "success",
          });
        } else {
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    }
  
    return {
      error,
      message,
      loading,
      getListStaff,
      createStaff,
      dropStaff,
      updateStaff
    };
  };