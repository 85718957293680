import moment from "moment";
import "moment/locale/id";
import numeral from "numeral";
import React, { useMemo } from "react";
import { BASE_URL_PREVIEW_IMG } from "../../../assets/constants/constants";
import { IconDollar } from "../../../assets/images";
import Image from "../../../components/Image";

function DetailClose({ item, image }) {
  const paymentDetail = useMemo(() => {
    if (item?.booking?.paymentDetail) {
      return JSON.parse(item?.booking?.paymentDetail);
    }
    return undefined;
  }, [item]);
  return (
    <React.Fragment>
      <div className="mt-8 pb-8 select-none w-[700px] px-10 relative">
        <div className="flex justify-between text-xl">
          <p>{item?.detailbooking?.venueName}</p>
          <p className="text-primary-default font-bold">
            #{item?.booking?.invoiceCode}
          </p>
        </div>
        <Image
          src={
            image ||
            `${BASE_URL_PREVIEW_IMG}/${item?.pathName}/${item?.imageName}`
          }
          alt="icon venue"
          height={143}
          width={276}
          className="rounded-md object-cover w-full mt-5 h-[143px]"
        />
        <div className="flex justify-between my-4">
          <p className="my-auto text-xl font-medium text-primary-default">
            {item?.courtName}
          </p>
          <p className="my-auto text-regular w-fit font-semibold border-[1px] border-primary-default rounded-[10px] px-4 py-3">
            {item?.statusBook}
          </p>
        </div>
        <div className="flex justify-between w-full">
          <div className="block w-full">
            <p className="text-medium font-semibold">Name</p>
            <p className="text-medium">{item?.booking?.customerName}</p>
          </div>
          <div className="block w-full">
            <p className="text-medium font-semibold">Email</p>
            <p className="text-medium">{item?.booking?.customerEmail}</p>
          </div>
        </div>
        <div className="flex justify-between w-full mt-6">
          <div className="block w-full">
            <p className="text-medium font-semibold">Date</p>
            <p className="text-regular">
              {moment(item?.bookDate).format("DD MMMM YYYY")}
            </p>
            <p className="text-medium mt-2">
              {item?.startTime} - {item?.endTime}
            </p>
          </div>
          <div className="block w-full">
            <p className="text-medium font-semibold">Price</p>
            <p className="text-regular text-transparent">
              {moment(item?.bookDate).format("DD MMMM YYYY")}
            </p>
            <p className="text-medium mt-2">
              Rp {numeral(item?.price).format("0,0")}
            </p>
          </div>
        </div>
        <div className="flex justify-between w-full mt-6">
          <div className="block w-full">
            <p className="text-medium font-semibold">Payment Method</p>
            <p className="text-regular">
              {paymentDetail?.payment_channel || "-"}
            </p>
          </div>
          <div className="block w-full">
            <p className="text-medium font-semibold">Payment Time</p>
            <p className="text-regular">
              {moment(paymentDetail?.paid_at).format(
                "dddd, DD MMMM yyyy HH:mm"
              )}
            </p>
          </div>
        </div>
        <div className="flex mt-8 pb-14 justify-end items-end">
          <div className="block relative w-[400px]">
            <div className="flex justify-start absolute right-0">
              <img src={IconDollar} alt="icon dollar" className="mr-2" />
              <p className="my-auto mr-5 text-dark-default font-normal">
                Total Pesanan:
              </p>
              <p className="my-auto text-primary-default text-xl">
                Rp {numeral(item?.price).format("0,0")}
              </p>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 right-0 rounded-b-lg w-full flex justify-between px-10 py-8 bg-primary-default text-white text-xl font-medium">
          <p>Total Price</p>
          <p>Rp {numeral(item?.price).format("0,0")}</p>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DetailClose;
