import api from "./api";

export const getAllCourts = async () => {
  return api
    .get({
      endpoint: "/own/court/simple/list",
      port: process.env.REACT_APP_PORT_COURT,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
