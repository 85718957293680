import { Popover } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { BgNoData, IconArrowRed, IconCalendar } from "../../assets/images";
import Button from "../../components/Button";
import Image from "../../components/Image";
import FormDate from "../../components/Input/Date";
import SelectOption from "../../components/Input/SelectOptions";
import InputText from "../../components/Input/Text";
import Modal from "../../components/Modal";
import Tab from "../../components/Tab";
import { useCourt } from "../../hooks/useCourt";
import { useMyBooking } from "../../hooks/useMyBooking";
import { setQueryBooking } from "../../stores/actions/mybooking.action";
import CardBookingCancelled from "./CardBooking/CardBookingCancelled";
import CardBookingClose from "./CardBooking/CardBookingClose";
import CardBookingReserved from "./CardBooking/CardBookingReserved";
import CardBookingWaitingPayment from "./CardBooking/CardBookingWaitingPayment";
import ModalDetail from "./Detail";
import ModalResponseBooking from "./ResponseBooking";
moment.locale("id");

const BookingManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const [modalAction, setModalAction] = useState(null);
  const [date, setDate] = useState(null);
  const [customerName, setCustomerName] = useState();
  const [choosenCourt, setChoosenCourt] = useState();
  const [invoiceCode, setInvoiceCode] = useState();
  const [filters, setFilters] = useState({ date: null });
  const [tab, setTab] = useState("waiting payment");
  const [modal, setModal] = useState(false);
  const [modalRes, setModalRes] = useState(false);
  const [modalType, setModalType] = useState("");
  const [itemDetail, setItemDetail] = useState();
  const {
    fetchBookingWaitingPayment,
    fetchBookingReserved,
    fetchDetailBookingReserved,
    fetchBookingClose,
    fetchDetailBookingWaitingPayment,
    fetchDetailBookingClose,
    fetchBookingCancelled,
    fetchDetailBookingCancelled,
    responseBooking,
    eventCancelBooking,
  } = useMyBooking();
  const { fetchAllCourts } = useCourt();
  const { list, rows, query, detail } = useSelector((state) => state.mybook);
  const { allcourts } = useSelector((state) => state.court);
  const { page, pageSize } = query || {};
  const validationSchema = Yup.object().shape({
    status: Yup.object().nullable().required("Status required"),
    amount: Yup.string().required("Amount required"),
    remarks: Yup.string().notRequired(),
  });

  const { control } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    fetchBooking(tab);
    fetchAllCourts();
  }, [page, tab]);

  const fetchBooking = async (tab) => {
    if (tab === "waiting payment") {
      fetchBookingWaitingPayment();
    }
    if (tab === "cancelled") {
      fetchBookingCancelled();
    }
    if (tab === "reserved") {
      fetchBookingReserved();
    }
    if (tab === "closed") {
      fetchBookingClose();
    }
  };

  const onChangeTab = (tab) => {
    query["page"] = 1;
    dispatch(setQueryBooking(query));
    setTab(tab);
  };

  const onShowMore = async () => {
    let pg = page + 1;
    query["page"] = pg;
    dispatch(setQueryBooking(query));
  };

  const applyDate = async () => {
    buttonRef.current.click();
    query["date"] = filters.date
      ? moment(filters.date).format("YYYY-MM-DD")
      : "";
    query["page"] = 1;
    query["customerName"] = customerName || "";
    query["invoiceCode"] = invoiceCode || "";
    query["courtId"] = choosenCourt?.id || "";
    dispatch(setQueryBooking(query));
    setDate(filters.date);
    fetchBooking(tab);
  };

  const onChangeDate = (value) => {
    if (value === null) {
      query["date"] = "";
      dispatch(setQueryBooking(query));
      buttonRef.current.click();
      setFilters({ ...filters, date: value });
      setDate(value);
      fetchBooking(tab);
    } else {
      setFilters({ ...filters, date: value });
    }
  };

  const openDetailBook = async ({ id, ...item }, status) => {
    setModalType(status);
    setItemDetail(item);
    if (status === "WAITING_FOR_PAYMENT") {
      fetchDetailBookingWaitingPayment(id);
    } else if (status === "CANCELLED") {
      fetchDetailBookingCancelled(id);
    } else if (status === "RESERVED") {
      fetchDetailBookingReserved(id);
    } else {
      fetchDetailBookingClose(id);
    }
    onToggle();
  };

  const onToggle = () => {
    setModal(!modal);
  };

  const onCancelBooking = (id) => {
    setModalAction({
      label: "Are you sure want to cancel this booking?",
      title: "Update Staff",
      click: () => cancelBooking(id),
    });
  };

  const cancelBooking = async (id) => {
    setModalAction(null);
    eventCancelBooking(id);
  };
  return (
    <React.Fragment>
      <div className="pt-7 pb-36 px-11">
        <p className="font-semibold text-2xl text-left">
          Booking Management
          <span className="text-medium text-gray-500 font-normal block mt-1">
            Manage all your existing booking or change status or add new booking
          </span>
        </p>
        <div className="flex mt-6">
          <div className="w-[70%]" />
          <div className="gap-3 grid grid-cols-2 w-[30%]">
            <Popover>
              <Popover.Button className="col-span-1 w-full">
                <Button
                  buttonRef={buttonRef}
                  className="text-sm w-full"
                  btnType="button"
                  label={`Show Filter ${
                    date && date !== null
                      ? "( " + moment(date).format("YYYY-MM-DD") + " )"
                      : ""
                  }`}
                  type="outline-primary"
                  isDisable={false}
                />
              </Popover.Button>
              <Popover.Panel className="z-10 mt-2">
                <form className="py-4 px-7 absolute min-w-[300px] rounded-lg bg-white border-[1px] border-gray-300 shadow">
                  <FormDate
                    label="Date"
                    placeholder="mm/dd/yyyy"
                    icon={IconCalendar}
                    iconPosition="left"
                    selectedDate={filters.date}
                    setDate={(value) => onChangeDate(value)}
                    control={control}
                    format="MM/dd/yyyy"
                    isClearable
                  />
                  <InputText
                    placeholder="Input customer name"
                    value={customerName}
                    setValue={setCustomerName}
                    type="text"
                    label="Customer Name"
                    labelClassName="mt-3"
                  />
                  <SelectOption
                    placeholder="Choose court"
                    options={allcourts}
                    setValue={setChoosenCourt}
                    value={choosenCourt}
                    label="Court"
                    labelClassName="mt-3"
                  />
                  <InputText
                    placeholder="Input invoice code"
                    value={invoiceCode}
                    setValue={setInvoiceCode}
                    type="text"
                    label="Invoice Code"
                    labelClassName="mt-3"
                  />
                  <div className="flex justify-between gap-3 mt-7">
                    <Button
                      label="Cancel"
                      type="outline-primary"
                      isDisable={false}
                      size="sm"
                      onClick={() => buttonRef.current.click()}
                    />
                    <Button
                      label="Apply"
                      type="primary"
                      isDisable={
                        !filters.date &&
                        !customerName &&
                        !choosenCourt &&
                        !invoiceCode
                      }
                      size="sm"
                      onClick={applyDate}
                    />
                  </div>
                </form>
              </Popover.Panel>
            </Popover>
            <Button
              btnType="button"
              label="+ Add New Booking"
              type="primary"
              className="text-sm"
              isDisable={false}
              onClick={() => navigate("/booking-management/add-booking")}
            />
          </div>
        </div>
        <Tab
          className="mt-9"
          tabs={["Waiting Payment", "Reserved", "Closed", "Cancelled"]}
          tab={tab}
          setTab={onChangeTab}
        />
        {list && list.length === 0 && (
          <div className="w-full flex flex-col justify-center items-center text-center mt-28">
            <Image src={BgNoData} height={175} width={175} alt="No Data" />
            <p className="italic text-xl text-gray-600">
              You haven't booked anything yet
            </p>
          </div>
        )}
        {list &&
          list.length > 0 &&
          list.map((item, i) => {
            if (tab === "waiting payment") {
              return (
                <CardBookingWaitingPayment
                  keyString={item.id}
                  onDetail={openDetailBook}
                  item={item}
                  key={i}
                />
              );
            }
            if (tab === "reserved") {
              return (
                <CardBookingReserved
                  keyString={item.id}
                  item={item}
                  key={i}
                  onDetail={openDetailBook}
                  onCancel={onCancelBooking}
                />
              );
            }
            if (tab === "cancelled") {
              return (
                <CardBookingCancelled
                  keyString={item.id}
                  onDetail={openDetailBook}
                  item={item}
                  key={i}
                />
              );
            }
            if (tab === "closed") {
              return (
                <CardBookingClose
                  keyString={item.id}
                  item={item}
                  key={i}
                  onDetail={openDetailBook}
                />
              );
            }
          })}
        {rows > page * pageSize && (
          <p
            onClick={onShowMore}
            className="cursor-pointer flex justify-center gap-5 text-[#A33E4F] text-lg font-semibold mt-10 tracking-[0.3px] hover:opacity-[0.5] transition-all duration-200"
          >
            Show more result
            <Image src={IconArrowRed} alt="icon arrow" width={30} height={24} />
          </p>
        )}
      </div>
      <ModalDetail
        onToggle={onToggle}
        item={detail}
        showForm={modal}
        modalType={modalType}
        expiryTime={itemDetail?.detailbooking?.booking.expiry_date}
        image={itemDetail?.image}
      />
      <ModalResponseBooking
        onResponse={responseBooking}
        item={detail}
        showForm={modalRes}
        onToggle={() => setModalRes(false)}
      />
      <Modal.Delete
        showModalDelete={modalAction ? true : false}
        label={modalAction?.label}
        onCancel={() => setModalAction(null)}
        title={modalAction?.title}
        onDelete={modalAction?.click}
      />
    </React.Fragment>
  );
};

export default BookingManagement;
