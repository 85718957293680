import React from "react";
import "./Radio.style.css";

const InputRadio = ({
  className = "",
  errorMessage,
  label = "",
  labelClassName = "",
  name,
  register,
  required = false,
  items,
  watch,
  col,
  value = "",
  setValue,
}) => {
  return (
    <React.Fragment>
      <div className={labelClassName}>
        {label && (
          <div className="mb-1">
            <label className={`${labelClassName} form-label tracking-[0.4px]`}>
              {label}{" "}
              {required && <span className="text-danger-default">*</span>}
            </label>
          </div>
        )}
        <div className="lg:grid grid-cols-12 gap-4">
          {items.map((e, i) => (
            <div
              className={`mt-3 lg:mt-0 bg-white rounded-lg w-full border-[1px] border-gray-400 ${
                (watch && name && watch(name) === e.value) || value === e.value
                  ? "card-radio-active"
                  : "card-radio"
              } ${`col-span-${col}`}`}
              key={i}
            >
              <label
                htmlFor={e.value}
                className={
                  (watch && name && watch(name) !== e.value) ||
                  value !== e.value
                    ? "label-radio"
                    : "label-radio-active"
                }
              >
                {e.label}
              </label>
              {register && name ? (
                <input
                  {...register(name)}
                  id={e.value}
                  type="radio"
                  value={e.value}
                  className={`${className} form-input-radio`}
                />
              ) : (
                <input
                  id={e.value}
                  type="radio"
                  checked={e?.value === value}
                  value={e.value}
                  className={`${className} form-input-radio`}
                  onChange={({ target }) => setValue(target.value)}
                />
              )}
            </div>
          ))}
        </div>
        {errorMessage && (
          <div className="form-input-message">
            <p className="text-[12px] my-auto tracking-[0.4px]">
              {errorMessage}
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default InputRadio;
