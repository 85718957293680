import moment from "moment";

export const IDRFormat = (num, prefix = null) => {
  const number_string = num
    .toString()
    .replace(/[^,\d]/g, "")
    .toString();
  const split = number_string.split(",");
  const sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  const ribuan = split[0].substr(sisa).match(/\d{3}/gi);
  let separator = null;

  if (ribuan) {
    separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] !== undefined ? `${rupiah},${split[1]}` : rupiah;
  return prefix === undefined ? rupiah : rupiah ? `Rp ${rupiah}` : "";
};

export const serializeParam = (obj = null) => {
  let resolveObj = {};
  Object.keys(obj).forEach((key) => {
    if (key) {
      resolveObj = { ...resolveObj, [key]: obj[key] };
    }
  });
  const params = new URLSearchParams(resolveObj);
  return params.toString();
};

export const deserializeParam = (str = "") => {
  const params = new URLSearchParams(str);
  const obj = {};

  params.forEach((value, key) => {
    obj[key] = value;
  });

  return obj;
};

export const commafy = (num) => {
  const str = num.toString().split(".");
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");
};

export const mergeByProperty = (target, source, prop) => {
  return source.map((sourceElement) => {
    let targetElement = target.find((targetElement) => {
      return sourceElement[prop] === targetElement[prop];
    });
    targetElement
      ? Object.assign(targetElement, sourceElement)
      : target.push(sourceElement);
  });
};

export const convertNumToArr = (num) => {
  const tempArr = [];
  for (let i = 0; i < num; i++) {
    tempArr.push(i);
  }
  return tempArr;
};

export const formatDateArr = (startDate, endDate, length) => {
  if (new Date(startDate) >= new Date(endDate)) return [];
  const date1 = moment(startDate);
  const date2 = moment(endDate);
  const arrNum = convertNumToArr(date2.diff(date1, "days"));
  let result = [
    ...arrNum.map((e) => new Date(moment(new Date(startDate)).add(e, "days"))),
    new Date(endDate),
  ];
  if (length) {
    const diff = length - result?.length;
    if (diff) {
      const arr = convertNumToArr(diff)?.map((_) => undefined);
      result = [...result, ...arr];
    }
  }
  return result;
};

export const capitalizeFirstLetter = (str) => {
  let splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};
