import { Popover } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../components/Button";
import SelectOption from "../../../components/Input/SelectOptions";

const PaymentFilter = ({ onSubmit }) => {
  const validationSchema = Yup.object().shape({
    status: Yup.object().nullable(),
    // settlement_status: Yup.object().nullable(),
    channel_code: Yup.object().nullable(),
    type: Yup.object().nullable(),
    // payment_method: Yup.object().nullable(),
  });

  const { control, watch } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onApply = () => {
    const statuses = watch("status")?.value || "";
    // const settlement_status = watch("settlement_status")?.value || "";
    const channel_categories = watch("channel_code")?.value || "";
    const types = watch("type")?.value || "";
    // const payment_method = watch("payment_method")?.value || "";
    // onSubmit({
    //   statuses,
    //   settlement_status,
    //   channel_categories,
    //   types,
    //   payment_method,
    // });
    onSubmit({
      statuses,
      channel_categories,
      types,
    });
  };
  return (
    <Popover className="z-[2] relative">
      <Popover.Button className="col-span-1 w-full">
        <Button
          className="text-sm w-full border-[1px] border-gray-300 !h-[45px] !bg-[#FCFDFE] !text-gray-400 !font-normal px-5"
          btnType="button"
          label="Show Filter"
          type="outline-gray"
          isDisable={false}
        />
      </Popover.Button>
      <Popover.Panel className="absolute !top-[50px]">
        <form className="py-4 px-7 absolute min-w-[300px] rounded-lg bg-white border-[1px] border-gray-300 shadow">
          <SelectOption
            labelClassName="mt-3"
            label="Type"
            name="type"
            isClearable
            placeholder="Choose type"
            isSearchable
            options={[
              { label: "DISBURSEMENT", value: "DISBURSEMENT" },
              { label: "PAYMENT", value: "PAYMENT" },
              { label: "TRANSFER", value: "TRANSFER" },
              { label: "REFUND", value: "REFUND" },
            ]}
            control={control}
          />
          <SelectOption
            labelClassName="mt-3"
            label="Status"
            name="status"
            isClearable
            placeholder="Choose status"
            isSearchable
            options={[
              { label: "PENDING", value: "PENDING" },
              { label: "SUCCESS", value: "SUCCESS" },
              { label: "FAILED", value: "FAILED" },
              { label: "VOIDED", value: "VOIDED" },
              { label: "REVERSED", value: "REVERSED" },
            ]}
            control={control}
          />
          <SelectOption
            labelClassName="mt-3"
            label="Channel"
            name="channel_code"
            isClearable
            placeholder="Choose channel"
            isSearchable
            options={[
              { label: "BANK", value: "BANK" },
              { label: "CASH", value: "CASH" },
              { label: "CARDS", value: "CARDS" },
              { label: "CARDLESS_CREDIT", value: "CARDLESS_CREDIT" },
              { label: "DIRECT_DEBIT", value: "DIRECT_DEBIT" },
              { label: "EWALLET", value: "EWALLET" },
              { label: "PAYLATER", value: "PAYLATER" },
              { label: "QR_CODE", value: "QR_CODE" },
              { label: "RETAIL_OUTLET", value: "RETAIL_OUTLET" },
              { label: "VIRTUAL_ACCOUNT", value: "VIRTUAL_ACCOUNT" },
              { label: "XENPLATFORM", value: "XENPLATFORM" },
            ]}
            control={control}
          />
          {/* <SelectOption
            labelClassName="mt-3"
            label="Settlement Status"
            name="settlement_status"
            isClearable
            placeholder="Choose settlement status"
            isSearchable
            options={[
              { label: "PENDING", value: "PENDING" },
              { label: "SETTLED", value: "SETTLED" },
              { label: "EARLY_SETTLED", value: "EARLY_SETTLED" },
            ]}
            control={control}
          /> */}
          {/* <SelectOption
            labelClassName="mt-3"
            label="Payment Method"
            name="payment_method"
            isClearable
            placeholder="Choose payment method"
            isSearchable
            options={[
              { label: "VIRTUAL_ACCOUNT", value: "VIRTUAL_ACCOUNT" },
              { label: "EWALLET", value: "EWALLET" },
              { label: "DIRECT_DEBIT", value: "DIRECT_DEBIT" },
              { label: "QR_CODE", value: "QR_CODE" },
              { label: "PAYLATER", value: "PAYLATER" },
              { label: "CARD", value: "CARD" },
            ]}
            control={control}
          /> */}
          <div className="flex justify-between gap-3 mt-7">
            <Button
              label="Cancel"
              type="outline-primary"
              isDisable={false}
              size="sm"
            />
            <Button
              label="Apply"
              type="primary"
              isDisable={false}
              size="sm"
              onClick={onApply}
            />
          </div>
        </form>
      </Popover.Panel>
    </Popover>
  );
};

export default PaymentFilter;
