import {
  SET_LIST_TIME_BOOKING,
  SET_ROWS_TIME_BOOKING,
  SET_QUERY_TIME_BOOKING,
  SET_MASTER_DAYS_TIME,
  SET_DETAIL_EXCEPTION,
  SET_MASTER_TIME_INACTIVE,
  SET_LIST_EXCEPTION,
} from "../../assets/constants/reducer.type";

export const setListTimeBooking = (payload) => {
  return { type: SET_LIST_TIME_BOOKING, payload };
};

export const setRowsTimeBooking = (payload) => {
  return { type: SET_ROWS_TIME_BOOKING, payload };
};

export const setQueryTimeBooking = (payload) => {
  return { type: SET_QUERY_TIME_BOOKING, payload };
};

export const setMasterDaysTime = (payload) => {
  return { type: SET_MASTER_DAYS_TIME, payload };
};

export const setDetailException = (payload) => {
  return { type: SET_DETAIL_EXCEPTION, payload };
};

export const setMasterTimeInactive = (payload) => {
  return { type: SET_MASTER_TIME_INACTIVE, payload };
};

export const setListException = (payload) => {
  return { type: SET_LIST_EXCEPTION, payload };
};
