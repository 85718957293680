import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Tab from "../../components/Tab";
import { useMyVenue } from "../../hooks/useMyVenue";
import FilterMembership from "./Filter";
import { ModalAdd, ModalApproval, ModalDetail, ModalHistory } from "./Modal";
import { TableMemberList, TableWaitingApprove } from "./Table";

const Membership = () => {
  const [tab, setTab] = useState("menunggu persetujuan");
  const [isShowApprove, setIsShowApprove] = useState();
  const [isShowReject, setIsShowReject] = useState();
  const [isShowAdd, setIsShowAdd] = useState(false);
  const [detail, setDetail] = useState();
  const [history, setHistory] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [note, setNote] = useState();

  const { fetchMembers, loading, updateMembership, fetchMemberHistory } =
    useMyVenue();

  useEffect(() => {
    onLoad({
      page,
      pageSize,
      status:
        tab === "menunggu persetujuan"
          ? "WAITING APPROVAL"
          : "ACTIVE,REJECTED,EXPIRED",
    });
  }, [tab, page, pageSize]);

  const onLoad = (params) => {
    fetchMembers(
      !params?.status
        ? {
            ...params,
            status:
              tab === "menunggu persetujuan"
                ? "WAITING APPROVAL"
                : "ACTIVE,REJECTED,EXPIRED",
          }
        : params
    );
  };

  const onUpdate = async () => {
    const updated = await updateMembership(
      isShowApprove?.id || isShowReject?.id,
      {
        status: !!isShowApprove ? "APPROVED" : "REJECTED",
        remarks: note,
      }
    );
    if (updated) {
      setDetail(undefined);
      setIsShowApprove(undefined);
      setIsShowReject(undefined);
    }
  };

  const chooseMemberHistory = async (id) => {
    await fetchMemberHistory(id);
    setHistory(id);
  };
  return (
    <React.Fragment>
      <ModalHistory history={history} setHistory={setHistory} />
      <ModalAdd isShow={isShowAdd} setIsShow={setIsShowAdd} tab={tab} />
      <ModalDetail data={detail} setData={setDetail} />
      <ModalApproval
        note={note}
        setNote={setNote}
        showForm={!!isShowApprove || !!isShowReject}
        isApprove={!!isShowApprove}
        onSubmit={onUpdate}
        onToggleModal={() => {
          setIsShowApprove(undefined);
          setIsShowReject(undefined);
        }}
      />
      <div className="py-7 px-11">
        <p className="font-semibold text-2xl text-left">
          Keanggotaan
          <span className="text-medium text-gray-500 font-normal block mt-1">
            Kelola semua daftar keanggotaan Anda dengan melakukan
            approval/rejection dan menambah anggota baru
          </span>
        </p>
        <div className="flex justify-end gap-4 mt-4">
          {tab === "daftar anggota" && <FilterMembership onApply={onLoad} />}
          <Button
            label="+ Tambah Anggota"
            type="primary"
            size="sm"
            isDisable={false}
            className="!w-fit !px-6"
            onClick={() => setIsShowAdd(true)}
          />
        </div>
        <Tab
          className="mt-9 mb-6"
          tabs={["Menunggu Persetujuan", "Daftar Anggota"]}
          tab={tab}
          setTab={setTab}
        />
        {tab === "menunggu persetujuan" ? (
          <TableWaitingApprove
            setIsShowApprove={setIsShowApprove}
            setIsShowReject={setIsShowReject}
            setDetail={setDetail}
            loading={loading}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        ) : (
          <TableMemberList
            setDetail={setDetail}
            setHistory={chooseMemberHistory}
            loading={loading}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Membership;
