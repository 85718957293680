import { toast } from "react-toastify";

export const toastNotif = ({
  message,
  type = "default" | "error" | "success" | "info" | "warning",
}) => {
  return toast((message || "Internal server error")?.toUpperCase(), {
    type,
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
