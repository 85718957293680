import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { BASE_URL_PREVIEW_IMG } from "../../../assets/constants/constants";
import Button from "../../../components/Button";
import SelectOption from "../../../components/Input/SelectOptions";
import InputText from "../../../components/Input/Text";
import InputTextarea from "../../../components/Input/Textarea";
import { UploadImageBanner } from "../../../components/Input/Upload";
import Modal from "../../../components/Modal";
import { useCourt } from "../../../hooks/useCourt";
import { retrieveLocalStorageItem } from "../../../utils/localStorage";

const FormCourt = ({ showForm, setShowForm, data }) => {
  /* Local State */
  const [banners, setBanners] = useState();
  const [confirmEdit, setConfirmEdit] = useState(false);
  const [tempId, setTempId] = useState();

  /* Hooks */
  const { createCourt, updateCourt, loading } = useCourt();

  /* Redux */
  const { facilities } = useSelector((state) => state.common);

  const validationSchema = Yup.object().shape({
    facility: Yup.object().nullable().required("Facility required"),
    courtName: Yup.string().required("Court name required"),
    courtDescription: Yup.string().required("Court description required"),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!loading) {
      onCloseForm();
    }
  }, [loading]);

  useEffect(() => {
    if (showForm && !data) {
      reset();
      setBanners();
      setValue("facility", null);
    }
  }, [showForm, data]);

  useEffect(() => {
    if (data) {
      onSetValue();
    }
  }, [data]);

  const user = useMemo(() => {
    return JSON.parse(retrieveLocalStorageItem("info"));
  }, [retrieveLocalStorageItem]);

  const onCloseForm = () => {
    setShowForm(false);
    setBanners();
    setValue("facility", null);
    reset();
  };

  const onSubmit = (payload) => {
    const tempData = {
      ...payload,
      facilityId: payload?.facility?.value,
      facility: payload?.facility?.label,
      base64: banners ? banners[0]?.base64 || "" : "",
      idVenue: user?.venueId,
      description: payload?.description || payload?.courtDescription,
    };
    if (!data) {
      return createCourt(tempData);
    }
    return updateCourt(data?.id, tempData);
  };

  const onSetValue = () => {
    setTempId(data?.id);
    setValue("courtName", data?.courtName);
    setValue("courtDescription", data?.description);
    setValue("facility",{ label: data?.facility, value: data?.facilityId });
    setBanners([
      {
        src: `${BASE_URL_PREVIEW_IMG}/${data?.pathName}/${data?.imageName}`,
        name: data?.imageName,
      },
    ]);
  };

  const onSubmitEdit = () => {
    const payload = {
      facilityId: watch("facility")?.value,
      facility: watch("facility")?.label,
      courtName: watch("courtName"),
      courtDescription: watch("courtDescription"),
      base64: banners ? banners[0]?.base64 : "",
      description: watch("courtDescription"),
    };
    setConfirmEdit(false);
    setShowForm(false);
    return updateCourt(tempId, payload);
  };

  return (
    <React.Fragment>
      <Modal.Delete
        showModalDelete={confirmEdit}
        label="Are you sure want to change this court?"
        onCancel={() => setConfirmEdit(false)}
        title="Change Court"
        onDelete={onSubmitEdit}
        className="w-full"
      />
      <Modal.Common
        showModal={showForm}
        title={`${data ? "Edit" : "Add"} Court`}
        description="Add new court by complete form information below"
        closeModal={() => setShowForm(false)}
      >
        <form
          className="w-[757px] px-10 pb-10"
          onSubmit={handleSubmit(onSubmit)}
        >
          <UploadImageBanner
            setImages={setBanners}
            images={banners}
            text="Upload Image by click icon above"
            height={168}
            textClassName="top-[73%] right-[34.5%]"
          />
          <SelectOption
            name="facility"
            label="Facility"
            labelClassName="mt-3"
            placeholder="Choose facility"
            required
            options={facilities}
            control={control}
            errorMessage={errors?.facility?.message?.toString()}
          />
          <InputText
            labelClassName="mt-3"
            name="courtName"
            required
            type="text"
            label="Court Name"
            placeholder="Insert court name"
            register={register}
            errorMessage={errors?.courtName?.message?.toString()}
          />
          <InputTextarea
            name="courtDescription"
            label="Court Description"
            placeholder="Insert description"
            labelClassName="mt-3"
            required
            register={register}
            errorMessage={errors?.courtDescription?.message?.toString()}
            textLength={watch("courtDescription")?.length}
            maxLength={120}
          />
          <div className="flex justify-end gap-4">
            <div className="w-[180%]" />
            {data && (isDirty || (banners && banners[0]?.base64)) ? (
              <Button
                className="mt-8 tracking-wider font-[14px] w-40"
                onClick={() => setConfirmEdit(true)}
                label="Save"
                type="primary"
                btnType="button"
                isDisable={
                  (!isValid && !banners?.length) || isSubmitting || loading
                }
                isSubmit={(isSubmitting && isValid) || loading}
              />
            ) : (
              <Button
                className="mt-8 tracking-wider font-[14px] w-40"
                label="Save"
                type="primary"
                btnType="submit"
                isDisable={
                  (!isValid && !banners?.length) || isSubmitting || loading
                }
                isSubmit={(isSubmitting && isValid) || loading}
              />
            )}
            <Button
              className="mt-8 tracking-wider font-[14px] w-40"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={loading}
              onClick={onCloseForm}
            />
          </div>
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default FormCourt;
