import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getDistricts } from "../core/GET_Districts";
import { getFacilities } from "../core/GET_Facilities";
import { getProvinces } from "../core/GET_Provinces";
import { getRegencies } from "../core/GET_Regencies";
import { getVillages } from "../core/GET_Villages";
import {
  setDistricts,
  setFacilities,
  setProvinces,
  setRegencies,
  setVillages,
} from "../stores/actions/common.action";
import { toastNotif } from "../utils/toastNotif";

export const useCommon = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  /* get provinces */
  const fetchProvinces = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getProvinces();

      if (result) {
        dispatch(setProvinces(result));
      } else {
        dispatch(setProvinces());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get regencies */
  const fetchRegencies = async (param) => {
    setLoading(true);
    try {
      const { success, message, result } = await getRegencies(param);

      if (result) {
        dispatch(setRegencies(result));
      } else {
        dispatch(setRegencies());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get districts */
  const fetchDistricts = async (param) => {
    setLoading(true);
    try {
      const { success, message, result } = await getDistricts(param);

      if (result) {
        dispatch(setDistricts(result));
      } else {
        dispatch(setDistricts());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get villages */
  const fetchVillages = async (param) => {
    setLoading(true);
    try {
      const { success, message, result } = await getVillages(param);

      if (result) {
        dispatch(setVillages(result));
      } else {
        dispatch(setVillages());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get facilities */
  const fetchFacilities = async () => {
    setLoading(true);
    try {
      const { message, result } = await getFacilities();

      if (result) {
        dispatch(
          setFacilities(
            result?.map((e) => ({
              ...e,
              value: e?.id,
              label: e?.typeName,
            }))
          )
        );
      } else {
        dispatch(setFacilities());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  return {
    error,
    message,
    loading,
    fetchProvinces,
    fetchDistricts,
    fetchVillages,
    fetchRegencies,
    fetchFacilities,
  };
};
