import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBookingCart } from "../core/DELETE_Cart";
import { getBookingCart } from "../core/GET_BookingCart";
import { getBookings } from "../core/GET_Bookings";
import { getBookingsAllHours } from "../core/GET_BookingsAllHour";
import { getCustomers } from "../core/GET_Customers";
import { getDashboardCalendar } from "../core/GET_DashboardCalendar";
import { getDetailBookingCancelled } from "../core/GET_DetailBookingCancelled";
import { getDetailBookingClose } from "../core/GET_DetailBookingClose";
import { getDetailBookingReserved } from "../core/GET_DetailBookingReserved";
import { getDetailBookingWaitingPayment } from "../core/GET_DetailBookingWaitingPayment";
import { getBookingCancelled } from "../core/GET_MyBookingCancelled";
import { getBookingClose } from "../core/GET_MyBookingClose";
import { getBookingReserved } from "../core/GET_MyBookingReserved";
import { getBookingWaitingPayment } from "../core/GET_MyBookingWaitingPayment";
import { getTotalCart } from "../core/GET_TotalCart";
import { postAddDeleteCart } from "../core/POST_AddDeleteCart";
import { postBookingCart } from "../core/POST_BookingCart";
import { postCheckoutBooking } from "../core/POST_CheckoutBooking";
import { postCustomer } from "../core/POST_Customer";
import { putCancelBooking } from "../core/PUT_MyBookingCancel";
import { putResponseBooking } from "../core/PUT_ResponseBooking";
import {
  setBookings,
  setBookingsAllHours,
  setCutomers,
  setDashboardCalendar,
  setDetailBooking,
  setListBooking,
  setListCart,
  setRowsBooking,
  setTotalCart,
} from "../stores/actions/mybooking.action";
import { toastNotif } from "../utils/toastNotif";

export const useMyBooking = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const navigate = useNavigate();
  const { query, list } = useSelector((state) => state.mybook);

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  const { page } = query;
  /* get my venue detail */
  const fetchBookingWaitingPayment = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getBookingWaitingPayment(
        query
      );

      if (success && result) {
        const { count, rows } = result || {};
        const listData = page === 1 ? rows : [...list, ...rows];
        dispatch(setRowsBooking(count));
        dispatch(setListBooking(listData));
      } else {
        dispatch(setListBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchDetailBookingWaitingPayment = async (id) => {
    setLoading(true);
    try {
      const { success, message, result } = await getDetailBookingWaitingPayment(
        id
      );

      if (success && result) {
        dispatch(setDetailBooking(result));
      } else {
        dispatch(setDetailBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchBookingCancelled = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getBookingCancelled(query);

      if (success && result) {
        const { count, rows } = result || {};
        const listData = page === 1 ? rows : [...list, ...rows];
        dispatch(setRowsBooking(count));
        dispatch(setListBooking(listData));
      } else {
        dispatch(setListBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchDetailBookingCancelled = async (id) => {
    setLoading(true);
    try {
      const { success, message, result } = await getDetailBookingCancelled(id);

      if (success && result) {
        dispatch(setDetailBooking(result));
      } else {
        dispatch(setDetailBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchBookingReserved = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getBookingReserved(query);

      if (success && result) {
        const { count, rows } = result || {};
        const listData = page === 1 ? rows : [...list, ...rows];
        dispatch(setRowsBooking(count));
        dispatch(setListBooking(listData));
      } else {
        dispatch(setListBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchDetailBookingReserved = async (id) => {
    setLoading(true);
    try {
      const { success, message, result } = await getDetailBookingReserved(id);

      if (success && result) {
        dispatch(setDetailBooking(result));
      } else {
        dispatch(setDetailBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchBookingClose = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getBookingClose(query);

      if (success && result) {
        const { count, rows } = result || {};
        const listData = page === 1 ? rows : [...list, ...rows];
        dispatch(setRowsBooking(count));
        dispatch(setListBooking(listData));
      } else {
        dispatch(setListBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchDetailBookingClose = async (search) => {
    setLoading(true);
    try {
      const { success, message, result } = await getDetailBookingClose(search);

      if (success && result) {
        dispatch(setDetailBooking(result));
      } else {
        dispatch(setDetailBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const searchCustomer = async (search) => {
    setLoading(true);
    try {
      const { success, message, result } = await getCustomers(search);

      if (success && result) {
        dispatch(setCutomers(result));
      } else {
        dispatch(setCutomers());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const createCustomer = async (payload) => {
    setLoading(true);
    try {
      const { success, message, result } = await postCustomer(payload);

      if (success && result) {
        toastNotif({
          message: message || "Create customer successfully",
          type: "success",
        });
      } else {
        setMessage("Create customer failed");
        toastNotif({
          message: message || "Create customer failed",
          type: "error",
        });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchBooking = async (courtId, date, customerId, courtName) => {
    setLoading(true);
    try {
      const { success, message, result } = await getBookings(
        courtId,
        date,
        customerId
      );

      if (success && result) {
        const data = result?.map((e) => ({
          ...e,
          customerId,
          courtId,
          courtName,
          openHoursId: e?.id,
          date,
        }));
        dispatch(setBookings(data));
      } else {
        dispatch(setBookings());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchBookingAllHours = async (queryParams) => {
    setLoading(true);
    try {
      const { success, message, result } = await getBookingsAllHours(
        queryParams
      );
      if (success && result) {
        dispatch(setBookingsAllHours(result));
      } else {
        dispatch(setBookingsAllHours());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const fetchBookingCart = async (customerId) => {
    setLoading(true);
    dispatch(setBookings());
    try {
      const { success, message, result } = await getBookingCart(customerId);

      if (success && result) {
        dispatch(setListCart(result));
      } else {
        dispatch(setBookings());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const addToCart = async (
    payload,
    filter,
    { courtId, date, customerId, courtName },
    isAddDelete = false
  ) => {
    setLoading(true);
    try {
      const { success, message } =
        isAddDelete && !payload?.length
          ? await postAddDeleteCart(payload)
          : await postBookingCart(payload);
      if (success) {
        dispatch(setBookingsAllHours(undefined));
        await eventTotalCart(filter.customerId);
        await fetchBookingAllHours(filter, {
          courtId,
          courtName,
          customerId,
          date,
        });
        toastNotif({
          message: message || "Add to cart successfully",
          type: "success",
        });
      } else {
        setMessage("Add to cart failed");
        toastNotif({
          message: message || "Add to cart failed",
          type: "error",
        });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const checkoutBooking = async (payload) => {
    setLoading(true);
    try {
      const { success, result, message } = await postCheckoutBooking(payload);
      if (success) {
        if (result?.invoice_url) {
          window.open(result?.invoice_url);
        }
        navigate("/booking-management", { replace: true });
        toastNotif({
          message: message || "Checkout booking successfully",
          type: "success",
        });
      } else {
        setMessage("Checkout booking failed");
        toastNotif({
          message: message || "Checkout booking failed",
          type: "error",
        });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const responseBooking = async (id, data) => {
    setLoading(true);
    try {
      const { success, result, message } = await putResponseBooking(id, data);
      if (success) {
        await fetchBookingCancelled();
        toastNotif({
          message: message || "Response booking successfully",
          type: "success",
        });
      } else {
        setMessage("Response booking failed");
        toastNotif({
          message: message || "Response booking failed",
          type: "error",
        });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const eventCancelBooking = async (id) => {
    setLoading(true);
    try {
      const { success, message } = await putCancelBooking(id);
      if (success) {
        await fetchBookingReserved();
        toastNotif({
          message: message || "Cancel booking successfully",
          type: "success",
        });
      } else {
        setMessage("Cancel booking failed");
        toastNotif({
          message: message || "Cancel booking failed",
          type: "error",
        });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const eventTotalCart = async (customerId) => {
    try {
      const { success, message, rows } = await getTotalCart(customerId);
      if (success) {
        dispatch(setTotalCart(rows));
      } else {
        dispatch(setTotalCart(0));
        setMessage("Get total cart failed");
        toastNotif({
          message: message || "Get total cart failed",
          type: "error",
        });
      }
    } catch (err) {
      dispatch(setTotalCart(0));
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
    }
  };

  const eventDeleteCartBooking = async (id, customerId) => {
    setLoading(true);
    try {
      const { success, message } = await deleteBookingCart(id);
      if (success) {
        await fetchBookingCart(customerId);
        toastNotif({
          message: message || "Delete cart booking successfully",
          type: "success",
        });
      } else {
        setMessage("Delete cart booking failed");
        toastNotif({
          message: message || "Delete cart booking failed",
          type: "error",
        });
      }
      setLoading(false);
      return true;
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      setLoading(false);
      return false;
    }
  };

  const fetchDashboardCalendar = async (queryParams) => {
    setLoading(true);
    try {
      const { success, message, result } = await getDashboardCalendar(
        queryParams
      );
      if (success && result) {
        dispatch(setDashboardCalendar(result));
      } else {
        dispatch(setDashboardCalendar());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };
  return {
    error,
    message,
    loading,
    fetchBookingWaitingPayment,
    fetchBookingReserved,
    fetchDetailBookingReserved,
    fetchBookingClose,
    fetchDetailBookingWaitingPayment,
    fetchDetailBookingClose,
    searchCustomer,
    createCustomer,
    fetchBooking,
    fetchBookingAllHours,
    addToCart,
    checkoutBooking,
    fetchBookingCancelled,
    fetchDetailBookingCancelled,
    responseBooking,
    eventCancelBooking,
    eventTotalCart,
    fetchBookingCart,
    eventDeleteCartBooking,
    fetchDashboardCalendar,
  };
};
