import api from "./api";

export const getMembers = async (queryParam) => {
  return api
    .get({
      endpoint: "/member/by-owner",
      queryParam,
      port: process.env.REACT_APP_PORT_VENUE,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
