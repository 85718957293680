import React from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import InputTextarea from "../../components/Input/Textarea";
import SelectOption from "../../components/Input/SelectOptions";

const ResponseBooking = ({
  showForm,
  onToggle,
  item,
  onResponse
}) => {

  const validationSchema = Yup.object().shape({
    status: Yup.object().required("Facility required"),
    note: Yup.string(),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = async (data) => {
    let { status, note } = data;
    status = status.value === 'Approved' ? 'APPROVED' : 'REJECTED';
    onResponse(item.id, { status, note });
    onToggle();
  }
  return (
    <React.Fragment>
      <Modal.Common
        showModal={showForm}
        title="Response Booking"
        description=""
        closeModal={onToggle}
      >
        <div className="w-[757px] px-10 pb-10">
          <div className="block text-xl font-normal text-dark-default">
            <div className="grid grid-cols-10 w-full mt-2">
              <div className="col-span-4">
                <p className="py-2">Bank Name</p>
                <p className="py-2">Bank Account Holder</p>
                <p className="py-2">Bank Account Number</p>
                <p className="py-2">Proof of Transfer</p>
              </div>
              <div className="col-span-6">
                <p className="py-2">: {item?.booking?.fromBankName}</p>
                <p className="py-2">: {item?.booking?.fromBankAccountHolder}</p>
                <p className="py-2">: {item?.booking?.fromBankAccountNumber}</p>
                <p className="py-2">: <a className="text-blue-500" href={item?.pathProofOfTransfer} target="_blank">open image</a></p>
              </div>
            </div>
          </div>
          <SelectOption
            name="status"
            label="Status"
            labelClassName="mt-3"
            placeholder="Choose Status"
            required
            options={[{value: 'Approved', label: 'Approved'}, {value: 'Rejected', label: 'Rejected'}]}
            control={control}
            errorMessage={errors?.status?.message?.toString()}
          />
          <InputTextarea
            name="note"
            label="Note"
            placeholder="Insert note"
            labelClassName="mt-3"
            required
            register={register}
            errorMessage={errors?.note?.message?.toString()}
            textLength={watch("note")?.length}
            maxLength={500}
          />
          <div className="flex justify-end gap-4">
            <div className="w-[180%]" />
            <Button
              className="mt-8 tracking-wider font-[14px] w-40"
              label="Submit"
              type="primary"
              btnType="button"
              isDisable={!isValid}
              onClick={handleSubmit(onSubmit)}
            />
            <Button
              className="mt-8 tracking-wider font-[14px] w-40"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={() => {
                reset()
                onToggle()
              }}
            />
          </div>
        </div>
      </Modal.Common>
    </React.Fragment>
  );
};

export default ResponseBooking;
