import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import InputText from "../../../components/Input/Text";
import InputTextarea from "../../../components/Input/Textarea";
import Permission from "./Permission";

const StaffMForm = ({ showModal, setShowModal, isUpdate }) => {
  /* Local State */
  const [permissionTabs, setPermissionTab] = useState([
    {
      id: 1,
      name: "Booking",
      active: true,
      items: [
        { id: "1A", name: "Create Booking", isGrant: true },
        { id: "1B", name: "View Booking", isGrant: false },
        { id: "1C", name: "Update Booking", isGrant: true },
      ],
    },
    {
      id: 2,
      name: "Venue",
      active: false,
      items: [
        { id: "2A", name: "Create Venue", isGrant: false },
        { id: "2B", name: "View Venue", isGrant: true },
      ],
    },
    {
      id: 3,
      name: "Court",
      active: false,
      items: [
        { id: "3A", name: "Create Court", isGrant: false },
        { id: "3B", name: "View Court", isGrant: true },
      ],
    },
    {
      id: 4,
      name: "Bank",
      active: false,
      items: [
        { id: "4A", name: "Create Bank", isGrant: false },
        { id: "4B", name: "View Bank", isGrant: true },
      ],
    },
  ]);

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("Role name required"),
    description: Yup.string()
      .max(120, "Descrption must be at most 120 characters")
      .required("Description required"),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onCloseForm = () => {
    setShowModal(false);
    reset();
  };

  return (
    <React.Fragment>
      <Modal.Common
        showModal={showModal}
        title="Add Role"
        description="Add new Role by complete form information below"
        closeModal={onCloseForm}
      >
        <form className="w-[55em]">
          <div className="px-10 py-2">
            <InputText
              name="roleName"
              required
              type="text"
              label="Role Name"
              placeholder="Insert role name"
              register={register}
              errorMessage={errors?.roleName?.message?.toString()}
            />
            <InputTextarea
              labelClassName="mt-3"
              name="description"
              label="Description"
              placeholder="Inset description"
              required
              register={register}
              errorMessage={errors?.description?.message?.toString()}
              textLength={watch("description")?.length}
              maxLength={120}
            />
            <div className="mt-4">
              <Permission tabs={permissionTabs} setTabs={setPermissionTab} />
            </div>
          </div>
          <div className="flex relative -right-[400px] px-10 gap-5 pb-8 w-[30em]">
            <Button
              className="mt-10 tracking-wider font-[14px]"
              label="Save"
              type="primary"
              btnType="submit"
              isDisable={!isValid || isSubmitting}
              isSubmit={isSubmitting && isValid}
            />
            <Button
              className="mt-10 tracking-wider font-[14px]"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={onCloseForm}
            />
          </div>
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default StaffMForm;
