import api from "./api";

export const deleteGallery = async (id) => {
  return api
    .delete({
      port: process.env.REACT_APP_PORT_IMG,
      endpoint: `/own/images/venue/${id}`,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
