import api from "./api";

export const putTimeBookingStatus = async (id, body) => {
  return api
    .put({
      endpoint: `/all/hour/${id}`,
      port: process.env.REACT_APP_PORT_OPENHOUR,
      body,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
