import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { patchCheckStatusPin } from "../core/generalsettings/PATCH_CheckStatusPin";
import { postCreateOrUpdatePin } from "../core/generalsettings/POST_CreateOrUpdatePin";
import { patchValidationPin } from "../core/generalsettings/PATCH_ValidationPin";
import {
    setStatusPin
} from "../stores/actions/generalsetting.action";
import { toastNotif } from "../utils/toastNotif";

export const useGeneralSetting = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  const checkStatusPin = async () => {
    setLoading(true);
    try {
      const { success, message, status_PIN } = await patchCheckStatusPin();

      if (success && status_PIN) {
        dispatch(setStatusPin(status_PIN));
        setLoading(false);
        return setStatusPin;
      } else {
        dispatch(setStatusPin());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
        setLoading(false);
        return null;
      }
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      setLoading(false);
      return null;
    }
  };

  const createOrUpdatePin = async (payload) => {
    setLoading(true);
    try {
      const { success, message } = await postCreateOrUpdatePin(payload);
      if (success) {
        checkStatusPin();
        toastNotif({ message: "Success update PIN", type: "success" });
        setLoading(false);
        return true;
      } else {
        dispatch(setStatusPin());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
        setLoading(false);
        return null;
      }
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      setLoading(false);
      return null;
    }
  };

  const validationPin = async (payload) => {
    setLoading(true);
    try {
      const { success, message, status_PIN } = await patchValidationPin(payload);
      if (success) {
        setLoading(false);
        return status_PIN;
      } else {
        setLoading(false);
        throw new Error(message || "Data not found");
      }
    } catch (err) {
      setLoading(false);
      throw new Error(err?.message || "Internal server error!");
    }
  };

  
  return {
    error,
    message,
    loading,
    checkStatusPin,
    createOrUpdatePin,
    validationPin
  };
};
