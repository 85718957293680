import React from "react";
import InputText from "../../../components/Input/Text";

const Phone = ({ register, errors }) => {
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Phone Number</p>
          <p className="font-normal text-medium">
            Please provide professional contact phone number
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="telephone"
              required
              type="text"
              placeholder="Insert phone contact"
              register={register}
              errorMessage={errors?.telephone?.message?.toString()}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">WhatsApp Number</p>
          <p className="font-normal text-medium">
            Please provide WhatsApp Number that can be contacted by user for
            customer service dialing
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputText
              name="contactWa"
              required
              type="text"
              placeholder="Insert whatsapp number"
              register={register}
              errorMessage={errors?.contactWa?.message?.toString()}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Phone;
