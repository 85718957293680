import React, { useEffect } from "react";
import Button from "../../../components/Button";
import InputTextarea from "../../../components/Input/Textarea";
import Modal from "../../../components/Modal";

const Approval = ({
  showForm,
  isApprove,
  onToggleModal,
  note,
  setNote,
  onSubmit,
}) => {
  useEffect(() => {
    if (showForm) setNote(undefined);
  }, [showForm]);
  return (
    <Modal.Common
      showModal={showForm}
      title={`Apakah Anda yakin untuk ${
        isApprove ? "menerima" : "menolak"
      } pengguna ini menjadi anggota?`}
      closeModal={onToggleModal}
    >
      <div className="w-full px-10 pb-10">
        <InputTextarea
          setValue={setNote}
          value={note}
          placeholder="Tulis catatan"
        />
        <div className="flex justify-start w-full gap-4">
          <Button
            className="mt-8 tracking-wider font-[14px]"
            label="Cancel"
            type="outline-primary"
            size="sm"
            btnType="button"
            isDisable={false}
            onClick={onToggleModal}
          />
          <Button
            className="mt-8 tracking-wider font-[14px]"
            size="sm"
            label="Ya"
            type="primary"
            btnType="button"
            isDisable={false}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal.Common>
  );
};

export default Approval;
