import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React from "react";
import { IconWarning } from "../../../assets/images";

const VenueOthers = ({ errors, setValue, value }) => {
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%]">
          <p className="font-bold text-lg">Privacy Policy</p>
          <p className="font-normal text-medium">
            Please provide the privacy policy for your venue
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <CKEditor
              editor={ClassicEditor}
              config={{
                toolbar: [
                  "undo",
                  "redo",
                  "|",
                  "bold",
                  "italic",
                  "|",
                  "numberedList",
                  "bulletedList",
                  "|",
                  "indent",
                  "outdent",
                ],
              }}
              data={value}
              onChange={(_event, editor) => {
                setValue(editor.getData());
              }}
            />
            {errors?.privacyPolicy?.message?.toString() && (
              <div className="form-input-message">
                <img
                  src={IconWarning}
                  alt="icon warning"
                  className="max-h-[15px]"
                />
                <p>{errors?.privacyPolicy?.message?.toString()}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VenueOthers;
