import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { useMyVenue } from "../../../hooks/useMyVenue";
import { CardHistory } from "../Card";

const History = ({ history, setHistory }) => {
  const { memberHistory, detail } = useSelector((state) => state.myvenue);

  const { myVenueDetail } = useMyVenue();

  useEffect(() => {
    if (history && !detail) {
      myVenueDetail();
    }
  }, [history, detail]);
  return (
    <Modal.Common
      showModal={!!history}
      title="Riwayat Anggota"
      closeModal={() => setHistory(undefined)}
    >
      {memberHistory?.data?.length ? (
        memberHistory?.data?.map((e) => (
          <div className="w-[512px] px-10 pb-10" key={e?.id}>
            <p className="text-regular text-gray-500 px-2 py-1 w-fit bg-gray-150 rounded-md">
              {moment(new Date(e?.createdAt)).format("DD MMMM yyyy")}
            </p>
            <CardHistory {...e} venueName={detail?.venueName} />
          </div>
        ))
      ) : (
        <p className="text-center italic text-gray-400 text-small pt-4 pb-12 px-36">
          -- Data not found --
        </p>
      )}
    </Modal.Common>
  );
};

export default History;
