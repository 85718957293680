import React from "react";
import { Controller } from "react-hook-form";

const Toggle = ({
  className = "",
  label = "",
  id = 1,
  checked = false,
  setChecked,
  control = null,
  name = "",
}) => {
  return (
    <React.Fragment>
      {control && name ? (
        <Controller
          control={control}
          name={name}
          render={({ field: { ref, ...field } }) => (
            <label
              className="relative inline-flex items-center mr-5 cursor-pointer"
              for={name}
              key={name}
            >
              <input
                {...field}
                type="checkbox"
                className="sr-only peer"
                checked={field.value}
                id={name}
              />
              <div
                className={`w-11 h-5 bg-[#9EA3AF] rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-primary-100 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-none after:content-[''] after:absolute after:-top-1 after:-left-[5px] ${
                  field.value ? "after:bg-primary-lighter" : "after:bg-gray-200"
                } after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-primary-250 after:shadow-xl`}
              ></div>
              {label && (
                <label
                  for={name}
                  className={`${className} cursor-pointer ml-3 text-sm font-medium text-gray-900 dark:text-gray-300`}
                >
                  {label}
                </label>
              )}
            </label>
          )}
        />
      ) : (
        <label
          className="relative inline-flex items-center mr-5 cursor-pointer"
          for={id}
          key={id}
        >
          <input
            type="checkbox"
            className="sr-only peer"
            checked={checked}
            id={id}
            onChange={setChecked}
          />
          <div
            className={`w-11 h-5 bg-[#9EA3AF] rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-primary-100 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-none after:content-[''] after:absolute after:-top-1 after:-left-[5px] ${
              checked ? "after:bg-primary-lighter" : "after:bg-gray-200"
            } after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-primary-250 after:shadow-xl`}
          ></div>
          {label && (
            <label
              for={id}
              className={`${className} cursor-pointer ml-3 text-sm font-medium text-gray-900 dark:text-gray-300`}
            >
              {label}
            </label>
          )}
        </label>
      )}
    </React.Fragment>
  );
};

export default Toggle;
