import api from "./api";

export const postMyBankAccount = async (data) => {
  return api
    .post({
      endpoint: "/bank-account",
      port: process.env.REACT_APP_PORT_VENUE,
      body: data
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
