import React, { useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import { IconWarning } from "../../../assets/images";
import Image from "../../Image";
import "./Date.style.css";

const FormDate = ({
  selectedDate = null,
  startDate = null,
  endDate = null,
  setDate,
  setStartDate,
  setEndDate,
  label = "",
  labelClassName = "",
  errorMessage = false,
  placeholder,
  format = "dd-MM-yyyy",
  minDate = null,
  icon = null,
  iconPosition = "right",
  showMonthDropdown = false,
  showYearDropdown = false,
  selectsRange = false,
  isClearable = false,
  monthsShown = 1,
  required = false,
  iconType = "image",
  iconClassName = "",
  iconOnClick = null,
  disabled = false,
  className = "",
  control = null,
  name = "",
  showTimeSelect = false,
  showTimeSelectOnly = false,
  timeIntervals = 0,
  timeCaption = "Time",
  /*
    "SUNDAY" || 
    "MONDAY" || 
    "TUESDAY" || 
    "WEDNESDAY" || 
    "THURSDAY" || 
    "FRIDAY" || 
    "SATURDAY"
  */
  filterDay = undefined,
}) => {
  const isFilterDay = useCallback(
    (date) => {
      const day = date.getDay();
      switch (filterDay) {
        case "SUNDAY":
          return day === 0;
        case "MONDAY":
          return day === 1;
        case "TUESDAY":
          return day === 2;
        case "WEDNESDAY":
          return day === 3;
        case "THURSDAY":
          return day === 4;
        case "FRIDAY":
          return day === 5;
        case "SATURDAY":
          return day === 6;
        default:
          return (
            day === 0 ||
            day === 1 ||
            day === 2 ||
            day === 3 ||
            day === 4 ||
            day === 5 ||
            day === 6
          );
      }
    },
    [filterDay]
  );
  return (
    <React.Fragment>
      {label && (
        <div className="mb-[4px]">
          <label className={`${labelClassName} form-label`}>
            {label} {required && <span className="text-danger-default">*</span>}
          </label>
        </div>
      )}
      {control && !setDate ? (
        <span className="block relative customDatePickerWidth">
          <Controller
            name={name || "datepicker"}
            control={control}
            render={({ field }) => (
              <DatePicker
                filterDate={isFilterDay}
                placeholderText={placeholder}
                onChange={(date) => field.onChange(date)}
                selected={field.value}
                dateFormat={format}
                className={`${
                  disabled ? "cursor-not-allowed" : ""
                } ${className} ${
                  errorMessage
                    ? `form-input${
                        icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                      }-error`
                    : `form-input${
                        icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                      }`
                }`}
                wrapperClassName="w-100"
                readOnly={disabled}
                disabled={disabled}
                minDate={minDate}
                showYearPicker={format === "yyyy" || false}
                showMonthYearPicker={
                  format === "MMMM yyyy" || format === "mm yyyy" || false
                }
                showMonthDropdown={showMonthDropdown}
                showYearDropdown={showYearDropdown}
                selectsRange={selectsRange}
                monthsShown={monthsShown}
                isClearable={isClearable}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeIntervals={timeIntervals}
                timeCaption={timeCaption}
              />
            )}
          />
          {icon && iconPosition && iconType === "image" && (
            <span className={`form-input-${iconPosition}-icon`}>
              <img
                alt={`input date`}
                src={icon}
                className={iconClassName}
                onClick={iconOnClick}
              />
            </span>
          )}
          {icon && iconPosition && iconType === "string" && (
            <span
              className={`form-input-${iconPosition}-icon ${iconClassName}`}
            >
              {icon}
            </span>
          )}
        </span>
      ) : selectsRange ? (
        <span className="block relative customDatePickerWidth">
          <DatePicker
            filterDate={isFilterDay}
            dateFormat={format}
            onChange={(dates) => {
              const [start, end] = dates;
              setStartDate(start);
              setEndDate(end);
            }}
            startDate={startDate}
            endDate={endDate}
            className={`${disabled ? "cursor-not-allowed" : ""} ${className} ${
              errorMessage
                ? `form-input${
                    icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                  }-error`
                : `form-input${
                    icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                  }`
            }`}
            selectsRange
            shouldCloseOnSelect={false}
            monthsShown={monthsShown}
          />
          {icon && iconPosition && iconType === "image" && (
            <span className={`form-input-${iconPosition}-icon`}>
              <img
                alt={`input date`}
                src={icon}
                className={iconClassName}
                onClick={iconOnClick}
              />
            </span>
          )}
          {icon && iconPosition && iconType === "string" && (
            <span
              className={`form-input-${iconPosition}-icon ${iconClassName}`}
            >
              {icon}
            </span>
          )}
        </span>
      ) : (
        <span className="block relative customDatePickerWidth">
          <DatePicker
            filterDate={isFilterDay}
            selected={selectedDate && new Date(selectedDate)}
            dateFormat={format}
            className={`${disabled ? "cursor-not-allowed" : ""} ${className} ${
              errorMessage
                ? `form-input${
                    icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                  }-error`
                : `form-input${
                    icon && iconPosition ? `-with-icon-${iconPosition}` : ""
                  }`
            }`}
            onChange={setDate}
            placeholderText={placeholder}
            wrapperClassName="w-100"
            readOnly={disabled}
            disabled={disabled}
            minDate={minDate}
            showYearPicker={format === "yyyy" || false}
            showMonthYearPicker={
              format === "MMMM yyyy" || format === "mm yyyy" || false
            }
            showMonthDropdown={showMonthDropdown}
            showYearDropdown={showYearDropdown}
            selectsRange={selectsRange}
            monthsShown={monthsShown}
            isClearable={isClearable}
          />
          {icon && iconPosition && iconType === "image" && (
            <span className={`form-input-${iconPosition}-icon`}>
              <img
                alt={`input date`}
                src={icon}
                className={iconClassName}
                onClick={iconOnClick}
              />
            </span>
          )}
          {icon && iconPosition && iconType === "string" && (
            <span
              className={`form-input-${iconPosition}-icon ${iconClassName}`}
            >
              {icon}
            </span>
          )}
        </span>
      )}
      {errorMessage && (
        <div className={"form-input-message"}>
          <Image
            src={IconWarning}
            alt={`icon warning-date`}
            className="max-h-[15px]"
          />
          <p>{errorMessage}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default FormDate;
