import {
  SET_COLLAPSED,
  SET_DISTRICTS,
  SET_FACILITIES,
  SET_PROFILE,
  SET_PROVINCES,
  SET_REGENCIES,
  SET_VILLAGES,
} from "../../assets/constants/reducer.type";

const initialState = {
  collapsed: null,
  provinces: null,
  regencies: null,
  districts: null,
  villages: null,
  facilities: null,
  profile: null
};

const commonReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_COLLAPSED:
      return { ...state, collapsed: payload };
    case SET_PROVINCES:
      return { ...state, provinces: payload };
    case SET_REGENCIES:
      return { ...state, regencies: payload };
    case SET_DISTRICTS:
      return { ...state, districts: payload };
    case SET_VILLAGES:
      return { ...state, villages: payload };
    case SET_FACILITIES:
      return { ...state, facilities: payload };
      case SET_PROFILE:
        return { ...state, profile: payload };
    default:
      return state;
  }
};

export default commonReducer;
