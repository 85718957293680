import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountNeedPayment } from "../core/GET_CountNeedPayment";
import { getCountNewBooking } from "../core/GET_CountNewBooking";
import { getCountNextReservation } from "../core/GET_CountNextReservation";
import { getCountTodayReservation } from "../core/GET_CountTodayReservation";
import { getCountTotalBooking } from "../core/GET_CountTotalBooking";
import { getPayment } from "../core/GET_Payment";
import { getPaymentBalance } from "../core/GET_PaymentBalance";
import { postPaymentDisbursement } from "../core/POST_PaymentDisbursement";
import { getGenerateReport } from "../core/GET_GenerateReport";
import { getAvailableDisbursementBank } from "../core/GET_AvailableDisbursementBank";
import {
  setNeedPayment,
  setNewBooking,
  setNextReservation,
  setPayment,
  setPaymentBalance,
  setPaymentPagination,
  setTodayReservation,
  setTotalBooking,
  setAvailableDisburseBank
} from "../stores/actions/dashboard.action";
import { toastNotif } from "../utils/toastNotif";

export const useDashboard = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();

  const { payment } = useSelector((state) => state.dashboard);

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  /* get need payment list */
  const needPayment = async (params) => {
    setLoading(true);
    try {
      const { success, message, result } = await getCountNeedPayment(params);

      if (success && result) {
        dispatch(setNeedPayment(result));
      } else {
        dispatch(setNeedPayment());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get new booking list */
  const newBooking = async (params) => {
    setLoading(true);
    try {
      const { success, message, result } = await getCountNewBooking(params);

      if (success && result) {
        dispatch(setNewBooking(result));
      } else {
        dispatch(setNewBooking());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get today reservation list */
  const todayReservation = async (params) => {
    setLoading(true);
    try {
      const { success, message, result } = await getCountTodayReservation(
        params
      );

      if (success && result) {
        dispatch(setTodayReservation(result));
      } else {
        dispatch(setTodayReservation());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get next reservation list */
  const nextReservation = async (params) => {
    setLoading(true);
    try {
      const { success, message, result } = await getCountNextReservation(
        params
      );

      if (success && result) {
        dispatch(setNextReservation(result));
      } else {
        dispatch(setNextReservation());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get total booking */
  const fetchTotalBooking = async (params) => {
    setLoading(true);
    try {
      // const { success, message, result } = await getCountTotalBooking(
      //   params
      // );
      const reponse = await getCountTotalBooking(params);
      let { success, result } = reponse || {};
      result = result ? result : 0;
      if (success) {
        dispatch(setTotalBooking(result));
      } else {
        dispatch(setTotalBooking(0));
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get payment */
  const fetchPayment = async (params, showMore) => {
    setLoading(true);
    try {
      const { success, result, next_id, list_id } = await getPayment(params);
      if (success && result?.length) {
        if (showMore) {
          dispatch(
            setPayment(payment?.length ? [...payment, ...result] : result)
          );
        } else {
          dispatch(setPayment(result));
        }
        dispatch(setPaymentPagination({ next_id, list_id, params }));
      } else {
        dispatch(setPayment(payment?.length || undefined));
        dispatch(setPaymentPagination());
        setMessage("Data not found");
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* get payment balance */
  const fetchPaymentBalance = async (params) => {
    setLoading(true);
    try {
      const { message, success, balance } = await getPaymentBalance(params);
      if (success) {
        dispatch(setPaymentBalance(balance));
      } else {
        dispatch(setPaymentBalance());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  /* post payment disbursement */
  const createPaymentDisbursement = async (payload) => {
    setLoading(true);
    try {
      const { message, success } = await postPaymentDisbursement(payload);
      if (success) {
        toastNotif({
          message: message || "Create disbursement successfully",
          type: "success",
        });
        // window.location.reload();
        await fetchPayment({
          limit: 10,
          after_id: "",
          list_id: "",
        },
        false);
        return setLoading(false);
      } else {
        setMessage("Create disbursement failed!");
        toastNotif({
          message: message || "Create disbursement failed!",
          type: "error",
        });
        return setLoading(false);
      }
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      setLoading(false);
      return false;
    }
  };

  const generateReport = async (payload) => {
    setLoading(true);
    try {
      const { message, success } = await getGenerateReport(payload);
      if (success) {
        toastNotif({
          message: "Generate Report Successfully, Check Email For Download Report",
          type: "success",
        });
        return setLoading(false);
      } else {
        setMessage("Create disbursement failed!");
        toastNotif({
          message: message || "Create disbursement failed!",
          type: "error",
        });
        return setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      throw new Error(err.message || "Internal server error!")
    }
  };

  const fetchAvailableDisburseBank = async () => {
    setLoading(true);
    try {
      const { success, message, result } = await getAvailableDisbursementBank();

      if (success && result) {
        dispatch(
          setAvailableDisburseBank(
            result?.map((e) => ({
              value: e?.code,
              label: e?.name,
            }))
          )
        );
      } else {
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  return {
    error,
    message,
    loading,
    needPayment,
    newBooking,
    todayReservation,
    nextReservation,
    fetchTotalBooking,
    fetchPayment,
    fetchPaymentBalance,
    createPaymentDisbursement,
    generateReport,
    fetchAvailableDisburseBank
  };
};
