import React from "react";

const HomeTable = ({ loading, tabMenu }) => {
  return (
    <div className="mt-7 w-full border-2 border-[#EEF3F1] rounded-[10px] py-4 px-6">
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 bg-slate-50">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 bg-slate-100">
                <tr>
                    <th scope="col" class="px-6 py-3">
                      Invoice Code
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Guest Name
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Court
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Date
                    </th>
                    <th scope="col" class="px-6 py-3">
                      Start/End Time
                    </th>
                </tr>
            </thead>
            <tbody>
              {tabMenu?.data && tabMenu?.data.length > 0 && tabMenu.data.map((item) => (
                <tr key={item.invoiceCode} class="bg-white border-b">
                    <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                        {item?.booking?.invoiceCode || '-'}
                    </th>
                    <td class="px-6 py-4">
                        {item?.booking?.customerName || '-'}
                    </td>
                    <td class="px-6 py-4">
                        {item?.courtName || "-"}
                    </td>
                    <td class="px-6 py-4">
                        {item?.bookDate || "-"}
                    </td>
                    <td class="px-6 py-4">
                        {`${item?.startTime} - ${item?.endTime}` || "-"}
                    </td>
                </tr>
                ))}
                {tabMenu.data && tabMenu.data.length === 0 && (
                  <tr>
                    <td colSpan="5" align="center">
                      <div className="pt-[120px] pb-[120px] font-bold">
                        -- Tidak ada data --
                      </div>
                    </td>
                  </tr>
                )}
            </tbody>
        </table>
      </div>
    </div>
  );
};

export default HomeTable;
