import { Popover } from "@headlessui/react";
import React, { useState } from "react";
import Button from "../../../components/Button";
import SelectOption from "../../../components/Input/SelectOptions";
import InputText from "../../../components/Input/Text";

const FilterMembership = ({ onApply }) => {
  const [customerName, setCustomerName] = useState();
  const [customerEmail, setCustomerEmail] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [status, setStatus] = useState();
  return (
    <Popover className="z-[2] relative">
      <Popover.Button className="col-span-1 w-full">
        <Button
          label="Filter"
          type="outline-primary"
          size="sm"
          isDisable={false}
          className="!w-fit !px-16"
        />
      </Popover.Button>
      <Popover.Panel className="absolute !top-[50px]">
        <form className="py-4 px-7 absolute min-w-[300px] rounded-lg bg-white border-[1px] border-gray-300 shadow">
          <InputText
            placeholder="Input customer name"
            value={customerName}
            setValue={setCustomerName}
            type="text"
            label="Customer Name"
            labelClassName="mt-3"
          />
          <InputText
            placeholder="Input customer email"
            value={customerEmail}
            setValue={setCustomerEmail}
            type="text"
            label="Customer Email"
            labelClassName="mt-3"
          />
          <InputText
            placeholder="Input customer phone"
            value={customerPhone}
            setValue={setCustomerPhone}
            type="text"
            label="Customer Phone"
            labelClassName="mt-3"
          />
          <SelectOption
            labelClassName="mt-3"
            label="Type"
            isClearable
            placeholder="Choose type"
            isSearchable
            options={[
              { label: "ACTIVE", value: "ACTIVE" },
              { label: "REJECTED", value: "REJECTED" },
              { label: "EXPIRED", value: "EXPIRED" },
            ]}
            value={status}
            setValue={setStatus}
          />
          <div className="flex justify-between gap-3 mt-7">
            <Button
              label="Cancel"
              type="outline-primary"
              isDisable={false}
              size="sm"
            />
            <Button
              label="Apply"
              type="primary"
              isDisable={false}
              size="sm"
              onClick={() =>
                onApply({
                  customerName: customerName || "",
                  customerEmail: customerEmail || "",
                  customerPhone: customerPhone || "",
                  status: status?.value || "",
                })
              }
            />
          </div>
        </form>
      </Popover.Panel>
    </Popover>
  );
};

export default FilterMembership;
