import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Input/Checkbox";
import InputText from "../../../components/Input/Text";
import InputTextarea from "../../../components/Input/Textarea";
import { UploadImageBanner } from "../../../components/Input/Upload";
import Modal from "../../../components/Modal";
import { useGallery } from "../../../hooks/useGallery";

const FormGallery = ({ showForm, setShowForm, onSubmit }) => {
  /* Local State */
  const [banners, setBanners] = useState();

  /* Hooks */
  const { loading } = useGallery();

  const validationSchema = Yup.object().shape({
    imageDescription: Yup.string().notRequired(),
    isThumbnail: Yup.boolean().notRequired(),
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onCloseForm = () => {
    setShowForm(false);
    reset();
    setBanners();
  };

  const onSubmitForm = (payload) => {
    const body = {
      pathName: "venues",
      base64: banners[0]?.base64,
      description: payload.imageDescription,
      status: payload.isThumbnail ? true : false,
    };
    onSubmit(body);
  };

  return (
    <React.Fragment>
      <Modal.Common
        showModal={showForm}
        title="Add New Image"
        description="Add new Image by complete form information below"
        closeModal={() => setShowForm(false)}
      >
        <form
          className="w-[757px] px-10 pb-10"
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <UploadImageBanner
            setImages={setBanners}
            images={banners}
            text="Upload Image by click icon above"
            height={168}
            textClassName="top-[73%] right-[34.5%]"
          />
          <div className="mt-3">
            <Checkbox
              label="This image will be the thumbnail of the venue"
              id="isThumbnail"
              name="isThumbnail"
              register={register}
            />
          </div>
          <InputTextarea
            name="imageDescription"
            label="Images Description"
            placeholder="Inset description"
            labelClassName="mt-3"
            register={register}
            errorMessage={errors?.imageDescription?.message?.toString()}
            textLength={watch("imageDescription")?.length}
            maxLength={1000}
          />
          <div className="flex justify-end gap-4">
            <div className="w-[180%]" />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Save"
              type="primary"
              btnType="submit"
              isDisable={!isValid || isSubmitting || loading}
              isSubmit={(isSubmitting && isValid) || loading}
            />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={onCloseForm}
            />
          </div>
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default FormGallery;
