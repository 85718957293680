import api from "./api";

export const postCheckoutBooking = async (body) => {
  return api
    .post({
      body,
      endpoint: "/booking/own/create",
      port: process.env.REACT_APP_PORT_BOOK,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
