import moment from "moment";
import React from "react";
import {
  IconBookingCart,
  IconBookingClosed,
  IconBookingPaid,
  IconBookingWaitPaid,
  IconLockGray,
} from "../../../assets/images";
import { capitalizeFirstLetter, IDRFormat } from "../../../utils/formatters";
import "../BookingManagement.style.css";

const CardBookingHour = ({
  className = "",
  onClick,
  type = "available", // | "selected" | "approved" | "waiting_for_payment" | "cart",
  price,
  data,
}) => {
  return (
    <div className="relative group transition-all duration-200">
      {type !== "available" && !onClick && (
        <div className="absolute left-[50%] translate-x-[-40%] top-[-20%] group-hover:block hidden z-9999 bg-white p-3 rounded-md shadow-lg transition-all duration-200 w-full">
          <p className="text-small text-gray-500 truncate">
            {type === "approved" ? "Payment Completed" : "Waiting Payment"}
          </p>
          {type === "approved" && (
            <>
              {type !== "available" && (
                <p className="text-small text-gray-500 truncate">
                  {moment(data?.booking?.updatedAt).format("DD/MM/yyyy HH:mm")}
                </p>
              )}
            </>
          )}
          <p className="my-2 text-regular text-primary-default font-medium truncate">
            {data?.booking?.invoiceCode}
          </p>
          <p className="text-regular truncate">{data?.booking?.customerName}</p>
          {data?.booking?.paymentType === "CASH" && (
            <p className="text-regular mt-1 truncate">Cash</p>
          )}
        </div>
      )}
      <div
        id="my-tooltip-2"
        onClick={
          onClick &&
          (type?.toLowerCase() === "available" ||
            type?.toLowerCase() === "cart" ||
            type?.toLowerCase() === "selected")
            ? onClick
            : null
        }
        className={`${!onClick && "!cursor-default !scale-100 !shadow-none"} ${
          !price && "!bg-gray-300 !pointer-events-none"
        } ${className} select-none text-center border-[1px] border-l-0 w-full lg:h-[150px] h-28 rounded-lg rounded-l-none relative card-booking-v3-${type?.toLowerCase()} border-l-[6px]`}
      >
        {type !== "available" ? (
          <div className="w-full block my-auto h-full !text-left px-3">
            <img
              src={
                type === "closed"
                  ? IconBookingClosed
                  : type === "selected" || type === "cart"
                  ? IconBookingCart
                  : type === "approved"
                  ? IconBookingPaid
                  : IconBookingWaitPaid
              }
              alt="sportirena icon"
              className="mt-2"
            />
            <div className="inner-card-booking-v3">
              <p className="font-bold lg:text-xl text-regular my-1">
                {!onClick && type === "approved"
                  ? "Approved"
                  : !onClick && type === "waiting_for_payment"
                  ? "Waiting Payment"
                  : `${IDRFormat(price || 0)} ,-`}
              </p>
              <p className="font-normal lg:text-medium text-small line-clamp-1">
                {(type === "approved" || type === "waiting_for_payment") &&
                !onClick
                  ? data?.booking?.customerName
                  : capitalizeFirstLetter(
                      type === "waiting_for_payment"
                        ? "Waiting Payment"
                        : type?.split("_").join(" ")
                    )}
              </p>
            </div>
            {!onClick && type === "waiting_for_payment" ? (
              ""
            ) : (
              <p className="absolute bottom-2 text-small">
                {data?.startTime} - {data?.endTime}
              </p>
            )}
          </div>
        ) : (
          <div className="inner-card-booking w-full">
            {!price ? (
              <img
                src={IconLockGray}
                alt="sportirena icon"
                className="mx-auto h-8 w-8"
              />
            ) : (
              <p className="font-medium lg:text-medium text-regular my-1 text-gray-500">
                {IDRFormat(price || 0)},-
              </p>
            )}
            <p className="lg:text-md text-regular font-bold">
              {!price ? "Not " : ""}
              {capitalizeFirstLetter(
                type === "waiting_for_payment"
                  ? "Waiting Payment"
                  : type?.split("_").join(" ")
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardBookingHour;
