/* court management */
export const SET_COURT_LIST = "SET_COURT_LIST";
export const SET_COURT_DETAIL = "SET_COURT_DETAIL";
export const SET_ALL_COURT_LIST = "SET_ALL_COURT_LIST";

/* gallery */
export const SET_GALLERIES = "SET_GALLERIES";

/* common */
export const SET_COLLAPSED = "SET_COLLAPSED";
export const SET_PROVINCES = "SET_PROVINCES";
export const SET_REGENCIES = "SET_REGENCIES";
export const SET_DISTRICTS = "SET_DISTRICTS";
export const SET_VILLAGES = "SET_VILLAGES";
export const SET_FACILITIES = "SET_FACILITIES";
export const SET_PROFILE = "SET_PROFILE";

/* dashboard */
export const SET_NEED_PAYMENT_LIST = "SET_NEED_PAYMENT_LIST";
export const SET_NEW_BOOKING_LIST = "SET_NEW_BOOKING_LIST";
export const SET_TODAY_RESERVATION = "SET_TODAY_RESERVATION";
export const SET_NEXT_RESERVATION = "SET_NEXT_RESERVATION";
export const SET_TOTAL_BOOKING = "SET_TOTAL_BOOKING";
export const SET_PAYMENT = "SET_PAYMENT";
export const SET_PAYMENT_BALANCE = "SET_PAYMENT_BALANCE";
export const SET_PAYMENT_PAGINATION = "SET_PAYMENT_PAGINATION";

/* dashboard */
export const SET_DETAIL_MY_VENUE = "SET_DETAIL_MY_VENUE";

/* my bank account */
export const SET_LIST_MY_BANK_ACCOUNT = "SET_LIST_MY_BANK_ACCOUNT";
export const SET_DETAIL_MY_BANK_ACCOUNT = "SET_DETAIL_MY_BANK_ACCOUNT";

/* Staff Management */
export const SET_LIST_STAFF = "SET_LIST_MY_BANK_ACCOUNT";

/* Time Booking */
export const SET_LIST_TIME_BOOKING = "SET_LIST_TIME_BOOKING";
export const SET_ROWS_TIME_BOOKING = "SET_ROWS_TIME_BOOKING";
export const SET_QUERY_TIME_BOOKING = "SET_QUERY_TIME_BOOKING";
export const SET_MASTER_DAYS_TIME = "SET_MASTER_DAYS_TIME";
export const SET_DETAIL_EXCEPTION = "SET_DETAIL_EXCEPTION";
export const SET_MASTER_TIME_INACTIVE = "SET_MASTER_TIME_INACTIVE";
export const SET_LIST_EXCEPTION = "SET_LIST_EXCEPTION";

/* My Booking */
export const SET_LIST_BOOKING = "SET_LIST_BOOKING";
export const SET_ROWS_BOOKING = "SET_ROWS_BOOKING";
export const SET_QUERY_BOOKING = "SET_QUERY_BOOKING";
export const SET_DETAIL_BOOKING = "SET_DETAIL_BOOKING";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_BOOKINGS_ALL_HOURS = "SET_BOOKINGS_ALL_HOURS";
export const SET_DASHBOARD_CALENDAR = "SET_DASHBOARD_CALENDAR";
export const SET_CART_BOOKINGS = "SET_CART_BOOKINGS";
export const SET_TTL_CART = "SET_TTL_CART";
export const SET_LIST_CART = "SET_LIST_CART";

export const SET_SERVICE_FEE = "SET_SERVICE_FEE";
export const SET_BANK = "SET_BANK";
export const SET_MEMBERS = "SET_MEMBERS";
export const SET_MEMBER_HISTORY = "SET_MEMBER_HISTORY";

/* Payment Report */
export const SET_PAYMENT_REPORT = "SET_PAYMENT_REPORT";

/* Configuration */
export const SET_CONFIGURATION_VENUE = "SET_CONFIGURATION_VENUE";

export const SET_AVAILABLE_DISBURSE_BANK = "SET_AVAILABLE_DISBURSE_BANK";

/* Setting General */ 
export const SET_GENERAL_SETTING_STATUS_PIN = "SET_GENERAL_SETTING_CHECK_PIN";
export const SET_GENERAL_SETTING_VALIDATION_PIN = "SET_GENERAL_SETTING_VALIDATION_PIN";

