import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMyBankAccount } from "../core/GET_MyBankAccount";
import { postMyBankAccount } from "../core/POST_MyBankAccount";
import { deleteMyBankAccount } from "../core/DELETE_MyBankAccount";
import { putMyBankAccount } from '../core/PUT_MyBankAccount'
import { setListBankAccount } from "../stores/actions/mybankaccount.actions";
import { toastNotif } from "../utils/toastNotif";

export const useMyBankAccount = () => {
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState();
  
    const resetState = () => {
      setError(false);
      setMessage(undefined);
    };
  
    useEffect(() => {
      const timer = setTimeout(() => resetState(), 2500);
  
      return () => clearTimeout(timer);
    }, [message]);
  
    /* get my venue detail */
    const myBankAccount = async () => {
      setLoading(true);
      try {
        const { success, message, result } = await getMyBankAccount();
  
        if (success && result) {
          dispatch(setListBankAccount(result));
        } else {
          dispatch(setListBankAccount());
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    };

    const createMyBankAccount = async (data) => {
      setLoading(true);
      try {
        const { success, message } = await postMyBankAccount(data);
  
        if (success) {
          myBankAccount();
          toastNotif({
            message: message || "Created successfully",
            type: "success",
          });
        } else {
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    }

    const dropMyBankAccount = async (id) => {
      setLoading(true);
      try {
        const { success, message } = await deleteMyBankAccount(id);
        if (success) {
          myBankAccount();
          toastNotif({
            message: message || "Deleted successfully",
            type: "success",
          });
        } else {
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    }

    const updateMyBankAccount = async (id, data) => {
      setLoading(true);
      try {
        const { success, message } = await putMyBankAccount(id, data);
        if (success) {
          myBankAccount();
          toastNotif({
            message: message || "Updated successfully",
            type: "success",
          });
        } else {
          setMessage("Data not found");
          toastNotif({ message: message || "Data not found", type: "error" });
        }
        return setLoading(false);
      } catch (err) {
        setMessage("Internal server error!");
        toastNotif({
          message: err.message || "Internal server error!",
          type: "error",
        });
        return setLoading(false);
      }
    }
  
    return {
      error,
      message,
      loading,
      myBankAccount,
      createMyBankAccount,
      dropMyBankAccount,
      updateMyBankAccount
    };
  };