import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputText from "../../components/Input/Text";
import Toggle from "../../components/Input/Toggle";
import InputTextarea from "../../components/Input/Textarea";
import SelectOption from "../../components/Input/SelectOptions";
import Button from "../../components/Button";
import { useConfigVenue } from '../../hooks/useConfigVenue'
import { toastNotif } from "../../utils/toastNotif";
import listdates from "../../assets/data/dates.json";

const Configuration = () => {

  const { fetchConfiguration, createOrUpdateConfigVenue } = useConfigVenue();
  const { listConfig } = useSelector((state) => state.configvenue);

  const validationSchema = Yup.object().shape({
    status: Yup.boolean().default(false),
    benefit: Yup.string().required("please insert benefit to be member"),
    periodmember: Yup.number().default(0).required("please insert number periode"),
    mindatemember: Yup.object().required("please insert min book date member"),
    mindatenonmember: Yup.object().default(0).required("please insert min book date non member")
  });

  const {
    register,
    control,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    fetchDate();
  }, []);

  useEffect(() => {
    if (listConfig && listConfig.length > 0) {
      setValueConfig(listConfig);
    } else {
      reset();
    }
  }, [listConfig])

  const getValue = async (payload, key) => {
    const field = payload.find((i) => i.configKey === key);
    return field;
  }

  const setValueConfig = async (payload) => {
    const MEMBERSHIP = await getValue(payload, 'MEMBERSHIP');
    const BENEFIT_MEMBER = await getValue(payload, 'BENEFIT_MEMBER');
    const PERIODE_MEMBER = await getValue(payload, 'PERIODE_MEMBER');
    const OPEN_DATE_BOOKING = await getValue(payload, 'OPEN_DATE_BOOKING');
    let OPEN_DATE_BOOKING_VALUE = {};
    if (OPEN_DATE_BOOKING && OPEN_DATE_BOOKING?.configValue !== "") {
      OPEN_DATE_BOOKING_VALUE = JSON.parse(OPEN_DATE_BOOKING?.configValue);
    }
    let datemember = OPEN_DATE_BOOKING_VALUE && OPEN_DATE_BOOKING_VALUE?.anggota ? OPEN_DATE_BOOKING_VALUE?.anggota : "";
    datemember = datemember === "" ? null : { value: datemember, label: datemember};
    let datenomember = OPEN_DATE_BOOKING_VALUE && OPEN_DATE_BOOKING_VALUE?.non_anggota ? OPEN_DATE_BOOKING_VALUE?.non_anggota : "";
    datenomember = datenomember === "" ? null : { value: datenomember, label: datenomember};
    setValue("status", MEMBERSHIP && MEMBERSHIP?.configValue === "1" ? true : false);
    setValue("benefit", BENEFIT_MEMBER ? BENEFIT_MEMBER?.configValue : "");
    setValue("periodmember", PERIODE_MEMBER ? PERIODE_MEMBER?.configValue : "0");
    setValue("mindatemember", datemember);
    setValue("mindatenonmember", datenomember);
  }

  const fetchDate = async () => {
    await fetchConfiguration();
  }

  const onSubmit = async () => {
    try {
      const { status, benefit, periodmember, mindatemember, mindatenonmember } = await getValues();
      const payload = {
        dataConfig: [
          {
              configKey: "BENEFIT_MEMBER",
              configValue: benefit
          },
          {
              configKey: "PERIODE_MEMBER",
              configValue: periodmember
          },
          {
              configKey: "OPEN_DATE_BOOKING",
              configValue: JSON.stringify({anggota: mindatemember?.value || "", non_anggota: mindatenonmember?.value || ""})
          }, 
          {
              configKey: "MEMBERSHIP",
              configValue: status ? "1" : "0"
          }
        ]
      }
      await createOrUpdateConfigVenue(payload);
    } catch (e) {
      toastNotif({
        message: e.message || "Internal server error!",
        type: "error",
      });
    } finally {
      reset();
    }
  }

  return (
      <React.Fragment>
        <div className="pt-7 pb-36 px-11">
          <div className="flex justify-between">
            <p className="font-semibold text-2xl text-left">
              Configuration
              <span className="text-medium text-gray-500 font-normal block mt-1">
                Some information will be displayed publicly, so be careful what
                you share.
              </span>
            </p>
          </div>

          <div className="flex justify-between gap-8 mt-12 pb-8 border-b-[1px] border-[#F0F0F0]">
            <div className="w-[40%] my-auto">
              <p className="font-bold text-lg">Member Status</p>
              <p className="font-normal text-[12px]">
                This status will be active or inactive
              </p>
            </div>
            <div className="w-[60%] my-auto flex">
              <Toggle className="my-auto" name="status" control={control} />
              <p className="text-primary-default text-medium">
                Member is
                {watch("status") ? " active" : " inactive"}
              </p>
            </div>
          </div>

          <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
            <div className="w-[40%] my-auto">
              <p className="font-bold text-lg">Benifit Member</p>
              <p className="font-normal text-[12px]">
                This benefit to be members
              </p>
            </div>
            <div className="w-[60%] my-auto flex gap-4 justify-start">
              <div className="w-full">
                <InputTextarea
                  name="benefit"
                  required
                  type="text"
                  placeholder="Insert benefit member"
                  register={register}
                  errorMessage={errors?.benefit?.message?.toString()}
                  maxLength={1000}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
            <div className="w-[40%] my-auto">
              <p className="font-bold text-lg">Periode Member (day)</p>
              <p className="font-normal text-[12px]">
                This periode time member
              </p>
            </div>
            <div className="w-[60%] my-auto flex gap-4 justify-start">
              <div className="w-full">
                <InputText
                  name="periodmember"
                  required
                  type="number"
                  placeholder="Insert periode member active"
                  register={register}
                  errorMessage={errors?.periodmember?.message?.toString()}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
            <div className="w-[40%] my-auto">
              <p className="font-bold text-lg">Open Date Schedule Booking</p>
              <p className="font-normal text-[12px]">
                This min open date booking
              </p>
            </div>
            <div className="w-[60%] my-auto flex gap-4 justify-start">
              <div className="flex w-full justify-between gap-2 mt-8">
                <div className="w-[50%] my-auto">
                  <SelectOption
                    label="For Member"
                    labelClassName="mt-4 mr-4"
                    name="mindatemember"
                    placeholder="Choose Date"
                    required
                    control={control}
                    register={register}
                    options={listdates}
                    errorMessage={errors?.mindatemember?.message?.toString()}
                  />
                </div>
                <div className="w-[50%] my-auto">
                  <SelectOption
                    label="For Non Member"
                    labelClassName="mt-4 mr-4"
                    name="mindatenonmember"
                    placeholder="Choose Date"
                    required
                    control={control}
                    register={register}
                    options={listdates}
                    errorMessage={errors?.mindatenonmember?.message?.toString()}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse mt-10">
            <div className="w-60">
              <Button
                className="tracking-wider font-[14px]"
                label="Save"
                type="primary"
                btnType="button"
                isDisable={!isValid || isSubmitting}
                onClick={onSubmit}
                isSubmit={isSubmitting && isValid}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
    
  export default Configuration;