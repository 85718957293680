import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  IconExeption,
  IconPlusGreen,
  IconSave,
  IconSaveDisabled,
} from "../../assets/images";
import Image from "../../components/Image";
import SelectOption from "../../components/Input/SelectOptions";
import Toggle from "../../components/Input/Toggle";
import Table from "../../components/Table";
import { useCourt } from "../../hooks/useCourt";
import { useTimeBooking } from "../../hooks/useTimeBooking";
import { setQueryTimeBooking } from "../../stores/actions/timebooking.action";
import AddException from "./Modal/AddException";
import BulkEdit from "./Modal/BulkEdit";
import Exception from "./Modal/Exception";
import "./styles.css";

const TimeBooking = () => {
  const dispatch = useDispatch();
  const [id, setId] = useState({});
  const [court, setCourt] = useState(null);
  const [paginationDefaultPage, setPaginationDefaultPage] = useState(1);
  const [price, setPrice] = useState("");
  const [isShowBulkEdit, setIsShowBulkEdit] = useState(false);
  const [isShowException, setIsShowException] = useState(false);
  const [isShowAddException, setIsShowAddException] = useState(false);
  const [chooseTime, setChoosenTime] = useState();

  const { list, query, rows, detailException } = useSelector(
    (state) => state.timebooking
  );
  const { allcourts } = useSelector((state) => state.court);

  const {
    loading,
    fetchTimeBooking,
    updateTimeBookingStatus,
    createOrUpdatePricing,
  } = useTimeBooking();
  const { fetchAllCourts } = useCourt();
  const { fetchDetailException, loading: loadingTimeBooing } = useTimeBooking();

  const { pageSize } = query || {};

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllCourts();
  }, []);

  useEffect(() => {
    if (isShowException && chooseTime) {
      fetchDetailException({
        courtId: court?.value,
        startEndTime: chooseTime?.startEndTime,
        day: chooseTime?.value,
      });
    }
  }, [isShowException, chooseTime]);

  useEffect(() => {
    if (allcourts && allcourts.length > 0) {
      setCourt(allcourts[0]);
      query["idRelation"] = allcourts[0]?.value;
      fetchTimeBooking();
    }
  }, [allcourts]);

  const onChangeStatus = (id, { target }) => {
    updateTimeBookingStatus(id, { status: target.checked });
  };

  const onChangePage = (e) => {
    setPaginationDefaultPage(e);
    query["page"] = e - 1;
    dispatch(setQueryTimeBooking(query));
    fetchTimeBooking();
  };

  const onChangeRowsPerPage = (e) => {
    query["pageSize"] = e;
    dispatch(setQueryTimeBooking(query));
    fetchTimeBooking();
  };

  const onChangeDay = (e) => {
    if (e) {
      query["page"] = 0;
      // query["pageSize"] = 10;
      query["day"] = e.value;
    } else {
      query["page"] = 0;
      // query["pageSize"] = 10;
      query["day"] = "";
    }
    setPaginationDefaultPage(1);
    dispatch(setQueryTimeBooking(query));
    fetchTimeBooking();
  };

  const onChangeCourt = (e) => {
    setCourt(e);
    if (e) {
      query["page"] = 0;
      // query["pageSize"] = 10;
      query["idRelation"] = e.value;
    } else {
      query["page"] = 0;
      // query["pageSize"] = 10;
      query["idRelation"] = "";
    }
    setPaginationDefaultPage(1);
    dispatch(setQueryTimeBooking(query));
    fetchTimeBooking();
  };

  const onChangePrice = (id, { target }) => {
    setId(id);
    setPrice(target.value);
  };

  function handleSubmit(e, row) {
    e.preventDefault();
    const form = new FormData(e.target);
    const value = form.get(row.id);
    createOrUpdatePricing({
      openHourId: row.id,
      courtId: row.idRelation,
      price: value,
    });
    setId("");
    setPrice("");
  }
  return (
    <React.Fragment>
      <Exception
        setShowException={setIsShowException}
        showException={isShowException}
        data={detailException}
        isLoading={loadingTimeBooing}
      />
      <BulkEdit
        setShowBulkEdit={setIsShowBulkEdit}
        showBulkEdit={isShowBulkEdit}
      />
      <AddException
        setShowException={setIsShowAddException}
        showException={isShowAddException}
        choosenCourt={court}
        choosenTime={chooseTime}
      />
      <div className="py-7 px-11">
        <div className="grid grid-cols-5">
          <p className="font-semibold text-2xl text-left col-span-4">
            Time Booking
            <span className="text-medium text-gray-500 font-normal block mt-1">
              Manage all your existing time booking or edit or add new time
              booking
            </span>
          </p>
        </div>
        <div className="lg:flex md:flex justify-between">
          <div className="flex lg:w-1/3 md:w-1/2 lg:justify-start md:justify-start justify-between mt-4 lg:mb-0 md:mb-0 mb-4">
            <div className="mr-4 w-full">
              <SelectOption
                labelClassName="mt-4 mr-4"
                name="role"
                placeholder="Choose Court"
                required
                value={court}
                setValue={onChangeCourt}
                options={allcourts}
              />
            </div>
            <div className="w-full">
              <SelectOption
                className="mt-4"
                name="role"
                placeholder="Choose Day"
                required
                setValue={onChangeDay}
                isClearable
                options={[
                  {
                    label: "Sunday",
                    value: "Sunday",
                  },
                  {
                    label: "Monday",
                    value: "Monday",
                  },
                  {
                    label: "Tuesday",
                    value: "Tuesday",
                  },
                  {
                    label: "Wednesday",
                    value: "Wednesday",
                  },
                  {
                    label: "Thursday",
                    value: "Thursday",
                  },
                  {
                    label: "Friday",
                    value: "Friday",
                  },
                  {
                    label: "Saturday",
                    value: "Saturday",
                  },
                ]}
              />
            </div>
          </div>
          <div className="flex lg:justify-end md:justify-end justify-between gap-4 my-auto">
            <p
              onClick={() => setIsShowBulkEdit(true)}
              className="text-lg font-medium text-[#1212DB] cursor-pointer hover:text-blue-500 duration-200 transition-colors"
            >
              Bulk Edit
            </p>
            <p
              onClick={() =>
                navigate("/time-booking/exception", {
                  state: { court: allcourts[0]?.value },
                })
              }
              className="text-lg font-medium text-[#1212DB] cursor-pointer hover:text-blue-500 duration-200 transition-colors"
            >
              Exception
            </p>
          </div>
        </div>
        <Table
          progressPending={loading}
          usePagination
          paginationPerPage={pageSize}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          paginationTotalRows={rows}
          paginationServer
          subHeader={false}
          data={list}
          paginationRowsPerPageOptions={[10, 15, 20]}
          paginationDefaultPage={paginationDefaultPage}
          textTransform="none"
          columns={[
            {
              name: <p className="text-center w-full">No</p>,
              width: "8%",
              cell: (_row, i) => {
                return <p className="text-center w-full">{i + 1}</p>;
              },
            },
            {
              name: "Start Time",
              sortable: true,
              selector: (row) => row.startTime || "-",
            },
            {
              name: "End Time",
              sortable: true,
              selector: (row) => row.endTime || "-",
            },
            {
              name: "Day",
              sortable: true,
              selector: (row) => row.day || "-",
            },
            {
              name: "Status",
              width: "13%",
              cell: (row, i) => (
                <Toggle
                  key={i}
                  id={row.id}
                  checked={row.status}
                  setChecked={(e) => onChangeStatus(row.id, e)}
                />
              ),
            },
            {
              name: "Price",
              width: "25%",
              cell: (row, i) => (
                // <div className="flex justify-center justify-items-center content-center items-center">
                <form onSubmit={(e) => handleSubmit(e, row)}>
                  <div className="flex justify-center justify-items-center items-center">
                    <div className="mr-1.5">
                      <input
                        min={0}
                        name={row.id}
                        className="price form-input"
                        type="number"
                        onChange={(e) => onChangePrice(row.id, e)}
                        defaultValue={row.price.toString()}
                        required
                      />
                    </div>
                    <button type="submit" disabled={row.id !== id}>
                      <Image
                        src={row.id === id ? IconSave : IconSaveDisabled}
                        alt="Icon Pencil"
                        height={25}
                        width={25}
                      />
                    </button>
                  </div>
                </form>
              ),
            },
            {
              name: "Exception",
              cell: (row, i) => (
                <div className="flex justify-start gap-4">
                  <img
                    src={IconPlusGreen}
                    alt="sportirena icon plus"
                    className="h-4 w-4 my-auto"
                    onClick={() => {
                      setIsShowAddException(true);
                      setChoosenTime(row);
                    }}
                  />
                  <img
                    onClick={() => {
                      setChoosenTime(row);
                      setIsShowException(true);
                    }}
                    src={IconExeption}
                    alt="sportirena icon exception"
                    className="h-5 w-5 my-auto cursor-pointer"
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
    </React.Fragment>
  );
};

export default TimeBooking;
