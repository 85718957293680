import { Popover } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { IconCalendar } from "../../../assets/images";
import Button from "../../../components/Button";
import FormDate from "../../../components/Input/Date";
import "../../../components/Input/Date/Date.style.css";
import SelectOption from "../../../components/Input/SelectOptions";

const DateRange = ({ onSubmit }) => {
  const validationSchema = Yup.object().shape({
    filter: Yup.object().nullable(),
    startDate: Yup.string(),
    endDate: Yup.string(),
  });

  const { control, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const onApplyFilter = () => {
    const filter = watch("filter")?.value;
    if (filter === "created") {
      onSubmit({
        created_from:
          moment(watch("startDate")).format("YYYY-MM-DD") + "T00:00:00.000Z",
        created_to:
          moment(watch("endDate")).format("YYYY-MM-DD") + "T23:59:59.000Z",
      });
    } else {
      onSubmit({
        updated_from:
          moment(watch("startDate")).format("YYYY-MM-DD") + "T00:00:00.000Z",
        updated_to:
          moment(watch("endDate")).format("YYYY-MM-DD") + "T23:59:59.000Z",
      });
    }
  };
  return (
    <Popover className="z-[2] relative flex justify-start gap-2 !h-full w-full form-input !pt-0">
      <Popover.Button className="w-full !h-[4px] flex justify-start gap-4">
        <img
          alt="sportirena icon date"
          src={IconCalendar}
          className="my-auto h-5 w-5 mt-[11px]"
        />
        <div className="flex justify-between gap-2 my-auto mt-3 w-full">
          <p className="my-auto">
            {watch("startDate")
              ? moment(watch("startDate")).format("DD/MM/yyy")
              : ""}
          </p>
          <p className="my-auto">-</p>
          <p className="my-auto">
            {watch("endDate")
              ? moment(watch("endDate")).format("DD/MM/yyy")
              : ""}
          </p>
        </div>
      </Popover.Button>
      <Popover.Panel className="absolute !top-[50px] !left-0">
        <form className="py-4 px-7 absolute min-w-[300px] rounded-lg bg-white border-[1px] border-gray-300 shadow">
          <SelectOption
            label="Filter by"
            name="filter"
            placeholder="Choose filter by"
            isSearchable
            options={[
              {
                label: "Created",
                value: "created",
              },
              {
                label: "Updated",
                value: "updated",
              },
            ]}
            control={control}
          />
          <div className="mt-3">
            <FormDate
              label="Date"
              placeholder="mm/dd/yyyy"
              icon={IconCalendar}
              iconPosition="left"
              selectsRange
              startDate={watch("startDate")}
              endDate={watch("endDate")}
              setStartDate={(value) => setValue("startDate", value)}
              setEndDate={(value) => setValue("endDate", value)}
              format="dd/MM/yyyy"
              isClearable
            />
          </div>
          <div className="flex justify-between gap-3 mt-7">
            <Button
              label="Cancel"
              type="outline-primary"
              isDisable={false}
              size="sm"
            />
            <Button
              label="Apply"
              type="primary"
              isDisable={false}
              size="sm"
              onClick={onApplyFilter}
            />
          </div>
        </form>
      </Popover.Panel>
    </Popover>
  );
};

export default DateRange;
