import React, { useState } from "react";
import { IconLeaf } from "../../../assets/images";
import Image from "../../../components/Image";
import InputText from "../../../components/Input/Text";
import { commafy } from "../../../utils/formatters";

const BookingInformationCard = ({ className = "", totalBooking }) => {
  const [days, setDays] = useState(60);
  return (
    <React.Fragment>
      <div
        className={`${className} transition-all duration-[0.5s] bg-gray-50 rounded-lg border-[1px] border-[#E5E5E5] p-5`}
      >
        <p className="text-primary-default text-[16px] font-bold">
          TOTAL YOUR BOOKING
        </p>
        <div className="grid grid-cols-4 gap-4 mt-3">
          <div className="col-span-2"></div>
          <div className="col-span-2 ml-5">
            <InputText
              icon="Days"
              iconType="string"
              iconPosition="right"
              iconClassName="bg-[#DFE0EB] rounded-r-lg border-0 text-gray-500 font-semibold px-[50px]"
              value={days}
              setValue={setDays}
              disabled
            />
          </div>
        </div>
        <div className="flex justify-between mt-3">
          <Image src={IconLeaf} alt="Leaf Icon" width={101} height={79} />
          <div>
            <p className="text-dark-500 text-[50px] font-semibold">
              {commafy(totalBooking)}
            </p>
            <p className="text-right text-gray-400 font-bold text-md">
              Booking
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BookingInformationCard;
