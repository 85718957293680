import {
  SET_COLLAPSED,
  SET_DISTRICTS,
  SET_FACILITIES,
  SET_PROFILE,
  SET_PROVINCES,
  SET_REGENCIES,
  SET_VILLAGES,
} from "../../assets/constants/reducer.type";

export const setCollapsed = (payload) => {
  return { type: SET_COLLAPSED, payload };
};

export const setProvinces = (payload) => {
  return { type: SET_PROVINCES, payload };
};

export const setRegencies = (payload) => {
  return { type: SET_REGENCIES, payload };
};

export const setDistricts = (payload) => {
  return { type: SET_DISTRICTS, payload };
};

export const setVillages = (payload) => {
  return { type: SET_VILLAGES, payload };
};

export const setFacilities = (payload) => {
  return { type: SET_FACILITIES, payload };
};

export const setProfile = (payload) => {
  return { type: SET_PROFILE, payload };
};