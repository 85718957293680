import api from "./api";

export const getDashboardCalendar = async (queryParam) => {
  return api
    .get({
      endpoint: "/all/hour/dashboard-calendar/schedule-time-v2",
      port: process.env.REACT_APP_PORT_OPENHOUR,
      queryParam,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
