import moment from "moment";
import "moment/locale/id";
import numeral from "numeral";
import React from "react";
import { BASE_URL_PREVIEW_IMG } from "../../../assets/constants/constants";
import Image from "../../../components/Image";
import Table from "../../../components/Table";

function DetailWaitingPayment({ item, image }) {
  const { bookingschedules } = item || [];
  return (
    <React.Fragment>
      <div className="mt-8 pb-8 select-none w-[800px] px-10 relative">
        <div className="flex justify-between text-xl">
          <p>{item?.venueName}</p>
          <p className="text-primary-default font-bold">
            #{item?.booking?.invoiceCode}
          </p>
        </div>
        <Image
          src={
            image ||
            `${BASE_URL_PREVIEW_IMG}/${item?.pathName}/${item?.imageName}`
          }
          alt="icon venue"
          height={143}
          width={276}
          className="rounded-md object-cover w-full mt-5 h-[143px]"
        />
        <div className="flex justify-end my-4">
          <p className="my-auto text-regular w-fit font-semibold border-[1px] border-primary-default rounded-[10px] px-4 py-3">
            WAITING PAYMENT
          </p>
        </div>
        <div className="flex justify-between w-full">
          <div className="block w-full">
            <p className="text-medium font-semibold">Name</p>
            <p className="text-medium">{item?.booking?.customerName}</p>
          </div>
          <div className="block w-full">
            <p className="text-medium font-semibold">Email</p>
            <p className="text-medium">{item?.booking?.customerEmail}</p>
          </div>
        </div>
        <hr className="mt-6" />
        <div>
          {bookingschedules && (
            <Table
              subHeader={false}
              usePagination={false}
              title="User Management"
              description="Manage all your existing staff/admin or edit or add new staff/admin"
              data={bookingschedules}
              textTransform="none"
              columns={[
                {
                  name: <p className="text-center w-full">No</p>,
                  width: "8%",
                  cell: (_row, i) => {
                    return <p className="text-center w-full">{i + 1}</p>;
                  },
                },
                {
                  name: "Court",
                  sortable: true,
                  selector: (row) => row.courtName || "-",
                },
                {
                  name: "Date",
                  sortable: true,
                  selector: (row) => row.bookDate || "-",
                },
                {
                  name: "Time",
                  sortable: true,
                  selector: (row) => `${row.startTime} - ${row.endTime}` || "-",
                },
                {
                  name: "Price",
                  sortable: true,
                  selector: (row) =>
                    `Rp ${numeral(row.price).format("0,0")}` || "-",
                },
              ]}
            />
          )}
        </div>
        <hr />
        <p className="text-medium font-semibold mt-6">Expiration Time</p>
        <p className="text-medium mb-24">
          {moment(item?.booking?.expiry_date).format("DD MMMM yyyy HH:mm")} WIB
        </p>
        <div className="absolute bottom-0 right-0 rounded-b-xl w-full flex justify-between px-10 py-8 bg-primary-default text-white text-xl font-medium">
          <p>Total Price</p>
          <p>Rp {numeral(item?.booking?.totalPrice).format("0,0")}</p>
        </div>
      </div>
    </React.Fragment>
  );
}
export default DetailWaitingPayment;
