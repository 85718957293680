import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Skeleton from "../../components/Skeleton";
import { useDashboard } from "../../hooks/useDashboard";
import { useMyVenue } from "../../hooks/useMyVenue";
import BookingInformationCard from "./BookingInformationCard";
import GreetingCard from "./GreetingCard";
import TableOne from "./HomeTable/tableOne";
import TableTwo from "./HomeTable/tableTwo";
import StatusCards from "./StatusCards";

const Home = () => {
  /* Local State */
  const [menus, setMenus] = useState([
    {
      id: 1,
      name: "Need Payment",
      bookingCount: 0,
      data: null,
    },
    {
      id: 2,
      name: "New Booking",
      bookingCount: 0,
      data: null,
    },
    {
      id: 3,
      name: "Today`s Reservation",
      bookingCount: 0,
      data: null,
    },
    {
      id: 4,
      name: "Next`s Reservation",
      bookingCount: 0,
      data: null,
    },
  ]);
  const [activeMenu, setActiveMenu] = useState({
    id: 1,
    name: "Need Payment",
    bookingCount: 0,
    data: null,
  });

  /* Hooks */
  const {
    needPayment,
    loading,
    newBooking,
    nextReservation,
    todayReservation,
    fetchTotalBooking,
  } = useDashboard();

  const { myVenueDetail } = useMyVenue();

  /* Redux */
  const {
    needPaymentList,
    newBookingList,
    todayReservationList,
    nextReservationList,
    totalBooking,
  } = useSelector((state) => state.dashboard);
  const { detail } = useSelector((state) => state.myvenue);

  useEffect(() => {
    myVenueDetail();
    generateData();
  }, []);

  useEffect(() => {
    if (needPaymentList) {
      menus[0].bookingCount = needPaymentList?.count;
      menus[0].data = needPaymentList?.rows;
      setMenus(menus);
      setActiveMenu(menus[0]);
    }
    if (newBookingList) {
      menus[1].bookingCount = newBookingList?.count;
      menus[1].data = newBookingList?.rows;
      setMenus(menus);
    }
    if (todayReservationList) {
      menus[2].bookingCount = todayReservationList?.count;
      menus[2].data = todayReservationList?.rows;
      setMenus(menus);
    }
    if (nextReservationList) {
      menus[3].bookingCount = nextReservationList?.count;
      menus[3].data = nextReservationList?.rows;
      setMenus(menus);
    }
  }, [
    needPaymentList,
    newBookingList,
    todayReservationList,
    nextReservationList,
  ]);

  const generateData = useCallback(async () => {
    await needPayment();
    await newBooking();
    await todayReservation();
    await nextReservation();
    await fetchTotalBooking();
  }, []);

  const onClickTab = (e) => {
    setActiveMenu(e);
  };

  return (
    <React.Fragment>
      <div className="p-[41px]">
        <div className="lg:flex gap-7 h-full">
          <GreetingCard className="w-full" venueName={detail?.venueName} />
          {loading ? (
            <Skeleton.Square className="w-[60%] h-full" />
          ) : (
            <BookingInformationCard
              className="lg:w-[60%] lg:mt-0 mt-4"
              totalBooking={totalBooking}
            />
          )}
        </div>
        <div className="grid lg:grid-cols-4 lg:mt-0 mt-7 grid-cols-2 gap-7">
          {loading ? (
            menus.map((e) => (
              <Skeleton.Square key={e?.id} className="mt-7 w-full h-[162px]" />
            ))
          ) : (
            <StatusCards
              className="lg:mt-7"
              setTabMenu={onClickTab}
              tabMenu={activeMenu}
              menus={menus}
            />
          )}
        </div>
        {activeMenu && activeMenu.id === 1 ? (
          <TableOne loading={loading} tabMenu={activeMenu} />
        ) : (
          <TableTwo loading={loading} tabMenu={activeMenu} />
        )}
      </div>
    </React.Fragment>
  );
};

export default Home;
