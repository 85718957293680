import React from "react";
import InputTextarea from "../../../components/Input/Textarea";
import SelectOption from "../../../components/Input/SelectOptions";
import InputText from "../../../components/Input/Text";
import { useSelector } from "react-redux";

const Address = ({ register, errors, control, watch }) => {
  /* Redux */
  const { provinces, regencies, districts, villages } = useSelector(
    (state) => state.common
  );

  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] mt-3">
          <p className="font-bold text-lg">Location</p>
          <p className="font-normal text-[12px]">
            Please provide the address of the venue is located
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputTextarea
              className="mb-4"
              name="address"
              placeholder="Inset address"
              required
              register={register}
              errorMessage={errors?.address?.message?.toString()}
              textLength={watch("address")?.length}
              maxLength={120}
            />
            <div className="mb-4 flex justify-between gap-4">
              <div className="w-full">
                <SelectOption
                  name="province"
                  placeholder="Choose province"
                  required
                  isSearchable
                  options={provinces}
                  control={control}
                  errorMessage={errors?.province?.message?.toString()}
                />
              </div>
              <div className="w-full">
                <SelectOption
                  name="regency"
                  placeholder="Choose regency"
                  isSearchable
                  required
                  options={regencies}
                  control={control}
                  errorMessage={errors?.regency?.message?.toString()}
                  disabled={!watch("province")}
                />
              </div>
            </div>
            <div className="mb-4 flex justify-between gap-4">
              <div className="w-full">
                <SelectOption
                  name="district"
                  placeholder="Choose district"
                  isSearchable
                  required
                  options={districts}
                  control={control}
                  errorMessage={errors?.district?.message?.toString()}
                  disabled={!watch("regency")}
                />
              </div>
              <div className="w-full">
                <SelectOption
                  name="village"
                  placeholder="Choose village"
                  isSearchable
                  required
                  options={villages}
                  control={control}
                  errorMessage={errors?.village?.message?.toString()}
                  disabled={!watch("district")}
                />
              </div>
            </div>
            <div className="flex justify-between mt-4 gap-4">
              <div className="w-full">
                <InputText
                  name="longitude"
                  required
                  type="number"
                  placeholder="Insert longitude"
                  register={register}
                  errorMessage={errors?.longitude?.message?.toString()}
                />
              </div>
              <div className="w-full">
                <InputText
                  name="latitude"
                  required
                  type="number"
                  placeholder="Insert latitude"
                  register={register}
                  errorMessage={errors?.latitude?.message?.toString()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Address;
