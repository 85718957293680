import React, { useState } from "react";
import InputText from "../../../components/Input/Text";
import { IconEye, IconEyeOff } from "../../../assets/images";

const PasswordForm = ({ register, errors }) => {
  /* Local State */
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  return (
    <React.Fragment>
      <div className="px-10 py-5">
        <InputText
          required
          name="password"
          type={showPassword ? "text" : "password"}
          label="Password"
          register={register}
          icon={showPassword ? IconEyeOff : IconEye}
          iconOnClick={() => setShowPassword(!showPassword)}
          iconPosition="right"
          placeholder="Insert password"
          iconClassName="hover:cursor-pointer"
          errorMessage={errors?.password?.message?.toString()}
        />
        <InputText
          labelClassName="mt-4"
          required
          name="confirmPassword"
          type={showConfirmPassword ? "text" : "password"}
          label="Confirm Password"
          register={register}
          icon={showConfirmPassword ? IconEyeOff : IconEye}
          iconOnClick={() => setShowConfirmPassword(!showConfirmPassword)}
          iconPosition="right"
          placeholder="Retype password"
          iconClassName="hover:cursor-pointer"
          errorMessage={errors?.confirmPassword?.message?.toString()}
        />
      </div>
    </React.Fragment>
  );
};

export default PasswordForm;
