import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import InputText from "../../../../components/Input/Text";
import Modal from "../../../../components/Modal";
import { useGeneralSetting } from "../../../../hooks/useGeneralSetting";
import { toastNotif } from "../../../../utils/toastNotif";

const FormPIN = ({ showForm, onToggleModal, statusPin }) => {
  const { createOrUpdatePin } = useGeneralSetting();

  const validationSchema = Yup.object().shape({
    pin: Yup.string()
      .min(6, "Pin must be at least 6 characters")
      .max(6, "Pin must be at most 6 characters")
      .required("New pin required"),
    confirmationPin: Yup.string()
      .min(6, "Confirmation pin must be at least 6 characters")
      .max(6, "Confirmation pin must be at most 6 characters")
      .required("Confirmation pin required")
      .oneOf([Yup.ref("pin")], "Pin do not match"),
    old_pin: Yup.string()
      .min(6, "Old pin must be at least 6 characters")
      .max(6, "Old pin must be at most 6 characters")
      .nullable()
      .notRequired(),
  });

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      const payload = getValues();
      await createOrUpdatePin({
        pin: payload?.pin || "",
        old_pin: payload?.old_pin || "",
      });
    } catch (e) {
      toastNotif({
        message: e?.message || "Failed update PIN",
        type: "danger",
      });
    } finally {
      reset();
      onToggleModal();
    }
  };

  const {
    register,
    getValues,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  return (
    <React.Fragment>
      <Modal.Common
        showModal={showForm}
        title="PIN"
        description="Change your pin"
        closeModal={onToggleModal}
      >
        <form className="w-[757px] px-10 pb-10" onSubmit={onSubmit}>
          {statusPin !== "NOT_FOUND" && (
            <InputText
              className="number"
              labelClassName="mt-3"
              name="old_pin"
              type="password"
              label="Enter Old PIN"
              placeholder="******"
              register={register}
              errorMessage={errors?.old_pin?.message?.toString()}
            />
          )}
          <InputText
            labelClassName="mt-3"
            name="pin"
            required
            type="password"
            label="Enter New PIN"
            placeholder="*****"
            register={register}
            errorMessage={errors?.pin?.message?.toString()}
          />
          <InputText
            labelClassName="mt-3"
            name="confirmationPin"
            required
            type="password"
            label="Confirmation New PIN"
            placeholder="*****"
            register={register}
            errorMessage={errors?.confirmationPin?.message?.toString()}
          />
          <div className="flex justify-end gap-4">
            <div className="w-[180%]" />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Save"
              type="primary"
              btnType="submit"
              isDisable={!isValid || isSubmitting}
              isSubmit={isSubmitting && isValid}
            />
            <Button
              className="mt-8 tracking-wider font-[14px]"
              label="Cancel"
              type="outline-primary"
              btnType="button"
              isDisable={false}
              onClick={onToggleModal}
            />
          </div>
        </form>
      </Modal.Common>
    </React.Fragment>
  );
};

export default FormPIN;
