import moment from "moment";
import React, { useMemo } from "react";
import {
  IconChatApprove,
  IconCreditCardAccept,
  IconCreditCardReject,
  IconPassportExpired,
} from "../../../assets/images";
import Image from "../../../components/Image";

const History = ({ venueName, status, expiredDate, createdAt }) => {
  const wording = useMemo(() => {
    switch (status) {
      case "WAITING APPROVAL":
        return (
          <p className="text-regular">
            Pendaftaran keanggotaan untuk venue <b>{venueName}</b> berhasil
            dikirim. Silahkan menunggu untuk{" "}
            <b className="text-primary-default">diterima</b>.
          </p>
        );
      case "REJECTED":
        return (
          <p className="text-regular">
            Keanggotaan untuk venue <b>{venueName}</b>{" "}
            <b className="text-primary-default">ditolak</b>. Silahkan melakukan
            pendaftaran kembali.
          </p>
        );
      case "EXPIRED":
        return (
          <p className="text-regular">
            Keanggotaan untuk venue <b>{venueName}</b> sudah{" "}
            <b className="text-primary-default">berakhir</b>.
          </p>
        );
      case "ACTIVE":
        return (
          <p className="text-regular">
            Keanggotaan untuk venue <b>{venueName}</b>{" "}
            <b className="text-primary-default">diterima</b> dan akan aktif
            sampai tanggal{" "}
            <b>{moment(new Date(expiredDate)).format("DD/MM/yyyy")}</b>
          </p>
        );
      default:
        return "Unknown";
    }
  }, [status]);
  return (
    <div className="grid grid-cols-12 gap-4 mt-3">
      <div className="col-span-1 relative">
        {status === "WAITING APPROVAL" ? (
          <Image
            src={IconChatApprove}
            alt="IconChatApprove"
            className="rounded-full p-1 bg-[#C4A842]"
          />
        ) : status === "REJECTED" ? (
          <Image
            src={IconCreditCardReject}
            alt="IconCreditCardReject"
            className="rounded-full p-1 bg-[#B54242]"
          />
        ) : status === "EXPIRED" ? (
          <Image
            src={IconPassportExpired}
            alt="IconPassportExpired"
            className="rounded-full p-1 bg-[#2B77C0]"
          />
        ) : (
          <Image
            src={IconCreditCardAccept}
            alt="IconCreditCardAccept"
            className="rounded-full p-1 bg-[#34880C]"
          />
        )}
        <div className="border-r-[1px] border-[#C1C1C3] h-full right-[50%] translate-x-[50%] absolute" />
      </div>
      <div className="col-span-11 my-auto h-full">
        {wording}
        <p className="text-[#D9D9D9] text-small italic mt-1">
          {moment(createdAt).format("HH:mm:ss")} WIB
        </p>
      </div>
    </div>
  );
};

export default History;
