import React from "react";
import "./Skeleton.style.css";

const Square = ({ className, ...props }) => (
  <div className={`${className} skeleton-square`} {...props} />
);

const Circle = ({ className, ...props }) => (
  <div className={`${className} skeleton-circle`} {...props} />
);

const Triangle = ({ className, ...props }) => (
  <div className={`${className} skeleton-triangle`} {...props} />
);

const Skeleton = () => <Square />;

Square.defaultProps = {
  className: "h-10 w-10",
};

Circle.defaultProps = {
  className: "h-10 w-10",
};

Triangle.defaultProps = {
  className: "h-10 w-10",
};

Skeleton.Square = Square;
Skeleton.Circle = Circle;
Skeleton.Triangle = Triangle;

export default Skeleton;
