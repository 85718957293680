import axios from "axios";
import { serializeParam } from "../../utils/formatters";
import {
  removeLocalStorageItem,
  retrieveLocalStorageItem,
} from "../../utils/localStorage";

const http = axios.create({
  timeout: process.env.TIMEOUT,
});

http.interceptors.request.use(async (req) => {
  const accessToken = (await retrieveLocalStorageItem("accessToken")) || null;
  if (accessToken) {
    req.headers["x-access-token"] = accessToken;
  }
  return req;
});

http.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401 || status === 405) {
      removeLocalStorageItem("accessToken");
      removeLocalStorageItem("info");
      window.location.replace("/login");
    }
    throw error;
  }
);

const get = async ({
  endpoint,
  queryParam = "",
  port = null,
  config = null,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const portEnv = port || process.env.REACT_APP_PORT_OWNER;
  const version = process.env.REACT_APP_BASE_VERSION;
  let url = `${baseUrl}${portEnv}${version}${endpoint}`;
  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + "?" + serializeParam(queryParam);
  }

  return await http.get(url, config);
};

const post = async ({
  endpoint = "",
  body = null,
  queryParam = "",
  port = null,
  config = null,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const portEnv = port || process.env.REACT_APP_PORT_OWNER;
  const version = process.env.REACT_APP_BASE_VERSION;
  let url = `${baseUrl}${portEnv}${version}${endpoint}`;

  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + "?" + serializeParam(queryParam);
  }

  return await http.post(url, body, config);
};

const put = async ({
  endpoint = "",
  body = null,
  queryParam = "",
  port = null,
  config = null,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const portEnv = port || process.env.REACT_APP_PORT_OWNER;
  const version = process.env.REACT_APP_BASE_VERSION;
  let url = `${baseUrl}${portEnv}${version}${endpoint}`;

  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + "?" + serializeParam(queryParam);
  }

  return await http.put(url, body, config);
};

const patch = async ({
  endpoint = "",
  body = null,
  queryParam = "",
  port = null,
  config = null,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const portEnv = port || process.env.REACT_APP_PORT_OWNER;
  const version = process.env.REACT_APP_BASE_VERSION;
  let url = `${baseUrl}${portEnv}${version}${endpoint}`;

  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + "?" + serializeParam(queryParam);
  }

  return await http.patch(url, body, config);
};

const apiDelete = async ({
  endpoint = "",
  bodyparam = "",
  queryParam = "",
  port = null,
  config = null,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const portEnv = port || process.env.REACT_APP_PORT_OWNER;
  const version = process.env.REACT_APP_BASE_VERSION;
  let url = `${baseUrl}${portEnv}${version}${endpoint}`;

  if (queryParam && Object.keys(queryParam).length > 0) {
    url = url + "?" + serializeParam(queryParam);
  }

  const axiosConfig = {
    ...config,
    data: bodyparam,
  };

  return await http.delete(url, axiosConfig);
};

export default {
  delete: apiDelete,
  get,
  post,
  patch,
  put,
  http,
};
