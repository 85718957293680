import React from "react";
import Toggle from "../../../components/Input/Toggle";
import Address from "./Address";
import Info from "./Info";
import Facilities from "./Facilities";
import OpenCloseTime from "./OpenCloseTime";
import InputTextarea from "../../../components/Input/Textarea";

const VenueGeneral = ({ control, errors, register, watch }) => {
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-12 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Venue’s Status</p>
          <p className="font-normal text-[12px]">
            This status will be active or inactive
          </p>
        </div>
        <div className="w-[60%] my-auto flex">
          <Toggle className="my-auto" name="status" control={control} />
          <p className="text-primary-default text-medium">
            Venue is
            {watch("status") ? " active" : " inactive"}
          </p>
        </div>
      </div>
      <Info errors={errors} register={register} />
      <Address
        control={control}
        errors={errors}
        register={register}
        watch={watch}
      />
      <Facilities control={control} errors={errors} />
      <OpenCloseTime errors={errors} register={register} control={control} />
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] mt-3">
          <p className="font-bold text-lg">Description</p>
          <p className="font-normal text-[12px]">
            A quick snapshot of your venue
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full">
            <InputTextarea
              className="mb-4"
              name="description"
              placeholder="Inset description"
              required
              register={register}
              errorMessage={errors?.description?.message?.toString()}
              textLength={watch("description")?.length}
              maxLength={200}
              useDisplayLengthChar
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default VenueGeneral;
