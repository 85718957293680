import { combineReducers } from "redux";
import commonReducer from "./common.reducer";
import courtReducer from "./court.reducer";
import dashboardReducer from "./dashboard.reducer";
import galleryReducer from "./gallery.reducer";
import myBankAccountReducer from "./mybankaccount.reducer";
import myBookingReducer from "./mybooking.reducer";
import myVenueReducer from "./myvenue.reducer";
import paymentReportReducer from "./paymentreport.reducer";
import staffReducer from "./staff.reducer";
import timebookingReducer from "./timebooking.reducer";
import configVenueReducer from "./configvenue.reducer";
import generalSettingReducer from "./generalsetting.reducer";

const rootReducer = combineReducers({
  court: courtReducer,
  common: commonReducer,
  dashboard: dashboardReducer,
  myvenue: myVenueReducer,
  mybank: myBankAccountReducer,
  staff: staffReducer,
  timebooking: timebookingReducer,
  gallery: galleryReducer,
  mybook: myBookingReducer,
  paymentreport: paymentReportReducer,
  configvenue: configVenueReducer,
  generalSetting: generalSettingReducer
});

export default rootReducer;
