import api from "./api";

export const getDetailBookingClose = async (id) => {
  return api
    .get({
      endpoint: `/booking/own/close/${id}`,
      port: process.env.REACT_APP_PORT_BOOK,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
