import React from "react";
import { IconCross } from "../../assets/images";
import "./Card.style.css";

const CardHeader = ({
  children,
  onClose,
  className = "",
  noSeparator = false,
}) => {
  return (
    <header className="card-header">
      <div className="flex justify-between">
        <div className={className}>{children}</div>
        {onClose && (
          <img
            src={IconCross}
            onClick={onClose}
            className="cursor-pointer relative top-2 h-full hover:opacity-[0.5]"
            alt="Close Button"
          />
        )}
      </div>
      {!noSeparator && <hr className="hr" />}
    </header>
  );
};

const Card = ({ children, className = "", onClick }) => {
  return (
    <React.Fragment>
      <div
        onClick={onClick}
        className={`wrapper ${!onClick && "!cursor-default"} ${className}`}
      >
        {children}
      </div>
    </React.Fragment>
  );
};

const CardFooter = ({
  children,
  btnPositions,
  className = "",
  noSeparator = false,
}) => {
  return (
    <div className="card-footer">
      {!noSeparator && <hr className="hr" />}
      <footer
        className={`footer ${
          btnPositions === "start"
            ? "flex justify-start"
            : btnPositions === "center"
            ? "flex justify-center"
            : btnPositions === "between"
            ? "flex justify-between"
            : "flex justify-end"
        } ${className}`}
      >
        {children}
      </footer>
    </div>
  );
};

Card.Header = CardHeader;
Card.Footer = CardFooter;

export default Card;
