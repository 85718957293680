import React from "react";
import "../Home.style.css";

const StatusCards = ({ className = "", tabMenu, setTabMenu, menus }) => {
  return (
    <React.Fragment>
      {menus.map((e) => (
        <div
          key={e?.id.toString()}
          className={`status-cards${
            e?.id === tabMenu?.id ? "-active" : ""
          } ${className} flex justify-between p-5 relative`}
          onClick={() => setTabMenu(e)}
        >
          <div className="block">
            <p className="font-bold lg:text-xl">{e?.name}</p>
            <p className="leading-none ml-3 font-semibold lg:text-[50px] text-4xl mt-3">
              {e?.bookingCount}
            </p>
            <p className="leading-none ml-3 font-[20px]">
              {e?.id === 4 ? "Booking" : "Booking"}
            </p>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default StatusCards;
