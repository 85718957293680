import { SET_GALLERIES } from "../../assets/constants/reducer.type";

const initialState = {
  list: null,
};

const galleryReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_GALLERIES:
      return { ...state, list: payload };
    default:
      return state;
  }
};

export default galleryReducer;
