import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { IconCross, IconSearch } from "../../assets/images";
import Button from "../Button";
import InputText from "../Input/Text";
import TableSkeleton from "./Table.skeleton";
import "./Table.style.css";

const Table = ({
  columns,
  title = "",
  description = "",
  buttonAddTitle = "",
  data,
  useButtonAdd,
  usePagination,
  onClickBtnAdd,
  filterSearchPlaceholder,
  useFilterSearch,
  setValueFilter = undefined,
  valueFilter,
  textTransform = "uppercase",
  loading = false,
  onRowClick,
  onChangeRowsPerPage,
  onChangePage,
  paginationPerPage = 10,
  paginationTotalRows,
  progressPending = false,
  subHeader = false,
  paginationDefaultPage = null,
}) => {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const pageDefaultPage = paginationDefaultPage
    ? { paginationDefaultPage }
    : {};
  const customStyles = useMemo(() => {
    return {
      rows: {
        style: {
          minHeight: "60px",
          transitionDuration: "2s",
          transition: "all",
          "&:hover": {
            cursor: "pointer",
            fontWeight: 500,
            color: "#004A2F",
            background: "#EFF4F2",
          },
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px",
          paddingRight: "8px",
          color: "rgb(55 65 81)",
          textTransform,
          fontSize: "14px",
          fontWeight: "600",
        },
      },
      headRow: {
        style: {
          // borderRadius: "8px",
          // border: "0.8px solid #EBEBEB",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px",
          paddingRight: "8px",
        },
      },
      subHeader: {
        style: {
          paddingLeft: "0",
          paddingRight: "0",
        },
      },
      table: {
        style: {
          zIndex: "0",
          overflowY: "auto",
          // border: "1px black solid",
        },
      },
    };
  }, [textTransform]);

  useEffect(() => {
    // setRowsPerPage([10, 25, 30, 50])
    setResetPaginationToggle(!resetPaginationToggle);
  }, [data]);
  return (
    <div className="overflow-x-auto">
      {loading ? (
        <TableSkeleton />
      ) : (
        <DataTable
          columns={columns || []}
          subHeaderComponent={
            <div className="grid grid-cols-12 w-full mb-2">
              {title && (
                <p
                  className={`${
                    useFilterSearch && setValueFilter
                      ? "col-span-4"
                      : "col-span-6"
                  } font-semibold text-2xl text-left`}
                >
                  {title}
                  {description && (
                    <span className="text-medium text-gray-500 font-normal block mt-1">
                      {description}
                    </span>
                  )}
                </p>
              )}
              <div
                className={`${
                  useFilterSearch && setValueFilter
                    ? "col-span-8"
                    : "col-span-6"
                } flex justify-end w-full`}
              >
                {useFilterSearch && setValueFilter && (
                  <div className="w-[300px] mr-1">
                    <InputText
                      placeholder={filterSearchPlaceholder || ""}
                      value={valueFilter}
                      setValue={setValueFilter}
                      type="text"
                      icon={valueFilter ? IconCross : IconSearch}
                      iconPosition="right"
                      iconOnClick={() => valueFilter && setValueFilter("")}
                    />
                  </div>
                )}
                {useButtonAdd && (
                  <div className="w-fit">
                    <Button
                      btnType="button"
                      label={buttonAddTitle || ""}
                      type="primary"
                      onClick={onClickBtnAdd}
                      className="px-12 text-sm"
                      isDisable={false}
                    />
                  </div>
                )}
              </div>
            </div>
          }
          data={data || []}
          // persistTableHead
          pagination={usePagination}
          subHeader={subHeader}
          customStyles={customStyles}
          onRowClicked={onRowClick}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          paginationPerPage={paginationPerPage}
          paginationTotalRows={paginationTotalRows || 0}
          paginationServer={true}
          progressPending={progressPending}
          paginationResetDefaultPage={true}
          {...pageDefaultPage}
          // paginationDefaultPage={paginationDefaultPage}
          noDataComponent={
            <p className="text-center py-5 text-sm italic">
              -- Tidak ada data --
            </p>
          }
        />
      )}
    </div>
  );
};

export default Table;
