import { Popover } from "@headlessui/react";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconCollapse } from "../../assets/images";
import {
  removeLocalStorageItem,
  retrieveLocalStorageItem,
} from "../../utils/localStorage";
import Image from "../Image";
import Modal from "../Modal";
import { BASE_URL_PREVIEW_IMG } from "../../assets/constants/constants";

const Navbar = ({ setCollapse, collapse }) => {
  const [isSignOut, setIsSignOut] = useState(false);
  const navigate = useNavigate();
  const onSignOut = () => {
    setIsSignOut(false);
    removeLocalStorageItem("accessToken");
    removeLocalStorageItem("info");
    navigate("/", { replace: true });
  };

  const user = useMemo(() => {
    return JSON.parse(retrieveLocalStorageItem("info"));
  }, [retrieveLocalStorageItem]);

  return (
    <React.Fragment>
      <Modal.Delete
        showModalDelete={isSignOut}
        label="Are you sure want to sign out?"
        onCancel={() => setIsSignOut(false)}
        title="Sign Out"
        onDelete={onSignOut}
      />
      <div className="flex justify-between topbar">
        <Image
          onClick={() => setCollapse(!collapse)}
          height={15}
          width={15}
          alt="Icon Collapse"
          src={IconCollapse}
          className="cursor-pointer"
        />
        <div className="my-auto flex justify-between gap-5">
          <p className="text-[18px] text-[#252733] my-auto lg:visible invisible">
            {`${user?.firstName} ${
              !user?.lastName || user?.lastName === "-" ? "" : user?.lastName
            }`}
          </p>
          <Popover className="my-auto">
            <Popover.Button className="relative inline-flex items-center justify-center w-12 h-12 overflow-hidden rounded-full bg-primary-50">
              {user?.imageName ? (
                <Image
                  height={48}
                  width={48}
                  alt="profile photo"
                  src={`${BASE_URL_PREVIEW_IMG}/${user.pathName}/${user.imageName}`}
                />
              ) : (
                <span className="font-medium text-primary-default uppercase">
                  {user?.firstName[0] + user?.firstName[1]}
                </span>
              )}
            </Popover.Button>
            <Popover.Panel className="absolute right-16 z-10 mt-2">
              <div className="grid grid-cols-1 min-w-[180px] rounded-lg bg-white border-[1px] border-gray-300 shadow">
                <p
                  onClick={() => navigate("/profile")}
                  className="cursor-pointer px-4 py-3 hover:bg-primary-50 transition-all duration-100 rounded-t-lg"
                >
                  Profile
                </p>
                <p
                  onClick={() => navigate("/profile?tab=security")}
                  className="cursor-pointer px-4 py-3 hover:bg-primary-50 transition-all duration-100"
                >
                  Change Password
                </p>
                <p
                  onClick={() => setIsSignOut(true)}
                  className="cursor-pointer px-4 py-3 hover:bg-primary-50 transition-all duration-100 rounded-b-lg"
                >
                  Logout
                </p>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
