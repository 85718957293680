import React from "react";
import { DefaultImage } from "../../assets/images";

const Image = ({
  src = null,
  placeholder = null,
  alt = "Image Sportirena",
  className = "",
  height,
  width,
  onClick,
  delay,
}) => {
  return (
    <React.Fragment>
      <img
        loading="lazy"
        alt={alt || "image"}
        src={src}
        className={className}
        height={height}
        width={width}
        onClick={onClick}
        onError={({ target }) => (target.src = DefaultImage)}
      />
    </React.Fragment>
  );
};

export default Image;
