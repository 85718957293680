import React, { useMemo } from "react";
import { Menu, MenuItem, ProSidebar, SidebarHeader } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { dataSidebars } from "../../assets/data/sidebars";
import { IconArrowGreen, Sportirena } from "../../assets/images";
import { setCollapsed } from "../../stores/actions/common.action";
import { retrieveLocalStorageItem } from "../../utils/localStorage";
import Image from "../Image";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  /* Redux */
  const { collapsed } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  /* Router DOM */
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onChangeCollapsed = () => dispatch(setCollapsed(!collapsed));

  const role = useMemo(() => {
    return JSON.parse(
      retrieveLocalStorageItem("info") || "{}"
    )?.role?.toLowerCase();
  }, [retrieveLocalStorageItem("info")]);
  return (
    <React.Fragment>
      <div className="!mb-0 !pb-0 flex justify-between relative">
        <ProSidebar
          collapsed={collapsed}
          className={`layout-sidebar z-10 ${
            !collapsed ? "w-[240px]" : "w-0 hidden transition-all duration-200"
          }`}
        >
          <Menu className={collapsed ? "" : "h-screen"}>
            <SidebarHeader>
              <div className="py-3 border-b-2 border-gray-150 relative flex justify-between">
                <Image
                  className="mx-auto flex"
                  src={Sportirena}
                  alt="Icon Sportirena"
                  width={189}
                  height={61}
                />
                <div className="block my-auto pr-2 lg:hidden">
                  <Image
                    onClick={onChangeCollapsed}
                    src={IconArrowGreen}
                    alt="arrow green"
                    className="bg-gray-300 rounded-full p-2 shadow rotate-90 !w-fit my-auto"
                  />
                </div>
              </div>
            </SidebarHeader>
            {dataSidebars.map((e, i) => (
              <div key={i}>
                <p
                  key={i}
                  className="font-bold text-gray-500 text-small px-5 pb-2 pt-4 uppercase"
                >
                  {e.label}
                </p>
                {e.menus.map((el, j) => (
                  <MenuItem
                    className={`${
                      role === "staff" &&
                      (el?.id === "4.1" || el?.id === "5.2") &&
                      "hidden"
                    } sidebar-menu-item${
                      el.path.split("/").includes(pathname.split("/")[1]) &&
                      pathname !== "/"
                        ? "-active"
                        : ""
                    }`}
                    key={j + i}
                    onClick={() =>
                      !el.path.split("/").includes(pathname.split("/")[1]) &&
                      pathname !== "/" &&
                      navigate(el.path)
                    }
                  >
                    <div className="flex gap-2 my-auto">
                      <Image
                        src={
                          el.path.split("/").includes(pathname.split("/")[1]) &&
                          pathname !== "/"
                            ? el.iconPathActive
                            : el.iconPath
                        }
                        alt="Icon Home"
                        height={20}
                        width={20}
                        delay={0.0001}
                      />
                      {el.title}
                    </div>
                  </MenuItem>
                ))}
              </div>
            ))}
          </Menu>
        </ProSidebar>
        <main className="grid grid-cols-12 h-screen w-screen overflow-x-hidden">
          {!collapsed && <div className="col-span-2" />}
          <div className={`${collapsed ? "col-span-12" : "col-span-10"} block`}>
            <Navbar collapse={collapsed} setCollapse={onChangeCollapsed} />
            {children}
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

export default Layout;
