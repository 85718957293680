import api from "./api";

export const postAddDeleteCart = async (body) => {
  return api
    .post({
      endpoint: "/own/cart/add/delete",
      port: process.env.REACT_APP_PORT_CART,
      body,
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};
