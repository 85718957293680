import React from "react";
import SelectOption from "../../../components/Input/SelectOptions";
import { useSelector } from "react-redux";

const Facilities = ({ errors, control }) => {
  const { facilities } = useSelector((state) => state.common);
  return (
    <React.Fragment>
      <div className="flex justify-between gap-8 mt-8 pb-8 border-b-[1px] border-[#F0F0F0]">
        <div className="w-[40%] my-auto">
          <p className="font-bold text-lg">Facility</p>
          <p className="font-normal text-[12px]">
            Please select the facility court that you have in your venue. These
            will be help user discover your court facilities
          </p>
        </div>
        <div className="w-[60%] my-auto flex gap-4 justify-start">
          <div className="w-full my-auto">
            {facilities && (
              <SelectOption
                isMulti
                name="facility"
                placeholder="Choose facility"
                required
                options={facilities.map((item) => ({
                  value: item.id,
                  label: item.typeName,
                }))}
                control={control}
                errorMessage={errors?.facility?.message?.toString()}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Facilities;
