import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getPaymentReport } from "../core/GET_PaymentReport";
import { getPaymentReportExport } from "../core/GET_PaymentReportExport";
import { setPaymentReport } from "../stores/actions/paymentreport.actions";
import { toastNotif } from "../utils/toastNotif";

export const usePaymentReport = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState();
  const [downloadLink, setDownloadLink] = useState();

  const resetState = () => {
    setError(false);
    setMessage(undefined);
  };

  useEffect(() => {
    const link = document.createElement("a");
    document.body.appendChild(link);
    setDownloadLink(link);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => resetState(), 2500);

    return () => clearTimeout(timer);
  }, [message]);

  const getListPaymentReport = async (params) => {
    setLoading(true);
    try {
      const { success, message, result } = await getPaymentReport(params);

      if (success && result) {
        dispatch(setPaymentReport(result));
      } else {
        dispatch(setPaymentReport());
        setMessage("Data not found");
        toastNotif({ message: message || "Data not found", type: "error" });
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };

  const exportPaymentReport = async (params) => {
    setLoading(true);
    try {
      const file = await getPaymentReportExport(params);
      if (downloadLink) {
        const blob = new Blob([file]);
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute(
          "download",
          `payment_report_${moment().format("DD-MM-yyyy_HH-mm")}.xlsx`
        );
        downloadLink.click();
      }
      return setLoading(false);
    } catch (err) {
      setMessage("Internal server error!");
      toastNotif({
        message: err.message || "Internal server error!",
        type: "error",
      });
      return setLoading(false);
    }
  };
  return {
    error,
    message,
    loading,
    getListPaymentReport,
    exportPaymentReport,
  };
};
