import {
  SET_COURT_DETAIL,
  SET_COURT_LIST,
  SET_ALL_COURT_LIST,
} from "../../assets/constants/reducer.type";

const initialState = {
  list: null,
  detail: null,
  allcourts: [],
};

const courtReducer = (
  state = initialState,
  actions = {
    type: "",
    payload: null,
  }
) => {
  const { type, payload } = actions;

  switch (type) {
    case SET_COURT_LIST:
      return { ...state, list: payload };
    case SET_COURT_DETAIL:
      return { ...state, detail: payload };
    case SET_ALL_COURT_LIST:
      return { ...state, allcourts: payload };
    default:
      return state;
  }
};

export default courtReducer;
